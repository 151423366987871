import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as AuthService from '../../../services/internal/auth.service';
import { FormInput } from '../../../components';
import { PasswordMeterComponent } from '../../../components/ts';
import { useLocation, useNavigate } from 'react-router-dom';
import { VerifyTokenType } from '../../../types/IAuth';
import * as ROUTES from '../../../constants/routes';

const initialValues = {
	email: '',
	token: '',
	password: '',
	password_confirmation: '',
};

const useQuery = () => {
	const { pathname, search } = useLocation();
	return { pathName: pathname, query: useMemo(() => new URLSearchParams(search), [search]) };
};

const forgotPasswordSchema = Yup.object().shape({
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),
	password_confirmation: Yup.string()
		.required('Password confirmation is required')
		.when('password', {
			is: (val: string) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('password')],
				"Password and Confirm Password didn't match"
			),
		}),
});

const confirmToken = async (
	tokenParam: VerifyTokenType,
	callback: (data?: any, err?: any) => {}
) => {
	try {
		const { data } = await AuthService.verifyToken(tokenParam);
		callback(data);
		return data;
	} catch (error) {
		callback(null, error);
	}
};
const getToken = (pathName: string) => {
	const tokenPath = pathName.split('/');
	const token = tokenPath[tokenPath.length - 1];
	//check if token if path of the url
	if (token && tokenPath.length <= 2) return null;
	return token;
};

const SetPassword = () => {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
	const [hasTokenErrors, setHasTokenErrors] = useState<string | null>(null);
	const { query, pathName } = useQuery();
	const token = getToken(pathName) || '';
	const signature = query.get('signature') || '';
	const navigate = useNavigate();

	useEffect(() => {
		confirmToken({ signature, token }, (data, error) => {
			if (data) {
				initialValues.email = data.email;
				initialValues.token = data.token;
			}
			if (error) {
				setHasTokenErrors(error.message || 'Error Occurred verifying Token');
			}
			return data;
		});
	}, [initialValues]);

	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			setHasErrors(undefined);

			try {
				const { message } = await AuthService.resetReset(values);
				setHasErrors(false);
				setHasTokenErrors(null);
				setStatus(message);
				setLoading(false);
				navigate(ROUTES.LOGIN);
			} catch (error: any) {
				setHasErrors(true);
				setLoading(false);
				setSubmitting(false);
				setStatus(error?.message || 'error occurred');
			}
		},
	});

	useEffect(() => {
		PasswordMeterComponent.bootstrap();
	}, []);

	return (
		<div className="w-lg-500px p-10 p-lg-15 mx-auto">
			{!token && !signature ? (
				<div className="mb-lg-15 alert alert-danger">
					<div className="alert-text font-weight-bold">
						{
							'Sorry, invalid detected, please try using link sent to your email address.'
						}
					</div>
				</div> ? (
					!hasTokenErrors && (
						<div className="mb-lg-15 alert alert-danger">
							<div className="alert-text font-weight-bold">{hasTokenErrors}</div>
						</div>
					)
				) : (
					''
				)
			) : (
				<form
					className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
					noValidate
					id="kt_login_password_reset_form"
					onSubmit={formik.handleSubmit}
				>
					<div className="text-center mb-10">
						{/* begin::Title */}
						<h1 className="text-dark mb-3">Set Password</h1>
						{/* end::Title */}
					</div>

					{/* begin::Title */}
					{hasErrors === true && (
						<div className="mb-lg-15 alert alert-danger">
							<div className="alert-text font-weight-bold">
								{formik.status ||
									'Sorry, looks like there are some errors detected, please try again.'}
							</div>
						</div>
					)}

					{hasErrors === false && (
						<div className="mb-10 bg-light-info p-8 rounded">
							<div className="text-info">
								{formik.status || 'Sent password reset. Please check your email'}
							</div>
						</div>
					)}
					{/* end::Title */}
					{/* begin::Form group Email */}
					<div className="fv-row mb-7">
						<FormInput
							labelName="Email"
							labelClass="form-label fw-bolder text-dark fs-6"
							placeholder="Email"
							disabled={true}
							type="email"
							autoComplete="off"
							{...formik.getFieldProps('email')}
							className={clsx(
								'form-control form-control-lg form-control-solid',
								{ 'is-invalid': formik.touched.email && formik.errors.email },
								{
									'is-valid': formik.touched.email && !formik.errors.email,
								}
							)}
						/>
						{formik.touched.email && formik.errors.email && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.email}</span>
								</div>
							</div>
						)}
					</div>
					{/* end::Form group */}

					{/* begin::Form group  password */}
					<div className="mb-10 fv-row" data-kt-password-meter="true">
						<div className="mb-1">
							<div className="position-relative mb-3">
								<FormInput
									labelName="Password"
									labelClass="form-label fw-bolder text-dark fs-6"
									type="password"
									placeholder="Password"
									autoComplete="off"
									{...formik.getFieldProps('password')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										{
											'is-invalid':
												formik.touched.password && formik.errors.password,
										},
										{
											'is-valid':
												formik.touched.password && !formik.errors.password,
										}
									)}
								/>
								{formik.touched.password && formik.errors.password && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.password}</span>
										</div>
									</div>
								)}
							</div>
							{/* begin::Meter */}
							<div
								className="d-flex align-items-center mb-3"
								data-kt-password-meter-control="highlight"
							>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
								<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
							</div>
							{/* end::Meter */}
						</div>
						<div className="text-muted">
							Use 8 or more characters with a mix of letters, numbers & symbols.
						</div>
					</div>

					{/* begin::Form group Confirm password */}
					<div className="fv-row mb-5">
						<label className="form-label fw-bolder text-dark fs-6">
							Confirm Password
						</label>
						<input
							type="password"
							placeholder="Password confirmation"
							autoComplete="off"
							{...formik.getFieldProps('password_confirmation')}
							className={clsx(
								'form-control form-control-lg form-control-solid',
								{
									'is-invalid':
										formik.touched.password_confirmation &&
										formik.errors.password_confirmation,
								},
								{
									'is-valid':
										formik.touched.password_confirmation &&
										!formik.errors.password_confirmation,
								}
							)}
						/>
						{formik.touched.password_confirmation &&
							formik.errors.password_confirmation && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">
											{formik.errors.password_confirmation}
										</span>
									</div>
								</div>
							)}
					</div>
					{/* end::Form group */}
					{/* begin::Form group */}
					<div className="d-flex flex-wrap justify-content-center pb-lg-0">
						<button
							type="submit"
							id="kt_password_reset_submit"
							className="btn btn-lg btn-primary fw-bolder me-4"
						>
							<span className="indicator-label">Submit</span>
							{loading && (
								<span className="indicator-progress">
									Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
					{/* end::Form group */}
				</form>
			)}
		</div>
	);
};

export default SetPassword;
