import { usecoinsApi } from '../../services/http';
import { SETTINGS_ENDPOINTS } from '../../constants/endpoints';
import { IRole, ITeam } from '../../types/_model';

export const createRole = async (rolePayload: IRole) => {
	try {
		const { data } = await usecoinsApi.post(SETTINGS_ENDPOINTS.ROLE, rolePayload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const listRole = async () => {
	try {
		const { data } = await usecoinsApi.get(SETTINGS_ENDPOINTS.ROLE);
		return data;
	} catch (error) {
		throw error;
	}
};

export const findRole = async (roleId: string) => {
	try {
		const { data } = await usecoinsApi.get(`${SETTINGS_ENDPOINTS.ROLE}/${roleId}`);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const updateRole = async (roleUuid: string, rolePayload: IRole) => {
	try {
		const { data } = await usecoinsApi.put(
			`${SETTINGS_ENDPOINTS.ROLE}/${roleUuid}`,
			rolePayload
		);
		return data;
	} catch (error) {
		throw error;
	}
};

export const getPermissions = async () => {
	try {
		const { data } = await usecoinsApi.get(SETTINGS_ENDPOINTS.PERMISSION);
		return data;
	} catch (error) {
		throw error;
	}
};
