import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Audit, Balance, Customers, NotFound } from '../views';
import * as ROUTES from '../constants/routes';
import { MasterLayout } from '../layouts/MasterLayout';
import Dashboard from '../views/dashboard/Dashboard';
import Collections from '../views/collections/Collections';
import Settlement from '../views/settlement/Settlement';
import TransferRoutes from './TransferRoutes';
import SettingsRoutes from './SettingRoutes';
import { CustomerProvider } from '../store/Customer/customer.provider';
import { CollectionProvider } from '../store/Collection/collection.provider';
import { SettlementProvider } from '../store/Settlement/settlement.provider';
import { RoleProvider } from '../store/Role/role.provider';
import Invoice from '../views/invoice/invoice';
import { InvoiceProvider } from '../store/Invoice/invoice.provider';

const PrivateRoutes: React.FC = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route index element={<Navigate to={ROUTES.DASHBOARD} />} />

				<Route path={ROUTES.AUTH_ROOT} element={<Navigate to="/dashboard" />} />

				<Route
					path={ROUTES.DASHBOARD}
					element={
						<CollectionProvider>
							<Dashboard />
						</CollectionProvider>
					}
				/>
				<Route
					path={ROUTES.COLLECTIONS}
					element={
						<CollectionProvider>
							<Collections />
						</CollectionProvider>
					}
				/>
				<Route
					path={ROUTES.SETTLEMENT}
					element={
						<SettlementProvider>
							<Settlement />
						</SettlementProvider>
					}
				/>
				<Route
					path={ROUTES.CUSTOMERS}
					element={
						<CustomerProvider>
							<Customers />
						</CustomerProvider>
					}
				/>
				<Route path={ROUTES.TRANSFERS} element={<TransferRoutes />} />
				<Route
					path={ROUTES.SETTINGS}
					element={
						<RoleProvider>
							<SettingsRoutes />
						</RoleProvider>
					}
				/>

				<Route path={ROUTES.AUDIT} element={<Audit />} />
				<Route path={ROUTES.BALANCE} element={<Balance />} />

				<Route
					path={ROUTES.INVOICES}
					element={
						<CustomerProvider>
							<InvoiceProvider>
								<Invoice />
							</InvoiceProvider>
						</CustomerProvider>
					}
				/>

				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default PrivateRoutes;
