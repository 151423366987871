import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import NotFound from '../views/errors/NotFound';
import { SettingsHeader } from '../views/settings/SettingsHeader';
import Personal from '../views/settings/Personal';
import { Business } from '../views/settings/Business';
import Payment from '../views/settings/Payment';
import Preferences from '../views/settings/Preferences';
import Team from '../views/settings/Team';
import ApiWebhook from '../views/settings/ApiWebhook';
import { PageLink, PageTitle } from '../layouts';
import { RoleDispatchContext, RoleStateContext } from '../store/Role/role.provider';
import { useContext, useEffect, useState } from 'react';
import {
	IBank,
	IBankAccount,
	ICoinAddress,
	IPageQuery,
	IPermission,
	ITeam,
	IWallet,
} from '../types/_model';
import { listWallets } from '../services/internal/wallet.service';
import { ActionType } from '../types/IAuth';
import { listRoleAction } from '../store/Role/role.actions';
import { getPermissions } from '../services/internal/settings.service';
import { getTeams } from '../services/internal/business.service';
import { listBankAccount, listCoinAddress } from '../services/internal/withdrawal.service';
import { listBanks } from '../services/internal/app.service';
import { listInvoices } from '../services/internal/invoice.service';
import { IInvoice } from '../types/IInvoice';

const settingsBreadCrumbs: Array<PageLink> = [
	{
		title: 'Account',
		path: ROUTES.SETTINGS,
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const SettingsRoutes = () => {
	const [accepted_coins, setAcceptedCoins] = useState<IWallet[]>([]);
	const [accepted_fiats, setAcceptedFiats] = useState<IWallet[]>([]);
	const wallet_coins = async () => {
		try {
			const wallets = await listWallets();
			setAcceptedCoins(wallets.data.coins);
			setAcceptedFiats(wallets.data.fiat);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Wallet Fatal error');
		}
	};

	const [permissions, setPermissions] = useState<IPermission[]>([]);
	const [teams, setTeams] = useState<ITeam[]>([]);
	const [coinAddressData, setCoinAddressData] = useState<ICoinAddress[]>([]);
	const [bankAccountData, setBankAccountData] = useState<IBankAccount[]>([]);
	const [banks, setBanks] = useState<IBank[]>([]);
	const { allRole } = useContext(RoleStateContext);
	const dispatch = useContext(RoleDispatchContext) as React.Dispatch<ActionType>;

	const listCoinAddressData = async () => {
		try {
			const addresses = await listCoinAddress();
			setCoinAddressData(addresses.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Role Fatal error');
		}
	};

	const getBankAccount = async () => {
		try {
			const account = await listBankAccount();
			setBankAccountData(account.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Bank Account Fatal error');
		}
	};

	const listAllBanks = async () => {
		try {
			const banksData = await listBanks();
			setBanks(banksData.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Banks Fatal error');
		}
	};

	const listRole = async () => {
		try {
			dispatch(await listRoleAction());
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Role Fatal error');
		}
	};

	const listPermissions = async () => {
		try {
			const perm: any = await getPermissions();
			setPermissions(perm.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Permission Fatal error');
		}
	};

	const listTeams = async (pageQuery?: IPageQuery) => {
		try {
			const allteams: any = await getTeams(pageQuery);
			setTeams(allteams.data.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Permission Fatal error');
		}
	};

	useEffect(() => {
		wallet_coins();
		listRole();
		listPermissions();
		listTeams();
		listCoinAddressData();
		getBankAccount();
		listAllBanks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			<Route
				element={
					<>
						<PageTitle breadcrumbs={settingsBreadCrumbs}>Settings</PageTitle>
						<SettingsHeader />
						<Outlet />
					</>
				}
			>
				<Route path={ROUTES.PERSONAL} element={<Personal />} />
				<Route path={ROUTES.BUSINESS} element={<Business />} />
				<Route
					path={ROUTES.PAYMENT}
					element={
						<Payment
							accepted_coins={accepted_coins}
							coinAddressData={coinAddressData}
							accepted_fiats={accepted_fiats}
							bankAccountData={bankAccountData}
							banks={banks}
							setCoinAddressData={setCoinAddressData}
							getBankAccount={getBankAccount}
						/>
					}
				/>
				<Route
					path={ROUTES.PREFERENCES}
					element={<Preferences accepted_coins={accepted_coins} />}
				/>
				<Route
					path={ROUTES.TEAM}
					element={
						<Team
							permissions={permissions}
							teams={teams}
							listTeams={listTeams}
							allRole={allRole}
						/>
					}
				/>
				<Route path={ROUTES.APIWEBHOOK} element={<ApiWebhook />} />

				<Route index element={<Navigate to={ROUTES.PERSONAL} />} />

				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default SettingsRoutes;
