/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { IWallet } from '../types/_model';

type Props = {
	className: string;
	color: string;
	fiatBalance: IWallet[];
	coinBalance: IWallet[];
};

const MixedWidget1: React.FC<Props> = ({ className, color, fiatBalance, coinBalance }) => {
	return (
		<div className={`card ${className}`}>
			{/* begin::Body */}
			<div className="card-body p-0">
				{/* begin::Header */}
				<div className={`px-9 pt-8 card-rounded h-275px w-100 bg-${color}`}>
					{/* begin::Heading */}
					<div className="d-flex flex-stack">
						<h3 className="m-0 text-white fw-bolder fs-3">Available Balance</h3>
					</div>
					{/* end::Heading */}
					{/* begin::Balance */}
					<div className="d-flex text-center flex-column text-white pt-8">
						<span className="fw-bold fs-7">Your Balance</span>

						<span className="fw-bolder fs-2x pt-1">
							{fiatBalance[0]?.code} {fiatBalance[0]?.available_balance}
						</span>
					</div>
					{/* end::Balance */}
				</div>
				{/* end::Header */}
				{/* begin::Items */}
				<div
					className="shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white overflow-scroll"
					style={{ marginTop: '-100px', maxHeight: '220px'}}
				>
					{/* begin::Item */}
					{Object.values(
						coinBalance.map((balanceData, index) => {
							return (
								<div className="d-flex align-items-center mb-6" key={index}>
									<div className="symbol symbol-45px w-40px me-5">
										<span className="symbol-label bg-lighten">
											<img
												src={balanceData.logo_url}
												className="w-35px"
												alt="coin icon"
											/>
										</span>
									</div>

									<div className="d-flex align-items-center flex-wrap w-100">
										<div className="mb-1 pe-3 flex-grow-1">
											<a
												href="#"
												className="fs-5 text-gray-800 text-hover-primary fw-bolder"
											>
												{balanceData.name}
											</a>
											<div className="text-gray-400 fw-bold fs-7">
												{balanceData.available_balance} {balanceData.code}
											</div>
										</div>
										<div className="d-flex align-items-center">
											{/* TODO: balance should have there fiat converts */}
											<div className="fw-bolder fs-5 text-gray-800 pe-1">
												$0
											</div>
										</div>
									</div>
								</div>
							);
						})
					)}
				</div>
				{/* end::Items */}
			</div>
			{/* end::Body */}
		</div>
	);
};

export { MixedWidget1 };
