import { ActionType } from '../../types/IAuth';
import { IRole } from '../../types/_model';
import { roleActions } from './role.actions';

export type RoleType = {
	role: IRole | undefined;
	allRole: IRole[];
	loading: boolean;
	error: boolean;
};

export const ROLE_INITIAL_STATE: RoleType = {
	role: undefined,
	allRole: [],
	loading: false,
	error: false,
};

export const roleReducer = (state = ROLE_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case roleActions.ROLE_ACTION:
			return {
				...state,
				role: { ...state.role, ...action.payload },
				loading: false,
			};
		case roleActions.ROLE_FOUND:
			return {
				...state,
				role: action.payload,
				loading: false,
			};
		case roleActions.ALL_ROLE:
			return {
				...state,
				allRole: action.payload,
				loading: false,
			};
		case roleActions.ROLE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case roleActions.ROLE_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
