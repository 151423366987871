import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { CUSTOMER_INITIAL_STATE, customerReducer } from './customer.reducer';

export const CustomerStateContext = createContext({ ...CUSTOMER_INITIAL_STATE });
export const CustomerDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const CustomerProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(customerReducer, CUSTOMER_INITIAL_STATE);

	return (
		<CustomerDispatchContext.Provider value={dispatch}>
			<CustomerStateContext.Provider value={state}>{children}</CustomerStateContext.Provider>
		</CustomerDispatchContext.Provider>
	);
};
