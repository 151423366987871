import { usecoinsApi } from '../../services/http';
import { WITHDRAWAL_ENDPOINTS } from '../../constants/endpoints';
import { ICoinAddress, ICreateBankAccount } from '../../types/_model';

export const listCoinAddress = async () => {
	try {
		const { data } = await usecoinsApi.get(WITHDRAWAL_ENDPOINTS.COIN_ADDRESS);
		return data;
	} catch (error) {
		throw error;
	}
};

export const addCoinAddress = async (payload: ICoinAddress) => {
	try {
		const { data } = await usecoinsApi.post(WITHDRAWAL_ENDPOINTS.COIN_ADDRESS, payload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteCoinAddress = async (address: string) => {
	try {
		const { data } = await usecoinsApi.delete(
			`${WITHDRAWAL_ENDPOINTS.COIN_ADDRESS}/${address}`
		);
		return data;
	} catch (error) {
		throw error;
	}
};

export const listBankAccount = async () => {
	try {
		const { data } = await usecoinsApi.get(WITHDRAWAL_ENDPOINTS.BANK_ACCOUNT);
		return data;
	} catch (error) {
		throw error;
	}
};

export const addBankAccount = async (payload: ICreateBankAccount) => {
	try {
		const { data } = await usecoinsApi.post(WITHDRAWAL_ENDPOINTS.BANK_ACCOUNT, payload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteBankAccount = async (account: string) => {
	try {
		const { data } = await usecoinsApi.delete(
			`${WITHDRAWAL_ENDPOINTS.BANK_ACCOUNT}/${account}`
		);
		return data;
	} catch (error) {
		throw error;
	}
};
