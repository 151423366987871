import { ActionType } from '../../types/IAuth';
import * as SettingService from '../../services/internal/settings.service';
import { IRole } from '../../types/_model';

export const roleActions = {
	ROLE_ACTION: 'ROLE_ACTION',
	ROLE_FOUND: 'ROLE_FOUND',
	ALL_ROLE: 'ALL_ROLE',
	ROLE_LOADING: 'ROLE_LOADING',
	ROLE_ERROR: 'ROLE_ERROR',
};

export const createRoleAction = async (rolePayload: IRole): Promise<ActionType> => {
	try {
		const createdRole = await SettingService.createRole(rolePayload);
		return {
			type: roleActions.ROLE_ACTION,
			payload: { ...createdRole?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const updateRoleAction = async (
	roleUuid: string,
	rolePayload: IRole
): Promise<ActionType> => {
	try {
		const updatedRole = await SettingService.updateRole(roleUuid, rolePayload);
		return {
			type: roleActions.ROLE_FOUND,
			payload: { ...updatedRole?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const findRoleAction = async (roleId: string): Promise<ActionType> => {
	try {
		const foundRole = await SettingService.findRole(roleId);
		return {
			type: roleActions.ROLE_FOUND,
			payload: { ...foundRole?.data },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const listRoleAction = async (): Promise<ActionType> => {
	try {
		const allRole = await SettingService.listRole();
		return {
			type: roleActions.ALL_ROLE,
			payload: allRole.data,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};
