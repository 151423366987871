import { ActionType } from '../../types/IAuth';
import * as SettlementService from '../../services/internal/settlement.service';
import { IPageQuery } from '../../types/_model';

export const settlementActions = {
	LISTSETTLEMENTS: 'LISTSETTLEMENTS',
	FINDSETTLEMENT: 'FINDSETTLEMENT',
	SETTLEMENT_LOADING: 'SETTLEMENT_LOADING',
	SETTLEMENT_ERROR: 'SETTLEMENT_ERROR',
};

export const listSettlementsAction = async (pageQuery?: IPageQuery): Promise<ActionType> => {
	try {
		const settlements = await SettlementService.listSettlements(pageQuery);
		return {
			type: settlementActions.LISTSETTLEMENTS,
			payload: settlements?.data,
		};
	} catch (error: any) {
		throw error;
	}
};

export const findSettlementAction = async (settlementId: string): Promise<ActionType> => {
	try {
		const settlements = await SettlementService.findSettlement(settlementId);
		return {
			type: settlementActions.FINDSETTLEMENT,
			payload: { ...settlements.data },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};
export const settlementLoadingAction = (payload: boolean) => {
	return {
		type: settlementActions.SETTLEMENT_LOADING,
		payload,
	};
};
