import React from 'react';
import { PageLink, PageTitle } from '../../layouts';
import SimpleTable from '../../components/table/SimpleTable';
import { TableProvider } from '../../components/table/TableProvider';
import { Card, Search, CardBody } from '../../components';
import { ViewFiatTransferModal } from './ViewFiatTransferModal';
import { FiatTransferData } from '../../store/TableData';

const accountBreadCrumbs: Array<PageLink> = [
	{
		title: 'Transfers',
		path: '/transfers/fiat',
		isSeparator: false,
		isActive: true,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const TransferFiat: React.FC = () => {
	return (
		<TableProvider>
			<PageTitle breadcrumbs={accountBreadCrumbs}>Fiat Transfers</PageTitle>
			<Card>
				<div className="card-header border-0 justify-content-end">
					<Search placeholder="Search Fiat Transfers" />
				</div>

				<CardBody>
					<SimpleTable
						tableData={FiatTransferData}
						totalColumns={7}
						enableButton={true}
					/>
				</CardBody>
			</Card>
			<ViewFiatTransferModal />
		</TableProvider>
	);
};

export default TransferFiat;
