import { useContext, useEffect, useCallback, useState } from 'react';
import { RenderSvg } from '../../components';

import { Button, Modal } from 'react-bootstrap';
import { TableContext } from '../../components/table/TableProvider';
import { moneyFormat, titleCase, toDateFormat } from '../../helpers/utils';
import { InvoiceDispatchContext, InvoiceStateContext } from '../../store/Invoice/invoice.provider';
import { findInvoiceAction, invoiceLoadingAction } from '../../store/Invoice/invoice.actions';
import { ActionType } from '../../types/IAuth';

const ViewInvoiceModal = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);
	const dispatch = useContext(InvoiceDispatchContext) as React.Dispatch<ActionType>;
	const { invoice } = useContext(InvoiceStateContext);
	const [isCopied, setIsCopied] = useState(false);

	const copyToClipboard = (e: React.MouseEvent) => {
		setIsCopied(true);
		const text = e.currentTarget.getAttribute('data-clipboard-text');
		if (text) {
			navigator.clipboard.writeText(text);
			setTimeout(() => {
				setIsCopied(false);
			}, 2000);
		}
	};

	const dispatchFindCustomer = useCallback(async () => {
		try {
			dispatch(invoiceLoadingAction(true));
			dispatch(await findInvoiceAction(activeTableId));
			dispatch(invoiceLoadingAction(false));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Customer Fatal error');
			dispatch(invoiceLoadingAction(false));
		}
	}, [activeTableId, dispatch]);

	useEffect(() => {
		if (showModal) {
			dispatchFindCustomer();
		}
	}, [dispatchFindCustomer, showModal]);

	const lastKeyEntry = invoice && Object.keys(invoice).pop();

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Invoice Details</Modal.Title>
					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						<button
							className="btn btn-icon btn-sm btn-active-icon-primary"
							onClick={handleSetModal}
						>
							<RenderSvg
								path="/media/icons/arrows/arr061.svg"
								className="svg-icon-1"
							/>
						</button>
					</div>
				</Modal.Header>

				<Modal.Body>
					<div className={`row gy-1`}>
						{/* begin::Input group */}
						{invoice &&
							Object.entries(invoice).map(([key, value]: any, indexData) => {
								if (key === 'id') {
									return null;
								}
								if (key === 'items') {
									return [];
								}
								if (key === 'total' || key === 'amount') {
									return (
										<div className="col-md-6" key={indexData}>
											<div className="d-flex fv-row">
												<div className="form-check form-check-custom form-check-solid">
													<label
														className="form-check-label"
														htmlFor="kt_modal_update_role_option_0"
													>
														<div className="fw-bolder text-gray-800">
															{titleCase(key)}
														</div>
														<div className="text-gray-600">
															{moneyFormat(value)}
														</div>
													</label>
												</div>
											</div>

											{lastKeyEntry === key ? (
												<></>
											) : (
												<div className="separator separator-dashed my-5"></div>
											)}
										</div>
									);
								}
								if (
									key === 'created_at' ||
									key === 'due_date' ||
									key === 'updated_at'
								) {
									return (
										<div className="col-md-6" key={indexData}>
											<div className="d-flex fv-row">
												<div className="form-check form-check-custom form-check-solid">
													<label
														className="form-check-label"
														htmlFor="kt_modal_update_role_option_0"
													>
														<div className="fw-bolder text-gray-800">
															{titleCase(key)}
														</div>
														<div className="text-gray-600">
															{toDateFormat(value)}
														</div>
													</label>
												</div>
											</div>

											{lastKeyEntry === key ? (
												<></>
											) : (
												<div className="separator separator-dashed my-5"></div>
											)}
										</div>
									);
								}
								if (key === 'payment_link') {
									return (
										<div className="col-md-6" key={indexData}>
											<div className="d-flex fv-row">
												<div className="form-check form-check-custom form-check-solid">
													<label
														className="form-check-label"
														htmlFor="kt_modal_update_role_option_0"
													>
														<div className="fw-bolder text-gray-800">
															{titleCase(key)}
															{isCopied && (
																<small className="text-success">
																	{' '}
																	copied
																</small>
															)}
														</div>
														<div className="text-gray-600">
															{value}
															<button
																type="button"
																data-clipboard-text={value}
																className="btn btn-icon"
																onClick={copyToClipboard}
															>
																<RenderSvg
																	path="/media/icons/copy.svg"
																	className="svg-icon-hx"
																	svgClassName="h-25px w-25px"
																/>
															</button>
														</div>
													</label>
												</div>
											</div>

											{lastKeyEntry === key ? (
												<></>
											) : (
												<div className="separator separator-dashed my-5"></div>
											)}
										</div>
									);
								}
								return (
									<div className="col-md-6" key={indexData}>
										<div className="d-flex fv-row">
											<div className="form-check form-check-custom form-check-solid">
												<label
													className="form-check-label"
													htmlFor="kt_modal_update_role_option_0"
												>
													<div className="fw-bolder text-gray-800">
														{titleCase(key)}
													</div>
													<div className="text-gray-600">{value}</div>
												</label>
											</div>
										</div>

										{lastKeyEntry === key ? (
											<></>
										) : (
											<div className="separator separator-dashed my-5"></div>
										)}
									</div>
								);
							})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { ViewInvoiceModal };
