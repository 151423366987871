import React, { useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { checkIsActive, toAbsoluteUrl } from '../../../helpers/utils';
import { useLayout } from '../../index';
import { IBusiness } from '../../../types/IBusiness';

type Props = {
	children: React.ReactNode;
	business: IBusiness | undefined;
	to: string;
	icon?: string;
	fontIcon?: string;
};

const AsideBusinessDropdown: React.FC<Props> = ({ children, to, business }) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { aside } = config;
	const uuidLength = 8;

	const [show, setShow] = useState(false);

	const handleSetShow = () => setShow((show) => !show);

	return (
		<div
			className={clsx(
				'menu-item',
				{ 'here hover show': show },
				'menu-accordion w-full flex-column-auto bg-gray-800'
			)}
		>
			<div
				onClick={handleSetShow}
				className="menu-link p-4 d-flex justify-content-space-between"
			>
				{/* <div className="symbol symbol-20px ms-3">
					<img
						alt="business-logo"
						src={
							business?.logo_url
								? business?.logo_url
								: toAbsoluteUrl('/media/logos/usecoins.png')
						}
					/>
				</div> */}
				<div className="d-flex align-items-center justify-content-between w-100">
					<div className="d-flex  align-items-start flex-column">
						<span className="menu-title justify-content-center text-white fs-3">
							{business?.business_name}
						</span>
						<small className="d-flex fs-8 text-muted justify-content-center">
							{business?.uuid.substring(0, uuidLength)}
						</small>
					</div>
					<span className="menu-arrow"></span>
				</div>
			</div>
			<div className={clsx('menu-sub menu-sub-accordion', { 'menu-active-bg': isActive })}>
				{children}
			</div>
		</div>
	);
};

export { AsideBusinessDropdown };
