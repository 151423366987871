import React, { useContext } from 'react';
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { AuthStateContext } from '../store/Auth/auth.provider';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

export const AppRoutes: React.FC = () => {
	const { user } = useContext(AuthStateContext);
	const currentUser = user;
	return (
		<Router>
			<Routes>
				{currentUser ? (
					<Route path={ROUTES.ROOT} element={<PrivateRoutes />} />
				) : (
					<>
						<Route path={ROUTES.AUTH_ROOT} element={<PublicRoutes />} />
						<Route path="*" element={<Navigate to="auth" />} />
					</>
				)}
			</Routes>
		</Router>
	);
};
