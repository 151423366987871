import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { INVOICE_INITIAL_STATE, invoiceReducer } from './invoice.reducer';

export const InvoiceStateContext = createContext({ ...INVOICE_INITIAL_STATE });
export const InvoiceDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const InvoiceProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(invoiceReducer, INVOICE_INITIAL_STATE);

	return (
		<InvoiceDispatchContext.Provider value={dispatch}>
			<InvoiceStateContext.Provider value={state}>{children}</InvoiceStateContext.Provider>
		</InvoiceDispatchContext.Provider>
	);
};
