import { Card, TableProvider } from '../../components';
import { PageLink, PageTitle } from '../../layouts';
import TransferModal from '../../components/transfer/TransferModal';
import React, { useEffect, useState } from 'react';
import SimpleTable2 from '../../components/table/SimpleTable2';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { listWallets } from '../../services/internal/wallet.service';
import { IWallet } from '../../types/_model';

const balanceBreadCrumbs: Array<PageLink> = [
	{
		title: 'Balance',
		path: '/balance',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const Balance = () => {
	const [showModal, setShowModel] = useState(false);
	const [isFiat, setIsFiat] = useState<boolean>(true);
	const [loading, setLoading] = useState<boolean>(true);

	const triggerModel = (show: boolean) => {
		// the callback. Use a better name
		setShowModel(show);
	};

	const [walletCoinBalance, setWalletCoinBalance] = React.useState<IWallet[]>([]);
	const [walletFiatBalance, setWalletFiatBalance] = React.useState<IWallet[]>([]);

	const wallet_coins = async () => {
		try {
			setLoading(true);
			const wallets = await listWallets();
			setWalletCoinBalance(wallets.data.coins);
			setWalletFiatBalance(wallets.data.fiat);
			setLoading(false);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Wallet Fatal error');
		}
	};

	useEffect(() => {
		wallet_coins();
	}, []);

	return (
		<>
			<PageTitle breadcrumbs={balanceBreadCrumbs}>Balance Management</PageTitle>

			<TableProvider>
				<Card className="card card-custom">
					<div className="card-header card-header-stretch overflow-auto">
						{/*begin::Tabs*/}
						<ul
							className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
							role="tablist"
						>
							<li className="nav-item">
								<Link
									to=""
									className={clsx(`nav-link cursor-pointer`, {
										active: isFiat,
									})}
									onClick={() => setIsFiat(true)}
									role="tab"
								>
									Fiat
								</Link>
							</li>

							<li className="nav-item">
								<Link
									to="#"
									className={clsx(`nav-link cursor-pointer`, {
										active: !isFiat,
									})}
									onClick={() => setIsFiat(false)}
									role="tab"
								>
									Coin
								</Link>
							</li>
						</ul>

						<div className="py-5">
							<TransferModal show={showModal} triggerModel={triggerModel} />

							<Link
								to="#"
								type="button"
								onClick={() => triggerModel(true)}
								className="btn btn-light"
							>
								Transfer
							</Link>
						</div>
					</div>
				</Card>

				<div className="pt-1">
					<div className={`	 ${!isFiat && 'd-none'}`}>
						<SimpleTable2
							columns={['Currency', 'Balance']}
							tableData={walletFiatBalance}
							enableButton={true}
							loading={loading}
							// className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
							data-kt-table-widget-3="all"
						>
							{Object.values(walletFiatBalance).length > 0 &&
								walletFiatBalance.map((balanceData, index) => {
									return (
										<tr key={index} className="odd">
											<td className="">
												{/* end::Item */}
												<div className="d-flex align-items-center me-5">
													<span className=" bg-light-info">
														<span className="svg-icon svg-icon-2x svg-icon-info">
															<div className="">
																<div className="symbol symbol-45px w-40px">
																	<span className="symbol-label bg-lighten">
																		<img
																			src={
																				balanceData.logo_url
																			}
																			className="w-35px"
																			alt="coin icon"
																		/>
																	</span>
																</div>
															</div>
														</span>
													</span>
													<div className="flex-grow-1 mx-4">
														<Link
															to="#"
															className="text-gray-800 text-hover-primary fs-5 lh-0"
														>
															{balanceData.code}
														</Link>
														<span className="text-gray-400 fw-bold d-block fs-6">
															{balanceData.name}
														</span>
													</div>
												</div>
											</td>

											<td>
												<span className="text-dark  d-block fs-6">
													{balanceData.available_balance}
												</span>
												<span className="text-gray-400 fw-bold d-block fs-7">
													{balanceData.code} {balanceData.book_balance}
												</span>
											</td>

											<td className="text-end">
												<div className="">
													<button
														onClick={() => {}}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  me-1 mt-2"
													>
														Deposit
													</button>

													<button
														onClick={() => {}}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  mt-2"
													>
														Withdraw
													</button>
												</div>
											</td>
										</tr>
									);
								})}
						</SimpleTable2>
					</div>
					<div className={`${isFiat && 'd-none'}`}>
						<SimpleTable2
							columns={['Coin', 'Balance']}
							tableData={walletCoinBalance}
							enableButton={true}
							loading={loading}
							// className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3"
							data-kt-table-widget-3="all"
						>
							{Object.values(walletCoinBalance).length > 0 &&
								walletCoinBalance.map((balanceData, index) => {
									return (
										<tr key={index} className="odd">
											<td className="">
												{/* end::Item */}
												<div className="d-flex align-items-center me-5">
													<div className="symbol symbol-45px w-40px">
														<span className="symbol-label bg-lighten">
															<img
																src={balanceData.logo_url}
																className="w-35px"
																alt="coin icon"
															/>
														</span>
													</div>

													<div className="flex-grow-1 mx-4">
														<Link
															to="#"
															className="text-gray-800 text-hover-primary fs-5  lh-0"
														>
															{balanceData.code}
														</Link>
														<span className="text-gray-400 fw-bold d-block fs-6">
															{balanceData.name}
														</span>
													</div>
												</div>
											</td>
											<td>
												<span className="text-dark  d-block fs-6">
													{balanceData.available_balance}
												</span>
												<span className="text-gray-400 fw-bold d-block fs-7">
													{balanceData.code} {balanceData.book_balance}
												</span>
											</td>

											<td className="text-end">
												<div className="">
													<button
														onClick={() => {}}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  me-1 mt-2"
													>
														Deposit
													</button>

													<button
														onClick={() => {}}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  mt-2"
													>
														Withdraw
													</button>
												</div>
											</td>
										</tr>
									);
								})}
						</SimpleTable2>
					</div>
				</div>
			</TableProvider>
		</>
	);
};

export default Balance;
