import { usecoinsApi } from '../../services/http';
import { CUSTOMERS_ENDPOINTS } from '../../constants/endpoints';
import { buildPageQuery } from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';

export const listCustomers = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${CUSTOMERS_ENDPOINTS.CUSTOMERS}${buildPageQuery(pageQuery)}`;

		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error) {
		throw error;
	}
};

export const findCustomer = async (customerId: string) => {
	try {
		const { data } = await usecoinsApi.get(`${CUSTOMERS_ENDPOINTS.CUSTOMERS}/${customerId}`);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};
