import { useEffect, useRef } from 'react';
import { MenuComponent, DrawerComponent, ToggleComponent } from '../components/ts';

import { useLayout } from './index';

export function MasterInit() {
	const { config } = useLayout();
	const isFirstRun = useRef(true);
	const pluginsInitialization = () => {
		isFirstRun.current = false;
		setTimeout(() => {
			ToggleComponent.bootstrap();
			DrawerComponent.bootstrap();
			MenuComponent.bootstrap();
		}, 500);
	};

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			pluginsInitialization();
		}
	}, [config]);

	return <></>;
}
