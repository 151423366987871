import { Navigate, Route, Routes, Outlet } from 'react-router-dom';
import TransferHeader from '../views/transfers/TransferHeader';
import * as ROUTES from '../constants/routes';
import TransferFiat from '../views/transfers/TransferFiat';
import TransferCoin from '../views/transfers/TransferCoin';
import NotFound from '../views/errors/NotFound';

const TransferRoutes = () => {
	return (
		<Routes>
			<Route
				element={
					<>
						<TransferHeader />
						<Outlet />
					</>
				}
			>
				<Route path={ROUTES.FIAT} element={<TransferFiat />} />
				<Route path={ROUTES.COIN} element={<TransferCoin />} />

				<Route index element={<Navigate to={ROUTES.FIAT} />} />

				<Route path="*" element={<NotFound />} />
			</Route>
		</Routes>
	);
};

export default TransferRoutes;
