import React, { useContext, useEffect } from 'react';
import { PageLink, PageTitle } from '../../layouts';
import { ViewSettlementModal } from './ViewSettlementModal';
import * as ROUTES from '../../constants/routes';
import {
	SettlementDispatchContext,
	SettlementStateContext,
} from '../../store/Settlement/settlement.provider';
import { ActionType } from '../../types/IAuth';
import {
	listSettlementsAction,
	settlementLoadingAction,
} from '../../store/Settlement/settlement.actions';
import { TableProvider } from '../../components';
import SimpleTable from '../../components/table/SimpleTable2';
import { IPageQuery, ISettlement } from '../../types/_model';
import Pagination from '../../components/Pagination';

const settlementBreadCrumbs: Array<PageLink> = [
	{
		title: 'Settlement Management',
		path: ROUTES.SETTLEMENT,
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const formatSettlements = (settlements: ISettlement[]) => {
	if (settlements.length <= 0) return [];
	return settlements.map((settlement: ISettlement) => {
		return {
			uuid: settlement.uuid,
			amount: settlement.amount,
			account_number: settlement.account_number,
			currency: settlement.currency_id,
			status: settlement.status,
			created_at: settlement.created_at,
		};
	});
};

const Settlement: React.FC = () => {
	const { loading, settlements, meta } = useContext(SettlementStateContext);

	const dispatch = useContext(SettlementDispatchContext) as React.Dispatch<ActionType>;

	const dispatchSettlements = async (pageQuery?: IPageQuery) => {
		try {
			dispatch(settlementLoadingAction(true));
			dispatch(await listSettlementsAction(pageQuery));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Settlement Fatal error');
			dispatch(settlementLoadingAction(false));
		}
	};

	useEffect(() => {
		dispatchSettlements();
	}, []);

	return (
		<TableProvider>
			<PageTitle breadcrumbs={settlementBreadCrumbs}>Settlements List</PageTitle>
			<SimpleTable
				dispatchAction={dispatchSettlements}
				tableData={formatSettlements(settlements)}
				enableButton={true}
				columns={[
					'reference',
					'amount',
					'account number',
					'currency',
					'status',
					'created at',
				]}
				loading={loading}
			></SimpleTable>
			{meta && <Pagination {...meta} dispatchAction={dispatchSettlements} />}
			<ViewSettlementModal />
		</TableProvider>
	);
};

export default Settlement;
