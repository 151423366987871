import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
	Card,
	CardBody,
	Filter,
	FormInput,
	FormSelect,
	RenderSvg,
	Search,
	SimpleTable,
} from '../../components';
import { capitalizeFirstLetter, getStatusColor, titleCase, truncate } from '../../helpers/utils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RoleDispatchContext } from '../../store/Role/role.provider';
import { ActionType } from '../../types/IAuth';
import { createRoleAction, listRoleAction, updateRoleAction } from '../../store/Role/role.actions';
import { IPermission, IRole, ITeam } from '../../types/_model';
import { modifiedValues } from './Business';
import { LayoutContext } from '../../layouts';
import { inviteUser } from '../../services/internal/businessUser.service';

const RoleSchema = Yup.object().shape({
	title: Yup.string().required('Role Title is required'),
	description: Yup.string(),
	permissions: Yup.array(),
	status: Yup.string(),
});

let initialValues = {
	title: '',
	description: '',
	permissions: [],
};

const teamSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	role: Yup.string().required('Role is required'),
});

let teaminitialValues = {
	email: '',
	role: '',
};

const formatRoles = (roles: IRole[]) => {
	if (roles.length <= 0) return [];
	return roles.map((role: IRole) => {
		return {
			id: truncate(role.uuid, 8),
			title: role.title,
			description: role.description,
		};
	});
};

const formatTeams = (teams: ITeam[]) => {
	if (teams.length <= 0) return [];
	return teams.map((team: ITeam) => {
		return {
			name: team.firstname + ' ' + team.lastname,
			roles: team.roles,
			email: team.email,
			last_login: team.last_login_time,
			status: team.status,
		};
	});
};
type Prop = {
	teams: ITeam[];
	permissions: IPermission[];
	allRole: IRole[];
	listTeams: () => Promise<void>;
};
const Team: React.FC<Prop> = ({ teams, permissions, allRole, listTeams }: Prop) => {
	const [tab, setTab] = useState('Member');
	const [showModal, setShowModal] = useState(false);
	const handleSetModal = () => setShowModal(!showModal);

	const dispatch = useContext(RoleDispatchContext) as React.Dispatch<ActionType>;

	const [showUpdateRoleModal, setShowUpdateRoleModal] = useState(false);
	const handleSetShowUpdateRoleModal = () => setShowUpdateRoleModal(!showUpdateRoleModal);

	const [viewPermission, setViewPermission] = useState<IPermission[]>([]);

	const [arrangePermission, setArrangePermission] = useState<IPermission[][]>([]);

	const [showPermissionModal, setShowPermissionModal] = useState(false);
	const handleSetPermissionModal = (permission?: IPermission[]) => {
		setShowPermissionModal(!showPermissionModal);
		permission && setViewPermission(permission);
	};

	const [updateRole, setUpdateRole] = useState<IRole>(initialValues);

	const handleUpdateRole = (role: IRole) => {
		setUpdateRole(role);
		handleSetShowUpdateRoleModal();
	};

	const { setError, setSuccess } = useContext(LayoutContext);

	let roleUpdateValues = {
		title: updateRole.title,
		description: updateRole.description || '',
		permissions: updateRole.permissions.map((permission: IPermission) => permission.slug),
		status: updateRole.status || '',
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: showUpdateRoleModal ? roleUpdateValues : initialValues,
		validationSchema: RoleSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const hasChanged = modifiedValues(
					showUpdateRoleModal ? roleUpdateValues : initialValues,
					values
				);
				if (Object.keys(hasChanged).length > 0) {
					if (showUpdateRoleModal && updateRole.uuid) {
						dispatch(await updateRoleAction(updateRole.uuid, values as IRole));
						setShowUpdateRoleModal(false);
					} else {
						setShowModal(false);
						dispatch(
							await createRoleAction({ status: 'active', ...hasChanged } as IRole)
						);
					}
					setSuccess(`Role ${showUpdateRoleModal ? 'updated' : 'created'} successfully`);
					dispatch(await listRoleAction());
					setSubmitting(false);
					return;
				}
				setShowModal(false);
				setShowUpdateRoleModal(false);
				setError('No changes were made');
			} catch (error: any) {
				setShowModal(false);
				setShowUpdateRoleModal(false);
				setError(
					error.response.data
						? `${error.response.data.message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	const teamFormik = useFormik({
		enableReinitialize: true,
		initialValues: teaminitialValues,
		validationSchema: teamSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const invitation: any = await inviteUser(values);
				handleSetModal();
				setSuccess(invitation.message);
				setSubmitting(false);
				return;
			} catch (error: any) {
				console.error(error);
				handleSetModal();
				setError(
					error.response.data
						? titleCase(error.response.data.message)
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	useEffect(() => {
		let arrange_permissions: IPermission[][] = [];
		const permissionModuleCounts = permissions.reduce(
			(m, { module }) => m.set(module, m.has(module)),
			new Map()
		);

		permissionModuleCounts.forEach((count, module_perm) => {
			arrange_permissions.push(permissions.filter(({ module }) => module === module_perm));
		});
		setArrangePermission(arrange_permissions);
	}, [permissions]);

	return (
		<div className="card card-custom">
			<div className="card-header card-header-stretch overflow-auto">
				<ul
					className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
					role="tablist"
				>
					<li className="nav-item">
						<button
							className={clsx(`nav-link cursor-pointer bg-transparent`, {
								active: tab === 'Member',
							})}
							onClick={() => setTab('Member')}
							role="tab"
						>
							Member
						</button>
					</li>
					<li className="nav-item">
						<button
							className={clsx(`nav-link cursor-pointer bg-transparent`, {
								active: tab === 'Role',
							})}
							onClick={() => setTab('Role')}
							role="tab"
						>
							Roles
						</button>
					</li>
				</ul>
			</div>

			<div className="tab-content pt-3">
				<div className={clsx('tab-pane', { active: tab === 'Member' })}>
					<Card>
						<div className="card-header border-0 pt-6">
							<Search dispatchAction={listTeams} placeholder="Search Members" />
							<div className="card-toolbar">
								<div
									className="d-flex justify-content-end"
									data-kt-user-table-toolbar="base"
								>
									<Filter
										allRole={allRole}
										isLoading={false}
										html={''}
										dispatchAction={listTeams}
									/>
									<Button
										type="button"
										className="btn btn-sm btn-light"
										onClick={handleSetModal}
									>
										<RenderSvg
											path="/media/icons/arrows/arr075.svg"
											className="svg-icon-2"
										/>
										Invite
									</Button>
								</div>
							</div>
						</div>

						<CardBody>
							<SimpleTable
								tableData={formatTeams(teams)}
								totalColumns={6}
								tdClass="text-muted"
								enableButton={true}
							>
								{Object.values(teams).length > 0 ? (
									teams.map((member) => {
										return (
											<tr key={member.uuid} className="odd">
												<td className="text-dark fs-6">
													{member.firstname}
												</td>
												<td className="text-dark fs-6">
													{member.roles?.map((item) => (
														<span key={item.uuid}>{item.title} </span>
													))}
												</td>
												<td className="text-dark fs-6">{member.email}</td>
												<td className="text-dark fs-6">
													{member.last_login_time}
												</td>
												<td className="text-dark fs-6">
													<span
														className={`badge badge-light-${getStatusColor(
															member.status
														)}`}
													>
														{member.status &&
															capitalizeFirstLetter(member.status)}
													</span>
												</td>

												<td className="text-end">
													<div className="">
														<button
															onClick={() => {}}
															className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  mt-2"
														>
															Remove
														</button>
													</div>
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan={7}>
											<div className="d-flex text-center w-100 align-content-center justify-content-center">
												No matching records found
											</div>
										</td>
									</tr>
								)}
							</SimpleTable>
						</CardBody>
					</Card>
				</div>

				<div className={clsx('tab-pane', { active: tab === 'Role' })}>
					<Card>
						<div
							className="d-flex justify-content-end"
							data-kt-user-table-toolbar="base"
						>
							<Button
								type="button"
								className="btn btn-sm btn-light-primary me-3"
								onClick={handleSetModal}
							>
								<RenderSvg
									path="/media/icons/arrows/arr075.svg"
									className="svg-icon-2"
								/>
								Add Role
							</Button>
						</div>

						<CardBody>
							<SimpleTable
								tableData={formatRoles(allRole)}
								totalColumns={4}
								tdClass="text-muted"
								enableButton={true}
							>
								{Object.values(allRole).length > 0 ? (
									allRole.map((role) => {
										return (
											<tr key={role.uuid} className="odd">
												<td className="text-dark fs-6">
													{truncate(role.uuid, 8)}
												</td>
												<td className="text-dark fs-6">{role.title}</td>
												<td className="text-dark fs-6">
													{role.description}
												</td>

												<td className="text-end">
													<button
														onClick={() =>
															handleSetPermissionModal(
																role.permissions
															)
														}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm mt-2 me-4"
													>
														View Permissions
													</button>

													<button
														onClick={() => handleUpdateRole(role)}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm mt-2 me-4"
													>
														Edit Role
													</button>
												</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan={7}>
											<div className="d-flex text-center w-100 align-content-center justify-content-center">
												No matching records found
											</div>
										</td>
									</tr>
								)}
							</SimpleTable>
						</CardBody>
					</Card>
				</div>
			</div>

			{/* New Create modal */}
			<Modal show={showModal}>
				<Modal.Header>
					<Modal.Title>Create New {tab}</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetModal}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							{/*Member Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
									tab !== 'Member' && 'd-none'
								} `}
								id="Member"
								noValidate
								onSubmit={teamFormik.handleSubmit}
							>
								<div className="fv-row mb-7">
									<FormInput
										labelName="Email"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Member Email"
										type="email"
										{...teamFormik.getFieldProps('email')}
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										labelName="Select Role"
										labelClass="form-label fw-bolder text-dark fs-6"
										className={
											'form-control form-control-lg form-control-solid'
										}
										{...teamFormik.getFieldProps('role')}
									>
										<option disabled value="">
											{' '}
											Select role
										</option>
										{allRole.map((role, index) => {
											return (
												<option key={role.uuid} defaultValue={role.title}>
													{role.title}
												</option>
											);
										})}
										;
									</FormSelect>
								</div>
							</form>

							{/*Role Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
									tab !== 'Role' && 'd-none'
								} `}
								onSubmit={formik.handleSubmit}
								noValidate
								id="Role"
							>
								<div className="fv-row mb-7">
									<FormInput
										labelName="Role Name"
										labelClass="form-label fw-bolder text-dark fs-6"
										{...formik.getFieldProps('title')}
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Role Description"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Enter Description"
										{...formik.getFieldProps('description')}
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								{arrangePermission.map((allPerms, index) => {
									return (
										<div className="fv-row mb-7" key={index}>
											<p className="form-label fw-bolder text-dark fs-6 mb-5">
												{allPerms[0].module}
											</p>

											{allPerms.map((perm) => {
												return (
													<label
														key={perm.uuid}
														className="form-check form-check-custom form-check-solid align-items-start mb-2"
													>
														<input
															className="form-check-input me-3"
															type="checkbox"
															{...formik.getFieldProps('permissions')}
															value={perm.slug}
														/>

														<span className="form-check-label d-flex flex-column align-items-start">
															<span className="fs-5">
																{perm.description}
															</span>
														</span>
													</label>
												);
											})}
											<div className="separator mb-2"></div>
										</div>
									);
								})}
							</form>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>

					{tab === 'Role' ? (
						<Button
							disabled={formik.isSubmitting || !formik.isValid}
							className={'btn btn-light'}
							type="submit"
							form={tab}
						>
							{!formik.isSubmitting && (
								<span className="indicator-label">Submit</span>
							)}

							{formik.isSubmitting && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</Button>
					) : (
						<Button
							disabled={teamFormik.isSubmitting || !teamFormik.isValid}
							className={'btn btn-light'}
							type="submit"
							form={tab}
						>
							{!teamFormik.isSubmitting && (
								<span className="indicator-label">Submit</span>
							)}

							{teamFormik.isSubmitting && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</Button>
					)}
				</Modal.Footer>
			</Modal>

			{/* Update Role modal */}
			<Modal show={showUpdateRoleModal} onHide={handleSetShowUpdateRoleModal}>
				<Modal.Header>
					<Modal.Title>Update Role</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetShowUpdateRoleModal}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework`}
								onSubmit={formik.handleSubmit}
								noValidate
								id="updateRole"
							>
								<div className="fv-row mb-7">
									<FormInput
										labelName="Role Name"
										labelClass="form-label fw-bolder text-dark fs-6"
										{...formik.getFieldProps('title')}
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Role Description"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Enter Description"
										{...formik.getFieldProps('description')}
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										labelName="Select Role Status"
										labelClass="form-label fw-bolder text-dark fs-6"
										className={
											'form-control form-control-lg form-control-solid'
										}
										{...teamFormik.getFieldProps('status')}
									>
										<option value={updateRole.status}>
											{updateRole.status}{' '}
										</option>
										{updateRole.status === 'active' && (
											<option value="inactive">Inactive</option>
										)}
										{updateRole.status === 'inactive' && (
											<option value="active">Active</option>
										)}
									</FormSelect>
								</div>

								{arrangePermission.map((allPerms, index) => {
									return (
										<div className="fv-row mb-7" key={index}>
											<p className="form-label fw-bolder text-dark fs-6 mb-5">
												{allPerms[0].module}
											</p>

											{allPerms.map((perm) => {
												return (
													<label
														key={perm.uuid}
														className="form-check form-check-custom form-check-solid align-items-start mb-2"
													>
														<input
															className="form-check-input me-3"
															type="checkbox"
															{...formik.getFieldProps('permissions')}
															value={perm.slug}
															defaultChecked={updateRole.permissions.some(
																(p) => p.slug === perm.slug
															)}
														/>

														<span className="form-check-label d-flex flex-column align-items-start">
															<span className="fs-5">
																{perm.description}
															</span>
														</span>
													</label>
												);
											})}
											<div className="separator mb-2"></div>
										</div>
									);
								})}
							</form>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetShowUpdateRoleModal}>
						Close
					</Button>

					{/* <Button className={'btn btn-light'}>Update Role</Button> */}

					<Button
						disabled={formik.isSubmitting || !formik.isValid}
						className={'btn btn-light'}
						type="submit"
						form="updateRole"
					>
						{!formik.isSubmitting && (
							<span className="indicator-label">Update Role</span>
						)}

						{formik.isSubmitting && (
							<span className="indicator-progress" style={{ display: 'block' }}>
								Please wait...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</Button>
				</Modal.Footer>
			</Modal>

			{/* View Permissions modal */}
			<Modal show={showPermissionModal} onHide={handleSetPermissionModal}>
				<Modal.Header>
					<Modal.Title>View Permission</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={() => handleSetPermissionModal()}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="row gy-1">
						{viewPermission.map((perm, i) => {
							return (
								<div className="col-md-6" key={perm.uuid}>
									<div className="d-flex fv-row">
										<div className="form-check form-check-custom form-check-solid">
											<label
												className="form-check-label"
												htmlFor="kt_modal_update_role_option_0"
											>
												<div className="text-gray-600">{perm.slug}</div>
											</label>
										</div>
									</div>
									<div className="separator separator-dashed my-5"></div>
								</div>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => handleSetPermissionModal()}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Team;
