import React, { useContext, useEffect, useState } from 'react';
import SimpleTable from '../../components/table/SimpleTable2';
import { ListsWidget5 } from '../../components/ListsWidget5';
import { MixedWidget1 } from '../../components/MixedWidget1';
import { MixedWidget11 } from '../../components/MixedWidget11';
import { PageLink, PageTitle } from '../../layouts';
import { listWallets } from '../../services/internal/wallet.service';
import {
	collectionLoadingAction,
	listCollectionsAction,
} from '../../store/Collection/collection.actions';
import {
	CollectionDispatchContext,
	CollectionStateContext,
} from '../../store/Collection/collection.provider';
import { ActionType } from '../../types/IAuth';
import { IAudit, ICollection, IWallet } from '../../types/_model';
import { getDashboard } from '../../services/internal/dashboard.service';
import { useNavigate } from 'react-router-dom';

const dashboardBreadCrumbs: Array<PageLink> = [
	{
		title: 'Dashboard',
		path: '/dashboard',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const formatCollections = (collections: ICollection[]) => {
	if (collections.length <= 0) return [];
	return collections.map((collection: ICollection) => {
		return {
			reference: collection.reference,
			customer: collection.customer.email,
			amount: collection.amount_payable_fiat,
			status: collection.status,
			created_at: collection.created_at,
			amount_payable_coin: `${
				collection.amount_payable_coin ? collection.amount_payable_coin : '..'
			} ${collection.payment_currency ? collection.payment_currency : '..'}`,
		};
	});
};

const Dashboard: React.FC = () => {
	const [walletCoinBalance, setWalletCoinBalance] = React.useState<IWallet[]>([]);
	const [walletFiatBalance, setWalletFiatBalance] = React.useState<IWallet[]>([]);

	const [audit, setAudit] = useState<IAudit[]>([]);
	const [chart, setChart] = useState([]);

	const navigate = useNavigate();

	const wallet_coins = async () => {
		try {
			const wallets = await listWallets();
			setWalletCoinBalance(wallets.data.coins);
			setWalletFiatBalance(wallets.data.fiat);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Wallet Fatal error');
		}
	};

	const handleChangePeriod = (query?: Record<string, any>) => {
		getChart(query);
	};

	const getChart = async (query?: Record<string, any>) => {
		try {
			const response = await getDashboard(query);
			setChart(response.data.chart_data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Dashboard Fatal error');
		}
	};

	const getAudit = async (query?: Record<string, any>) => {
		try {
			const response = await getDashboard(query);
			setAudit(response.data.audit_log.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Dashboard Fatal error');
		}
	};

	const { loading, collections } = useContext(CollectionStateContext);

	const dispatch = useContext(CollectionDispatchContext) as React.Dispatch<ActionType>;

	const dispatchCollections = async () => {
		try {
			dispatch(collectionLoadingAction(true));
			dispatch(await listCollectionsAction());
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Collection Fatal error');
			dispatch(collectionLoadingAction(false));
		}
	};

	const handleShowMore = () => {
		if (collections) {
			navigate('/collections');
			return;
		}
	};

	useEffect(() => {
		dispatchCollections();
		wallet_coins();
		getChart({ months: 11 });
		getAudit();
	}, []);

	return (
		<>
			<PageTitle breadcrumbs={dashboardBreadCrumbs}>Dashboard</PageTitle>
			{/* begin::Row */}
			<div className="row gy-5 g-xl-8">
				<div className="col-xxl-4">
					<MixedWidget1
						className="card-xl-stretch mb-xl-8"
						color="primary"
						fiatBalance={walletFiatBalance}
						coinBalance={walletCoinBalance}
					/>
				</div>
				<div className="col-xxl-4">
					<ListsWidget5 auditData={audit} className="card-xxl-stretch" />
				</div>
				<div className="col-xxl-4">
					<MixedWidget11
						className="card-xxl-stretch"
						chartColor="primary"
						chartHeight="375px"
						chartData={chart}
						handleChangePeriod={handleChangePeriod}
					/>
				</div>
			</div>
			{/* end::Row */}

			{/* begin::Row */}
			<div className="row gy-5 gx-xl-8">
				<div className="col-xl-12">
					<SimpleTable
						tableData={formatCollections(collections)}
						enableButton={false}
						options={{ enableSearch: false, enableFilter: false }}
						columns={['reference', 'customer', 'amount', 'status', 'created_at']}
						loading={loading}
						isSliced
						showMore
						handleShowMore={handleShowMore}
						tableTitle="Recent Transaction Overview"
					></SimpleTable>
				</div>
			</div>
			{/* end::Row */}
		</>
	);
};

export default Dashboard;
