/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import { KTSVG } from '../../components';
import { toAbsoluteUrl } from '../../helpers/utils';
import { useLayout } from '../index';
import { Topbar } from './Topbar';

export function HeaderWrapper() {
	const { config, classes, attributes } = useLayout();
	const { aside } = config;

	return (
		<div
			id="kt_header"
			className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
			{...attributes.headerMenu}
		>
			<div
				className={clsx(
					classes.headerContainer.join(' '),
					'd-flex align-items-stretch justify-content-between'
				)}
			>
				{/* begin::Aside mobile toggle */}
				{aside.display && (
					<div
						className="d-flex align-items-center d-lg-none ms-n3 me-1"
						title="Show aside menu"
					>
						<div
							className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
							id="kt_aside_mobile_toggle"
						>
							<KTSVG
								path="/media/icons/abstract/abs015.svg"
								className="svg-icon-2x mt-1"
							/>
						</div>
					</div>
				)}
				{/* end::Aside mobile toggle */}
				{/* begin::Logo */}
				{!aside.display && (
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
						<Link to="/dashboard" className="d-lg-none">
							<img
								alt="Logo"
								src={toAbsoluteUrl('/media/logos/usecoins-dark.svg')}
								className="h-30px"
							/>
						</Link>
					</div>
				)}
				{/* end::Logo */}

				{aside.display && (
					<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-1">
						{/* <Link to="/" className="">
							<img
								alt="Logo"
								src={toAbsoluteUrl('/media/logos/usecoins-dark.svg')}
								className="h-40px"
							/>
						</Link> */}
					</div>
				)}

				{/* begin::Wrapper */}
				<div className="d-flex align-items-stretch justify-content-between flex-lg-grow-0">
					<div className="d-flex align-items-stretch flex-shrink-0">
						<Topbar />
					</div>
				</div>
				{/* end::Wrapper */}
			</div>
		</div>
	);
}
