import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormInput from '../form-input/FormInput';
import FormSelect from '../form-select/FormSelect';
import RenderSvg from '../renderSvg/RenderSvg';
import Button from '../button/Button';

type TransferModalProps = {
	show?: boolean;
	triggerModel?: any;
};

const TransferModal = ({ show, triggerModel }: TransferModalProps) => {
	const [isFiat, setIsFiat] = useState<boolean>(true);

	return (
		<>
			{/* New transfer modal */}
			<Modal show={show} size="lg">
				<Modal.Header>
					<Modal.Title>
						Create New Transfer
						<div className="d-flex overflow-auto h-55px">
							<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
								<li className="nav-item">
									<button
										style={{ backgroundColor: 'transparent' }}
										onClick={() => setIsFiat(true)}
										className={
											`nav-link text-active-primary me-6 ` +
											(isFiat && 'active')
										}
									>
										Fiat
									</button>
								</li>
								<li className="nav-item">
									<button
										style={{ backgroundColor: 'transparent' }}
										onClick={() => setIsFiat(false)}
										className={
											`nav-link text-active-primary me-6 ` +
											(!isFiat && 'active')
										}
									>
										Coin
									</button>
								</li>
							</ul>
						</div>
					</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={() => triggerModel(false)}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							{/*Fiat Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
									!isFiat && 'd-none'
								} `}
								noValidate
							>
								<div className="fv-row mb-7">
									<FormInput
										labelName="Currency"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Currency"
										type="text"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										className={
											'form-control form-control-lg form-control-solid'
										}
									>
										<option value="">Select Bank </option>
										<option value="gt">Gt Bank</option>
										<option value="first">First Bank</option>
									</FormSelect>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Account Number"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Account Number"
										type="text"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Account Name"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Account Name"
										type="text"
										autoComplete="off"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Remark (optional)"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Remark"
										type="text"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>
							</form>

							{/*Coin Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
									isFiat && 'd-none'
								} `}
								noValidate
							>
								<div className="fv-row mb-7">
									<FormInput
										labelName="Email (Optional)"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Email"
										type="text"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										className={
											'form-control form-control-lg form-control-solid'
										}
									>
										<option value="">Select Coin </option>
										<option value="btc">BTC</option>
										<option value="ltc">LTC</option>
										<option value="usdt">USDT</option>
									</FormSelect>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										className={
											'form-control form-control-lg form-control-solid'
										}
									>
										<option value="">Select Network </option>
										<option value="btc">Trc</option>
										<option value="P2PKH">P2PKH</option>
										<option value="usdt">P2SH</option>
									</FormSelect>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Address"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Enter Address"
										type="text"
										autoComplete="off"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>

								<div className="fv-row mb-7">
									<FormInput
										labelName="Remark (optional)"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Remark"
										type="text"
										className="form-control form-control-lg form-control-solid"
									/>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						type="button"
						className="btn btn-light-danger me-3"
						onClick={() => triggerModel(false)}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default TransferModal;
