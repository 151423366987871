import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { BUSINESS_INITIAL_STATE, businessReducer } from './business.reducer';

export const BusinessStateContext = createContext({ ...BUSINESS_INITIAL_STATE });
export const BusinessDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const BusinessProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(businessReducer, BUSINESS_INITIAL_STATE);

	return (
		<BusinessDispatchContext.Provider value={dispatch}>
			<BusinessStateContext.Provider value={state}>{children}</BusinessStateContext.Provider>
		</BusinessDispatchContext.Provider>
	);
};
