import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { COLLECTION_INITIAL_STATE, collectionReducer } from './collection.reducer';

export const CollectionStateContext = createContext({ ...COLLECTION_INITIAL_STATE });
export const CollectionDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const CollectionProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(collectionReducer, COLLECTION_INITIAL_STATE);

	return (
		<CollectionDispatchContext.Provider value={dispatch}>
			<CollectionStateContext.Provider value={state}>
				{children}
			</CollectionStateContext.Provider>
		</CollectionDispatchContext.Provider>
	);
};
