import React, { useContext, useEffect } from 'react';
import { KTSVG } from '../../components';
import { titleCase } from '../../helpers/utils';
import { TableContext } from '../../components/table/TableProvider';

import { Button, Modal } from 'react-bootstrap';
import { FiatTransferData, IFiatTransfer } from '../../store/TableData';
import { formatTableValue } from '../../components/viewFormats';

const ViewFiatTransferModal: React.FC = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);

	const fiatTransferDetail: IFiatTransfer | undefined = FiatTransferData.find(
		(fiatTransfer) => fiatTransfer.id.toString() === activeTableId
	);

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Fiat Transfer Details</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetModal}
					>
						<KTSVG path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="row gy-1">
						{Object.entries(fiatTransferDetail!).map(([key, value], indexData) => {
							if (key === 'id') {
								return null;
							}
							return (
								<div key={indexData} className="col-12">
									<div className="d-flex fv-row justify-content-between">
										<span className="fw-bolder text-gray-800">
											{titleCase(key)}
										</span>
										<span className="text-gray-600">
											{formatTableValue(key, value)}
										</span>
									</div>
									<div className="separator separator-dashed my-5"></div>
								</div>
							);
						})}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { ViewFiatTransferModal };
