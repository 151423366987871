import { useContext, useState, useEffect } from 'react';
import { RenderSvg, SimpleTable2 } from '../../components';

import { Button, Modal } from 'react-bootstrap';
import { TableContext } from '../../components/table/TableProvider';
import { moneyFormat, titleCase } from '../../helpers/utils';
import {
	CustomerDispatchContext,
	CustomerStateContext,
} from '../../store/Customer/customer.provider';
import { ActionType } from '../../types/IAuth';
import { customerLoadingAction, findCustomerAction } from '../../store/Customer/customer.actions';
import * as CollectionService from '../../services/internal/collection.service';
import { ICollection } from '../../types/_model';
import { formatCollections } from '../collections/Collections';

const calculateInitialState = {
	totalCollection: 0,
	pendingCollection: 0,
	successfulCollection: 0,
};

const ViewCustomerModal = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);
	const dispatch = useContext(CustomerDispatchContext) as React.Dispatch<ActionType>;
	const [isDetail, setIsDetail] = useState<boolean>(true);
	const { customer } = useContext(CustomerStateContext);

	const [loadingCustomerCollection, setLoadingCustomerCollection] = useState<boolean>(false);
	const [customerCollection, setCustomerCollection] = useState<ICollection[]>([]);

	const [
		{ totalCollection, pendingCollection, successfulCollection },
		setCalculatedCustomerCollections,
	] = useState(calculateInitialState);

	const calculateCustomerCollections = (collections: ICollection[]) => {
		let totalCollection = collections.reduce(function (prev, cur) {
			return prev + cur.amount_payable_fiat;
		}, 0);
		let pendingCollection = collections
			.filter((collection) => collection.status === 'pending')
			.reduce(function (prev, cur) {
				return prev + cur.amount_payable_fiat;
			}, 0);
		let successfulCollection = collections
			.filter((collection) => collection.status === 'success')
			.reduce(function (prev, cur) {
				return prev + cur.amount_payable_fiat;
			}, 0);
		setCalculatedCustomerCollections({
			totalCollection,
			pendingCollection,
			successfulCollection,
		});
	};

	const dispatchFindCustomer = async () => {
		try {
			dispatch(customerLoadingAction(true));
			dispatch(await findCustomerAction(activeTableId));
			dispatch(customerLoadingAction(false));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Customer Fatal error');
			dispatch(customerLoadingAction(false));
		}
	};

	const FindCustomerCollections = async () => {
		try {
			setLoadingCustomerCollection(true);
			const collections = await CollectionService.listCollections({
				customer_id: activeTableId,
				limit: 'null',
			});
			setCustomerCollection(collections.data.data.slice(0, 10));
			calculateCustomerCollections(collections.data.data);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Customer Fatal error');
			setLoadingCustomerCollection(false);
		}
	};

	useEffect(() => {
		if (showModal) {
			dispatchFindCustomer();
			FindCustomerCollections();
		}
	}, [showModal]);

	const lastKeyEntry = customer && Object.keys(customer).pop();

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Customer Details</Modal.Title>
					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						{/* begin::Add user */}
						{/* <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
							<FormInput
								labelName="Blacklist this Customer: &nbsp;&nbsp;"
								labelClass="form-check-label mr-3"
								className="form-check-input"
								type="checkbox"
								value=""
								name="notifications"
								defaultChecked={false}
							/>
						</div> */}

						<button
							className="btn btn-icon btn-sm btn-active-icon-primary"
							onClick={handleSetModal}
						>
							<RenderSvg
								path="/media/icons/arrows/arr061.svg"
								className="svg-icon-1"
							/>
						</button>
					</div>
				</Modal.Header>

				<Modal.Body>
					<>
						<div className="d-flex flex-column scroll-y me-n7 pe-7">
							<div className="fv-row mb-7 d-flex justify-content-center">
								<div className="d-flex flex-wrap flex-stack ">
									<div className="d-flex flex-column flex-grow-1 pe-8">
										<div className="d-flex flex-wrap">
											<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 ">
												<div className="d-flex align-items-center">
													<RenderSvg
														path="/media/icons/arrows/arr001.svg"
														className="svg-icon-3 svg-icon-primary me-2"
													/>
													<div className="fs-2 fw-bolder">
														{moneyFormat(totalCollection)}
													</div>
												</div>

												<div className="fw-bold fs-6 text-gray-400">
													Total transaction
												</div>
											</div>

											<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
												<div className="d-flex align-items-center">
													<RenderSvg
														path="/media/icons/arrows/arr066.svg"
														className="svg-icon-3 svg-icon-success me-2"
													/>
													<div className="fs-2 fw-bolder">
														{moneyFormat(successfulCollection)}
													</div>
												</div>

												<div className="fw-bold fs-6 text-gray-400">
													Total Successful
												</div>
											</div>

											<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
												<div className="d-flex align-items-center">
													<RenderSvg
														path="/media/icons/arrows/arr010.svg"
														className="svg-icon-3 svg-icon-warning me-2"
													/>
													<div className="fs-2 fw-bolder">
														{moneyFormat(pendingCollection)}
													</div>
												</div>

												<div className="fw-bold fs-6 text-gray-400">
													Total Pending
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="d-flex overflow-auto h-55px fv-row mb-7  justify-content-center">
								<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
									<li className="nav-item">
										<button
											style={{ backgroundColor: 'transparent' }}
											onClick={() => setIsDetail(true)}
											className={
												`nav-link text-active-primary me-6 ` +
												(isDetail && 'active')
											}
										>
											User Details
										</button>
									</li>
									<li className="nav-item">
										<button
											style={{ backgroundColor: 'transparent' }}
											onClick={() => setIsDetail(false)}
											className={
												`nav-link text-active-primary me-6 ` +
												(!isDetail && 'active')
											}
										>
											Collections
										</button>
									</li>
								</ul>
							</div>

							<div className={`row gy-1 	${!isDetail && 'd-none'}`}>
								{/* begin::Input group */}
								{customer &&
									Object.entries(customer).map(([key, value], indexData) => {
										if (key === 'id') {
											return null;
										}
										return (
											<div className="col-md-6" key={indexData}>
												<div className="d-flex fv-row">
													<div className="form-check form-check-custom form-check-solid">
														<label
															className="form-check-label"
															htmlFor="kt_modal_update_role_option_0"
														>
															<div className="fw-bolder text-gray-800">
																{titleCase(key)}
															</div>
															<div className="text-gray-600">
																{value}
															</div>
														</label>
													</div>
												</div>

												{lastKeyEntry === key ? (
													<></>
												) : (
													<div className="separator separator-dashed my-5"></div>
												)}
											</div>
										);
									})}
							</div>

							<div className={`row gy-1 ${isDetail && 'd-none'}`}>
								<SimpleTable2
									tableData={formatCollections(customerCollection)}
									enableButton={false}
									columns={[
										'reference',
										'customer',
										'amount',
										'status',
										'created_at',
									]}
									loading={loadingCustomerCollection}
									options={{
										enableSearch: false,
										enableFilter: false,
										enableExport: false,
									}}
								></SimpleTable2>
							</div>
						</div>
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { ViewCustomerModal };
