const FormSelect = ({ ...selectProps }) => {
	const { labelClass, labelName, children } = selectProps;

	//delete lable properties
	if (selectProps?.labelName) delete selectProps.labelName;
	if (selectProps?.labelClass) delete selectProps.labelClass;

	return (
		<>
			{labelName && <label className={labelClass}>{labelName}</label>}

			<select {...selectProps}>{children}</select>
		</>
	);
};

export default FormSelect;
