import React, { useContext } from 'react';
import { FormInput, FormSelect } from '../../components';
import { hasOwnProperty, titleCase, toAbsoluteUrl } from '../../helpers/utils';
import { BusinessDomainEnum, UpdateBusinessType } from '../../types/IBusiness';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { phoneRegExp } from '../auth/register/Register';
import { updateBusinessAction } from '../../store/Business/business.actions';
import {
	BusinessDispatchContext,
	BusinessStateContext,
} from '../../store/Business/business.provider';
import { ActionType } from '../../types/IAuth';
import { LayoutContext } from '../../layouts';

const businessSchema = Yup.object().shape({
	business_name: Yup.string().required('Business name is required'),
	business_category: Yup.string().required('Business category is required'),
	contact_phone: Yup.string()
		.matches(phoneRegExp, 'Business Phone number is not valid')
		.required('Phone number is required'),
	business_size: Yup.string().required(),
	contact_email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),

	default_currency: Yup.string().required(),
	// website_url: Yup.string().url('Wrong website url format'),
});

export let initialValues = {
	domain: BusinessDomainEnum.TEST,
	support_code: '',
	business_name: '',
	business_category: '',
	business_type: '',
	business_size: '',
	business_description: '',
	logo_url: '',
	bvn: '',
	rc: '',
	contact_phone: '',
	contact_email: '',
	support_email: '',
	chargeback_email: '',
	country: '',
	state: '',
	address: '',
	timezone: '',
	website_url: '',
	social_url: '',
	callback_url: '',
	cancel_url: '',
	default_currency: '',
	test_webhook_url: '',
	live_webhook_url: '',
	accepted_tokens: [],
	customer_bears_fee: 0,
	supported_currencies: '',
	settlement_method: '',
	settlement_currency: '',
	settlement_address: '',
	settlement_account_no: '',
	settlement_mode: '',
	settlement_swift_code: '',
	send_receipt_to_recipient: 0,
	send_receipt_to_sender: 0,
	onboarding_status: '',
	risk_level: '',
	rating: null,
};

export const modifiedValues = (initial_values: {}, values: {}) => {
	let modified_values = {};
	for (let key in values) {
		if (
			hasOwnProperty(values, key) &&
			hasOwnProperty(initial_values, key) &&
			values[key] !== null
		) {
			if (initial_values[key] !== values[key]) {
				modified_values = { ...modified_values, [key]: values[key] };
			}
		}
	}
	return modified_values;
};

const Business = () => {
	const { activeBusiness } = useContext(BusinessStateContext);

	if (activeBusiness) {
		Object.keys(activeBusiness).forEach((string) => {
			if (
				hasOwnProperty(initialValues, string) &&
				hasOwnProperty(activeBusiness, string) &&
				activeBusiness[string] !== null
			) {
				initialValues[string] = activeBusiness[string];
			}
		});
	}

	const { setError, setSuccess } = useContext(LayoutContext);

	const dispatch = useContext(BusinessDispatchContext) as React.Dispatch<ActionType>;

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		validationSchema: businessSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const hasChanged = modifiedValues(initialValues, values);
				if (Object.keys(hasChanged).length > 0) {
					dispatch(await updateBusinessAction(hasChanged as UpdateBusinessType));
					setSuccess('Business updated successfully');
					setSubmitting(false);
					return;
				}
				setError('No changes were made');
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	return (
		<div className="card mb-5 mb-xl-10">
			<div
				className="card-header border-0 cursor-pointer"
				role="button"
				data-bs-toggle="collapse"
				data-bs-target="#kt_account_profile_details"
				aria-expanded="true"
				aria-controls="kt_account_profile_details"
			>
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">Profile Details</h3>
				</div>
			</div>

			<div id="kt_account_profile_details" className="collapse show">
				<form className="form" onSubmit={formik.handleSubmit}>
					<div className="card-body border-top p-9">
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">Logo</label>

							<div className="col-lg-8">
								<div
									className="image-input image-input-outline"
									data-kt-image-input="true"
									style={{
										backgroundImage: `url(${toAbsoluteUrl(
											'/media/svg/avatars/blank.svg'
										)})`,
									}}
								>
									<div
										className="image-input-wrapper d-flex align-items-center flex-grow-1 flex-lg-grow-1"
									>
										<img alt="Logo" src={`${toAbsoluteUrl('/media/logos/usecoins.png')}`} className='h-100px' ></img>
									</div>

									<label
										className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
										data-kt-image-input-action="change"
										title="Change avatar"
									>
										<i className="bi bi-pencil-fill fs-7"></i>
										<input
											type="file"
											onChange={(event) => {
												formik.setFieldValue(
													'logo_url',
													event.currentTarget.files
												);
											}}
											accept=".png, .jpg, .jpeg"
										/>
										<input type="hidden" name="avatar_remove" />
									</label>

									<span
										className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
										data-kt-image-input-action="cancel"
										title="Cancel avatar"
									>
										<i className="bi bi-x fs-2"></i>
									</span>

									<span
										className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
										data-kt-image-input-action="remove"
										title="Remove avatar"
									>
										<i className="bi bi-x fs-2"></i>
									</span>
								</div>
								<div className="form-text">Allowed file types: png, jpg, jpeg.</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Name
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('business_name')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Email
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="email"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('contact_email')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Number
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('contact_phone')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Description
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<textarea
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('business_description')}
										></textarea>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Sector
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormSelect
											className="form-control form-control-lg form-control-solid"
											placeholder="Please describe your business"
											{...formik.getFieldProps('business_category')}
										>
											<option
												value={
													formik.values.business_category
														? formik.values.business_category
														: ''
												}
											>
												{formik.values.business_category
													? formik.values.business_category
													: 'Select Category'}
											</option>
											<option value="Agriculture sector">
												Agriculture sector
											</option>
											<option value="Telecommunication">
												Telecommunication
											</option>
										</FormSelect>
									</div>
								</div>
							</div>
						</div>

						{/* <div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Specify Team Size
							</label>

							<div className="col-lg-8">
								<div className="row mb-2" data-kt-buttons="true">
									<div className="col">
										<FormInput
											type="radio"
											className="btn-check"
											name="accountTeamSize"
											value="1-5"
											id="kt_account_team_size_select_1"
										/>
										<label
											className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
											htmlFor="kt_account_team_size_select_1"
										>
											<span className="fw-bolder fs-3">1-1</span>
										</label>
									</div>

									<div className="col">
										<FormInput
											type="radio"
											className="btn-check"
											name="accountTeamSize"
											value="5-10"
											id="kt_account_team_size_select_2"
										/>
										<label
											className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
											htmlFor="kt_account_team_size_select_2"
										>
											<span className="fw-bolder fs-3">2-10</span>
										</label>
									</div>

									<div className="col">
										<FormInput
											type="radio"
											className="btn-check"
											name="accountTeamSize"
											value="10-50"
											id="kt_account_team_size_select_3"
										/>
										<label
											className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
											htmlFor="kt_account_team_size_select_3"
										>
											<span className="fw-bolder fs-3">10-50</span>
										</label>
									</div>

									<div className="col">
										<FormInput
											type="radio"
											className="btn-check"
											name="accountTeamSize"
											value="50+"
											id="kt_account_team_size_select_4"
										/>
										<label
											className="btn btn-outline btn-outline-dashed btn-outline-default w-100 p-4"
											htmlFor="kt_account_team_size_select_4"
										>
											<span className="fw-bolder fs-3">50+</span>
										</label>
									</div>
								</div>
							</div>
						</div> */}

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Website Url
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('website_url')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Business Address
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('address')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Preferred Currency
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormSelect
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('default_currency')}
										>
											<option
												value={
													formik.values.default_currency
														? formik.values.default_currency
														: ''
												}
											>
												{formik.values.default_currency
													? formik.values.default_currency
													: 'Select Currency'}
											</option>
											<option value="usd">USD</option>
											<option value="ngn">NGN</option>
										</FormSelect>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Country
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormSelect
											className="form-control form-control-lg form-control-solid"
											placeholder="Please describe your business"
											{...formik.getFieldProps('country')}
										>
											<option
												value={
													formik.values.country
														? formik.values.country
														: ''
												}
											>
												{formik.values.country
													? formik.values.country
													: 'Select a Country...'}
											</option>
											<option value="AF">Afghanistan</option>
											<option value="AX">Aland Islands</option>
											<option value="AL">Albania</option>
											<option value="DZ">Algeria</option>
											<option value="AS">American Samoa</option>
											<option value="AD">Andorra</option>
											<option value="AO">Angola</option>
											<option value="AI">Anguilla</option>
											<option value="AQ">Antarctica</option>
											<option value="AG">Antigua and Barbuda</option>
											<option value="AR">Argentina</option>
											<option value="AM">Armenia</option>
											<option value="AW">Aruba</option>
											<option value="AU">Australia</option>
											<option value="AT">Austria</option>
											<option value="AZ">Azerbaijan</option>
											<option value="BS">Bahamas</option>
											<option value="BH">Bahrain</option>
											<option value="BD">Bangladesh</option>
											<option value="BB">Barbados</option>
											<option value="BY">Belarus</option>
											<option value="BE">Belgium</option>
											<option value="BZ">Belize</option>
											<option value="BJ">Benin</option>
											<option value="BM">Bermuda</option>
											<option value="BT">Bhutan</option>
											<option value="BO">
												Bolivia, Plurinational State of
											</option>
											<option value="BQ">
												Bonaire, Sint Eustatius and Saba
											</option>
											<option value="BA">Bosnia and Herzegovina</option>
											<option value="BW">Botswana</option>
											<option value="BV">Bouvet Island</option>
											<option value="BR">Brazil</option>
											<option value="IO">
												British Indian Ocean Territory
											</option>
											<option value="BN">Brunei Darussalam</option>
											<option value="BG">Bulgaria</option>
											<option value="BF">Burkina Faso</option>
											<option value="BI">Burundi</option>
											<option value="KH">Cambodia</option>
											<option value="CM">Cameroon</option>
											<option value="CA">Canada</option>
											<option value="CV">Cape Verde</option>
											<option value="KY">Cayman Islands</option>
											<option value="CF">Central African Republic</option>
											<option value="TD">Chad</option>
											<option value="CL">Chile</option>
											<option value="CN">China</option>
											<option value="CX">Christmas Island</option>
											<option value="CC">Cocos (Keeling) Islands</option>
											<option value="CO">Colombia</option>
											<option value="KM">Comoros</option>
											<option value="CG">Congo</option>
											<option value="CD">
												Congo, the Democratic Republic of the
											</option>
											<option value="CK">Cook Islands</option>
											<option value="CR">Costa Rica</option>
											<option value="CI">Côte d'Ivoire</option>
											<option value="HR">Croatia</option>
											<option value="CU">Cuba</option>
											<option value="CW">Curaçao</option>
											<option value="CY">Cyprus</option>
											<option value="CZ">Czech Republic</option>
											<option value="DK">Denmark</option>
											<option value="DJ">Djibouti</option>
											<option value="DM">Dominica</option>
											<option value="DO">Dominican Republic</option>
											<option value="EC">Ecuador</option>
											<option value="EG">Egypt</option>
											<option value="SV">El Salvador</option>
											<option value="GQ">Equatorial Guinea</option>
											<option value="ER">Eritrea</option>
											<option value="EE">Estonia</option>
											<option value="ET">Ethiopia</option>
											<option value="FK">Falkland Islands (Malvinas)</option>
											<option value="FO">Faroe Islands</option>
											<option value="FJ">Fiji</option>
											<option value="FI">Finland</option>
											<option value="FR">France</option>
											<option value="GF">French Guiana</option>
											<option value="PF">French Polynesia</option>
											<option value="TF">French Southern Territories</option>
											<option value="GA">Gabon</option>
											<option value="GM">Gambia</option>
											<option value="GE">Georgia</option>
											<option value="DE">Germany</option>
											<option value="GH">Ghana</option>
											<option value="GI">Gibraltar</option>
											<option value="GR">Greece</option>
											<option value="GL">Greenland</option>
											<option value="GD">Grenada</option>
											<option value="GP">Guadeloupe</option>
											<option value="GU">Guam</option>
											<option value="GT">Guatemala</option>
											<option value="GG">Guernsey</option>
											<option value="GN">Guinea</option>
											<option value="GW">Guinea-Bissau</option>
											<option value="GY">Guyana</option>
											<option value="HT">Haiti</option>
											<option value="HM">
												Heard Island and McDonald Islands
											</option>
											<option value="VA">
												Holy See (Vatican City State)
											</option>
											<option value="HN">Honduras</option>
											<option value="HK">Hong Kong</option>
											<option value="HU">Hungary</option>
											<option value="IS">Iceland</option>
											<option value="IN">India</option>
											<option value="ID">Indonesia</option>
											<option value="IR">Iran, Islamic Republic of</option>
											<option value="IQ">Iraq</option>
											<option value="IE">Ireland</option>
											<option value="IM">Isle of Man</option>
											<option value="IL">Israel</option>
											<option value="IT">Italy</option>
											<option value="JM">Jamaica</option>
											<option value="JP">Japan</option>
											<option value="JE">Jersey</option>
											<option value="JO">Jordan</option>
											<option value="KZ">Kazakhstan</option>
											<option value="KE">Kenya</option>
											<option value="KI">Kiribati</option>
											<option value="KP">
												Korea, Democratic People's Republic of
											</option>
											<option value="KW">Kuwait</option>
											<option value="KG">Kyrgyzstan</option>
											<option value="LA">
												Lao People's Democratic Republic
											</option>
											<option value="LV">Latvia</option>
											<option value="LB">Lebanon</option>
											<option value="LS">Lesotho</option>
											<option value="LR">Liberia</option>
											<option value="LY">Libya</option>
											<option value="LI">Liechtenstein</option>
											<option value="LT">Lithuania</option>
											<option value="LU">Luxembourg</option>
											<option value="MO">Macao</option>
											<option value="MK">
												Macedonia, the former Yugoslav Republic of
											</option>
											<option value="MG">Madagascar</option>
											<option value="MW">Malawi</option>
											<option value="MY">Malaysia</option>
											<option value="MV">Maldives</option>
											<option value="ML">Mali</option>
											<option value="MT">Malta</option>
											<option value="MH">Marshall Islands</option>
											<option value="MQ">Martinique</option>
											<option value="MR">Mauritania</option>
											<option value="MU">Mauritius</option>
											<option value="YT">Mayotte</option>
											<option value="MX">Mexico</option>
											<option value="FM">
												Micronesia, Federated States of
											</option>
											<option value="MD">Moldova, Republic of</option>
											<option value="MC">Monaco</option>
											<option value="MN">Mongolia</option>
											<option value="ME">Montenegro</option>
											<option value="MS">Montserrat</option>
											<option value="MA">Morocco</option>
											<option value="MZ">Mozambique</option>
											<option value="MM">Myanmar</option>
											<option value="NA">Namibia</option>
											<option value="NR">Nauru</option>
											<option value="NP">Nepal</option>
											<option value="NL">Netherlands</option>
											<option value="NC">New Caledonia</option>
											<option value="NZ">New Zealand</option>
											<option value="NI">Nicaragua</option>
											<option value="NE">Niger</option>
											<option value="NG">Nigeria</option>
											<option value="NU">Niue</option>
											<option value="NF">Norfolk Island</option>
											<option value="MP">Northern Mariana Islands</option>
											<option value="NO">Norway</option>
											<option value="OM">Oman</option>
											<option value="PK">Pakistan</option>
											<option value="PW">Palau</option>
											<option value="PS">
												Palestinian Territory, Occupied
											</option>
											<option value="PA">Panama</option>
											<option value="PG">Papua New Guinea</option>
											<option value="PY">Paraguay</option>
											<option value="PE">Peru</option>
											<option value="PH">Philippines</option>
											<option value="PN">Pitcairn</option>
											<option value="PL">Poland</option>
											<option value="PT">Portugal</option>
											<option value="PR">Puerto Rico</option>
											<option value="QA">Qatar</option>
											<option value="RE">Réunion</option>
											<option value="RO">Romania</option>
											<option value="RU">Russian Federation</option>
											<option value="RW">Rwanda</option>
											<option value="BL">Saint Barthélemy</option>
											<option value="SH">
												Saint Helena, Ascension and Tristan da Cunha
											</option>
											<option value="KN">Saint Kitts and Nevis</option>
											<option value="LC">Saint Lucia</option>
											<option value="MF">Saint Martin (French part)</option>
											<option value="PM">Saint Pierre and Miquelon</option>
											<option value="VC">
												Saint Vincent and the Grenadines
											</option>
											<option value="WS">Samoa</option>
											<option value="SM">San Marino</option>
											<option value="ST">Sao Tome and Principe</option>
											<option value="SA">Saudi Arabia</option>
											<option value="SN">Senegal</option>
											<option value="RS">Serbia</option>
											<option value="SC">Seychelles</option>
											<option value="SL">Sierra Leone</option>
											<option value="SG">Singapore</option>
											<option value="SX">Sint Maarten (Dutch part)</option>
											<option value="SK">Slovakia</option>
											<option value="SI">Slovenia</option>
											<option value="SB">Solomon Islands</option>
											<option value="SO">Somalia</option>
											<option value="ZA">South Africa</option>
											<option value="GS">
												South Georgia and the South Sandwich Islands
											</option>
											<option value="KR">South Korea</option>
											<option value="SS">South Sudan</option>
											<option value="ES">Spain</option>
											<option value="LK">Sri Lanka</option>
											<option value="SD">Sudan</option>
											<option value="SR">Suriname</option>
											<option value="SJ">Svalbard and Jan Mayen</option>
											<option value="SZ">Swaziland</option>
											<option value="SE">Sweden</option>
											<option value="CH">Switzerland</option>
											<option value="SY">Syrian Arab Republic</option>
											<option value="TW">Taiwan, Province of China</option>
											<option value="TJ">Tajikistan</option>
											<option value="TZ">Tanzania, United Republic of</option>
											<option value="TH">Thailand</option>
											<option value="TL">Timor-Leste</option>
											<option value="TG">Togo</option>
											<option value="TK">Tokelau</option>
											<option value="TO">Tonga</option>
											<option value="TT">Trinidad and Tobago</option>
											<option value="TN">Tunisia</option>
											<option value="TR">Turkey</option>
											<option value="TM">Turkmenistan</option>
											<option value="TC">Turks and Caicos Islands</option>
											<option value="TV">Tuvalu</option>
											<option value="UG">Uganda</option>
											<option value="UA">Ukraine</option>
											<option value="AE">United Arab Emirates</option>
											<option value="GB">United Kingdom</option>
											<option value="US">United States</option>
											<option value="UY">Uruguay</option>
											<option value="UZ">Uzbekistan</option>
											<option value="VU">Vanuatu</option>
											<option value="VE">
												Venezuela, Bolivarian Republic of
											</option>
											<option value="VN">Vietnam</option>
											<option value="VI">Virgin Islands</option>
											<option value="WF">Wallis and Futuna</option>
											<option value="EH">Western Sahara</option>
											<option value="YE">Yemen</option>
											<option value="ZM">Zambia</option>
											<option value="ZW">Zimbabwe</option>
										</FormSelect>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* </div> */}

					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!formik.isSubmitting && (
								<span className="indicator-label">Submit</span>
							)}
							{formik.isSubmitting && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export { Business };
