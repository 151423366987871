import React, { FC, useEffect, useContext, useRef, useState } from 'react';

import { AuthDispatchContext, AuthStateContext } from '../store/Auth/auth.provider';
import { authActions, getAuthUserAction } from '../store/Auth/auth.actions';
import { LayoutSplashScreen } from './LayoutSplashScreen';

const AuthInit: FC<{ children: React.ReactNode }> = ({ children }) => {
	const { auth } = useContext(AuthStateContext);
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);

	const dispatch = useContext(AuthDispatchContext);

	useEffect(() => {
		const requestUser = async () => {
			try {
				if (!didRequest.current) {
					dispatch(await getAuthUserAction());
				}
			} catch (error) {
				if (!didRequest.current) {
					dispatch({ type: authActions.LOGOUT });
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => (didRequest.current = true);
		};

		if (auth) {
			requestUser();
		} else {
			dispatch({ type: authActions.LOGOUT });
			setShowSplashScreen(false);
		}
		// eslint-disable-next-line
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { AuthInit };
