import React from 'react';
import clsx from 'clsx';
import { KTSVG } from '../components';
import { toAbsoluteUrl } from '../helpers/utils';
import { useLayout } from './index';
import { DefaultTitle } from './header/DefaultTitle';
import { useLocation } from 'react-router-dom';

const Toolbar = () => {
	const { classes } = useLayout();
	const location = useLocation();

	return (
		<div className="toolbar" id="kt_toolbar">
			{/* begin::Container */}
			<div
				id="kt_toolbar_container"
				className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
			>
				<DefaultTitle />

				{/* begin::Actions */}
				<div className="d-flex align-items-center py-1">
					{/* begin::Wrapper */}
					<div className={`me-4 ${location.pathname === '/dashboard' && 'd-none'}`}>
						{/* begin::Menu */}
						{/* <a
							href="#"
							className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
							data-kt-menu-trigger="click"
							data-kt-menu-placement="bottom-end"
							data-kt-menu-flip="top-end"
						>
							<KTSVG
								path="/media/icons/general/gen031.svg"
								className="svg-icon-5 svg-icon-gray-500 me-1"
							/>
							Filter
						</a> */}

						{/* end::Menu */}
					</div>
					{/* end::Wrapper */}
				</div>
				{/* end::Actions */}
			</div>
			{/* end::Container */}
		</div>
	);
};

export { Toolbar };
