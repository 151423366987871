import { ActionType } from '../../types/IAuth';
import { IPageMeta, ISettlement } from '../../types/_model';
import { settlementActions } from './settlement.actions';

export type SettlementType = {
	settlements: ISettlement[];
	settlement?: ISettlement | null;
	meta?: IPageMeta;
	loading: boolean;
	error: boolean;
};

export const SETTLEMENT_INITIAL_STATE: SettlementType = {
	settlements: [],
	settlement: undefined,
	meta: undefined,
	loading: false,
	error: false,
};

export const settlementReducer = (state = SETTLEMENT_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case settlementActions.LISTSETTLEMENTS:
			return {
				...state,
				settlements: action.payload,
				meta: action.payload.meta,
				loading: false,
			};
		case settlementActions.FINDSETTLEMENT:
			return {
				...state,
				settlement: { ...state.settlement, ...action.payload },
				loading: false,
			};
		case settlementActions.SETTLEMENT_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case settlementActions.SETTLEMENT_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
