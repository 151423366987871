import React from 'react';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../helpers/utils';
type Props = {
	className?: string;
	path: string;
	svgClassName?: string;
};

const RenderSvg = ({ className = '', path, svgClassName = 'mh-50px' }: Props) => {
	return (
		<span className={`svg-icon ${className}`}>
			<SVG src={toAbsoluteUrl(path)} className={svgClassName} />
		</span>
	);
};

export default RenderSvg;
