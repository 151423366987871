import Button from '../button/Button';
import RenderSvg from '../renderSvg/RenderSvg';
import FormSelect from '../form-select/FormSelect';
import { MenuComponent } from '../ts';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { titleCase } from '../../helpers/utils';
import FormInput from '../form-input/FormInput';
import { IPageQuery, IRole } from '../../types/_model';
import { modifiedValues } from '../../views/settings/Business';

type Props = {
	isLoading: boolean;
	html: string;
	allRole?: IRole[];
	dispatchAction?: (pageQuery?: IPageQuery) => Promise<void>;
};

type FilterProps = {
	role?: string;
	status?: string;
	dateType?: string;
	startDateValue?: string;
	endDateValue?: string;
};

const initialFilterState: FilterProps = {
	role: undefined,
	status: undefined,
	dateType: undefined,
	startDateValue: undefined,
	endDateValue: undefined,
};

const Filter = ({ ...filterProps }: Props) => {
	const location = useLocation();
	const { isLoading, dispatchAction, allRole } = filterProps;

	const [{ role, status, dateType, startDateValue, endDateValue }, setFilterState] =
		useState(initialFilterState);

	const collectionStatus = [
		'pending',
		'awaiting-payment',
		'partial-payment',
		'paid',
		'abandoned',
		'canceled',
	];
	const businessUserStatus = ['active', 'inactive'];
	const customerStatus = ['active', 'disabled', 'flagged'];
	const paymentStatus = ['success', 'failed', 'pending', 'abandoned', 'canceled'];
	const settlementStatus = ['success', 'failed', 'pending', 'canceled'];

	const handleFilterChangeState = (e: any) => {
		const { name, value } = e.target;
		if (name === 'dateType')
			setFilterState((prevState) => ({
				...prevState,
				startDateValue: undefined,
				endDateValue: undefined,
			}));

		if (name === 'dateType' && value === 'today') {
			let date = new Date();
			let todayDate = date.toISOString().split('T')[0];
			setFilterState((prevState) => ({ ...prevState, startDateValue: todayDate }));
		}

		setFilterState((prevState) => ({ ...prevState, [name]: value }));
	};

	const clearFilterState = () => {
		setFilterState({ ...initialFilterState });
	};

	const buildDateValue = (startDate: string, endDate?: string) => {
		const startEndDateFormat = [];
		if (!endDate) {
			let day = new Date(startDate);

			let nextDay = new Date(day);
			nextDay.setDate(day.getDate() + 1);
			let formated = nextDay.toISOString().split('T')[0]; // 2022-01-01

			startEndDateFormat.push(startDate, formated);
			return JSON.stringify(startEndDateFormat);
		}
		startEndDateFormat.push(startDate, endDate);
		return JSON.stringify(startEndDateFormat);
	};

	const buildStatusOptions = () => {
		switch (location.pathname) {
			case `/${ROUTES.CUSTOMERS}`:
				return customerStatus.map((item) => (
					<option key={item} value={item}>
						{titleCase(item)}
					</option>
				));

			case `/${ROUTES.SETTLEMENT}`:
				return settlementStatus.map((item) => (
					<option key={item} value={item}>
						{titleCase(item)}
					</option>
				));

			case `/${ROUTES.COLLECTIONS}`:
				return collectionStatus.map((item) => (
					<option key={item} value={item}>
						{titleCase(item)}
					</option>
				));

			case `/settings/${ROUTES.TEAM}`:
				return businessUserStatus.map((item) => (
					<option key={item} value={item}>
						{titleCase(item)}
					</option>
				));

			default:
				break;
		}
	};

	const resetData = () => {
		clearFilterState();
		dispatchAction?.();
		setFilterState({ dateType: '' });
	};

	const filterData = () => {
		let allFilters = {};
		const modifiedStates: Record<string, any> = modifiedValues(initialFilterState, {
			role,
			status,
			dateType,
			startDateValue,
			endDateValue,
		});

		Object.entries(modifiedStates).map(([key, value]: any) => {
			if (key === 'dateType') return;

			if (key === 'startDateValue' || key === 'endDateValue') {
				let date = buildDateValue(startDateValue!, endDateValue);
				allFilters = { ...allFilters, created_at: date };
				return;
			}
			allFilters = { ...allFilters, [key]: value };
		});
		dispatchAction?.(allFilters);
	};

	useEffect(() => {
		MenuComponent.reinitialization();
	}, []);

	return (
		<>
			{/* begin::Filter Button */}
			<Button
				disabled={isLoading}
				type="button"
				className="btn btn-light-primary me-3"
				data-kt-menu-trigger="click"
				data-kt-menu-placement="bottom-end"
			>
				<RenderSvg path="/media/icons/filter.svg" className="svg-icon-2" />
				Filter
			</Button>
			{/* end::Filter Button */}

			<div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
				<div className="px-7 py-5">
					<div className="fs-5 text-dark fw-bolder">Filter Options</div>
				</div>

				<div className="separator border-gray-200"></div>

				{/* begin::Content */}

				<div className="px-7 py-5" data-kt-user-table-filter="form">
					{/* begin::Input group */}
					<div className="mb-10">
						<label className="form-label fs-6 fw-bold">Status:</label>
						<FormSelect
							className="form-select form-select-solid fw-bolder"
							data-kt-select2="true"
							name="status"
							onChange={handleFilterChangeState}
						>
							<option value="">All</option>
							{buildStatusOptions()}
						</FormSelect>
					</div>
					{/* end::Input group */}

					{/* begin::Input group */}
					{location.pathname === `/settings/${ROUTES.TEAM}` && (
						<div className="mb-10">
							<label className="form-label fs-6 fw-bold">Role</label>
							<FormSelect
								className="form-select form-select-solid fw-bolder"
								data-kt-select2="true"
								data-placeholder="Select option"
								data-allow-clear="true"
								data-kt-user-table-filter="role"
								name="role"
								onChange={handleFilterChangeState}
								value={role}
							>
								<option value="">All</option>
								{allRole?.map((item) => (
									<option key={item.uuid} value={item.slug}>
										{item.title}
									</option>
								))}
							</FormSelect>
						</div>
					)}
					{/* end::Input group */}

					{/* begin::Input group */}
					<div className="mb-5">
						<label className="form-label fs-6 fw-bold">Date Period:</label>
						<FormSelect
							className="form-select form-select-solid fw-bolder"
							data-kt-select2="true"
							data-placeholder="Select option"
							onChange={handleFilterChangeState}
							name="dateType"
							value={dateType}
						>
							<option value="">All Time</option>
							<option value="today">Today</option>
							<option value="specific">Specific Day</option>
							<option value="custom">Custom Period</option>
						</FormSelect>
					</div>

					{/* end::Input group */}

					{/* Specific Day Date */}
					{dateType === 'specific' && (
						<div className="mb-10">
							<label className="form-label fs-6 fw-bold">Choose a day</label>
							<FormInput
								type="date"
								className="form-control form-control-solid"
								onChange={handleFilterChangeState}
								name="startDateValue"
							/>
						</div>
					)}

					{/* Custom Day Date */}
					{dateType === 'custom' && (
						<div className=" row mb-10">
							<div className="col-6">
								<label className="form-label fs-6 fw-bold">Start date</label>
								<FormInput
									type="date"
									className="form-control form-control-solid"
									onChange={handleFilterChangeState}
									name="startDateValue"
								/>
							</div>

							<div className="col-6">
								<label className="form-label fs-6 fw-bold">End date</label>
								<FormInput
									type="date"
									className="form-control form-control-solid"
									onChange={handleFilterChangeState}
									name="endDateValue"
								/>
							</div>
						</div>
					)}

					{/* begin::Actions */}
					<div className="d-flex justify-content-end">
						<Button
							type="button"
							disabled={isLoading}
							onClick={resetData}
							className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
							data-kt-menu-dismiss="true"
							data-kt-user-table-filter="reset"
						>
							Reset
						</Button>
						<Button
							disabled={isLoading}
							type="button"
							onClick={filterData}
							className="btn btn-primary fw-bold px-6"
							data-kt-menu-dismiss="true"
							data-kt-user-table-filter="filter"
						>
							Apply
						</Button>
					</div>
					{/* end::Actions */}
				</div>

				{/* end::Content */}
			</div>
			{/* end::SubMenu */}
		</>
	);
};

export default Filter;
