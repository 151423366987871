import { useContext, useEffect, useRef, useState } from 'react';
import { KTSVG, RenderSvg } from '../../components';
import { capitalizeFirstLetter, getStatusColor, moneyFormat, truncate } from '../../helpers/utils';

import { Button, Modal } from 'react-bootstrap';
import { TableContext } from '../../components/table/TableProvider';
import { ActionType } from '../../types/IAuth';
import {
	CollectionDispatchContext,
	CollectionStateContext,
} from '../../store/Collection/collection.provider';
import {
	collectionLoadingAction,
	findCollectionAction,
} from '../../store/Collection/collection.actions';
import SimpleTable from '../../components/table/SimpleTable2';
import { IPayment } from '../../types/_model';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const ViewCollectionModal = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);

	const dispatch = useContext(CollectionDispatchContext) as React.Dispatch<ActionType>;
	const { loading, collection } = useContext(CollectionStateContext);
	const [isDetail, setIsDetail] = useState<boolean>(true);

	const formatCollectionPayments = (payments: IPayment[] | []) => {
		if (payments && payments.length <= 0) return [];
		return payments?.map((payment: IPayment) => {
			return {
				id: truncate(payment.uuid, 8),
				reference: payment.reference,
				from: payment.from,
				to: payment.to,
				amount_currency: `${payment.amount} ${collection?.payment_currency}`,
				status: payment.status,
				created_at: payment.created_at,
			};
		});
	};
	const dispatchFindCollection = async () => {
		try {
			dispatch(collectionLoadingAction(true));
			dispatch(await findCollectionAction(activeTableId));
			dispatch(collectionLoadingAction(false));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Collection Fatal error');
		}
	};

	const collectionDownload = useRef<HTMLDivElement | null>(null);
	const handleDownloadCollection = async () => {
		const elementToDownload = collectionDownload.current;
		if (!elementToDownload) {
			return;
		}
		const canvas = await html2canvas(elementToDownload);
		const data = canvas.toDataURL('image/png');

		const pdf = new jsPDF();
		const imgProperties = pdf.getImageProperties(data);
		const pdfWidth = pdf.internal.pageSize.getWidth();
		const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

		pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
		pdf.save(collection?.reference);
	};

	useEffect(() => {
		if (showModal) {
			dispatchFindCollection();
		}
	}, [showModal]);

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Collection Details</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetModal}
					>
						<KTSVG path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="d-flex flex-column scroll-y me-n7 pe-7">
						<div className="d-flex overflow-auto h-55px fv-row mb-7  justify-content-center">
							<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
								<li className="nav-item">
									<button
										style={{ backgroundColor: 'transparent' }}
										onClick={() => setIsDetail(true)}
										className={
											`nav-link text-active-primary me-6 ` +
											(isDetail && 'active')
										}
									>
										Collection Details
									</button>
								</li>
								<li className="nav-item">
									<button
										style={{ backgroundColor: 'transparent' }}
										onClick={() => setIsDetail(false)}
										className={
											`nav-link text-active-primary me-6 ` +
											(!isDetail && 'active')
										}
									>
										Payments
									</button>
								</li>
							</ul>
						</div>

						<div
							className={`row gy-1 ${!isDetail && 'd-none'}`}
							ref={collectionDownload}
						>
							<div className="col-md-6">
								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Reference</div>
											<div className="text-gray-600">
												{collection?.reference}
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">
												Customer Email
											</div>
											<div className="text-gray-600">
												{collection?.customer.email}
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Amount</div>
											<div className="text-gray-600">
												<span>
													{moneyFormat(
														collection?.amount_payable_fiat || 0
													)}
												</span>
												<span className="text-muted fw-bold text-muted d-block fs-7">
													{collection?.amount_payable_coin
														? collection?.amount_payable_coin
														: '..'}
													{collection?.payment_currency
														? collection.payment_currency
														: '..'}
												</span>
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								{/* <div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">From</div>
											<div className="text-gray-600">{collection?.from}</div>
										</label>
									</div>
								</div> */}

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">
												Payment Currency
											</div>
											<div className="text-gray-600">
												{collection?.payment_currency}
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Currency</div>
											<div className="text-gray-600">
												{collection?.currency}
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>
							</div>

							<div className="col-md-6">
								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Status</div>
											<div className="text-gray-600">
												<span
													className={`badge badge-light-${getStatusColor(
														collection ? collection.status : ''
													)}`}
												>
													{capitalizeFirstLetter(
														collection ? collection.status : ''
													)}
												</span>
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Date</div>
											<div className="text-gray-600">
												{collection?.created_at}
											</div>
										</label>
									</div>
								</div>

								<div className="separator separator-dashed my-5"></div>

								<div className="d-flex fv-row">
									<div className="form-check form-check-custom form-check-solid">
										<label
											className="form-check-label"
											htmlFor="kt_modal_update_role_option_0"
										>
											<div className="fw-bolder text-gray-800">Address</div>
											<div className="text-gray-600">
												{collection?.payment_address}
											</div>
										</label>
									</div>
								</div>
							</div>
						</div>

						<div className={`row gy-1 ${isDetail && 'd-none'}`}>
							<SimpleTable
								tableData={
									collection ? formatCollectionPayments(collection.payments) : []
								}
								enableButton={false}
								columns={[
									'id',
									'reference',
									'from',
									'to',
									'amount',
									'status',
									'created_at',
								]}
								loading={loading}
							></SimpleTable>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={handleDownloadCollection}
						className={`${!isDetail && 'd-none'}`}
					>
						<RenderSvg path="/media/icons/download.svg" />
						Download
					</Button>

					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { ViewCollectionModal };
