import { useEffect } from 'react';
import RenderSvg from '../renderSvg/RenderSvg';
import FormInput from '../form-input/FormInput';
import { useState } from 'react';
import { IPageQuery } from '../../types/_model';
type SearchProps = {
	placeholder: string;
	dispatchAction?: ({ page, search }: IPageQuery) => Promise<void>;
};
const Search = ({ placeholder, dispatchAction }: SearchProps) => {
	const [searchTerm, setSearchTerm] = useState<string>('');

	useEffect(() => {
		const delaySearch = setTimeout(() => {
			dispatchAction?.({ search: searchTerm });
		}, 1000);
		return () => clearTimeout(delaySearch);
	}, [searchTerm]);

	return (
		<div className="card-title">
			{/* begin::Search */}
			<div className="d-flex align-items-center position-relative my-1">
				<RenderSvg
					path="/media/icons/search.svg"
					className="svg-icon-1 position-absolute ms-6"
				/>
				<FormInput
					type="text"
					data-kt-user-table-filter="search"
					className="form-control form-control-solid w-250px ps-14"
					placeholder={placeholder}
					onKeyUp={(e: any) => setSearchTerm(e.target.value)}
				/>
			</div>
			{/* end::Search */}
		</div>
	);
};

export default Search;
