export const REGISTER = 'v1/auth/register';
export const LOGIN = 'v1/auth/login';
export const FETCH_AUTH_USER = 'v1/auth';
export const UPDATE_USER = 'v1/user';
export const REQUEST_RESET = 'v1/auth/request-reset';
export const VERIFY_TOKEN = 'v1/auth/reset';
export const RESET_PASSWORD = 'v1/auth/reset';
export const UPDATE_PASSWORD = 'v1/auth/password';
export const BUSINESS = 'v1/business';
export const SWITCH_BUSINESS = 'v1/business/switch';

export const API_KEY = 'v1/settings/keys';
export const WEBHOOK = 'v1/settings/webhook';

export const ROLE = 'v1/rbac/roles';
export const PERMISSION = 'v1/rbac/permissions';

export const BUSINESS_USER = 'v1/business/invitations';

export const CUSTOMERS = 'v1/customers';
export const SETTLEMENT = 'v1/settlements';

export const COLLECTIONS = 'v1/business/orders';
export const FIND_COLLECTION = 'v1/orders';

export const WALLET = 'v1/wallets';

export const NETWORKS = 'v1/app/networks';
export const BANKS = 'v1/app/banks';

export const COIN_ADDRESS = 'v1/withdrawal/coin-address';
export const BANK_ACCOUNT = 'v1/withdrawal/bank-accounts';

export const DASHBOARD = 'v1/dashboard/chart';

export const INVOICES = 'v1/invoices';

export const AUTH_ENDPOINTS = {
	REGISTER,
	LOGIN,
	REQUEST_RESET,
	VERIFY_TOKEN,
	RESET_PASSWORD,
	UPDATE_PASSWORD,
	UPDATE_USER,
	FETCH_AUTH_USER,
};

export const CUSTOMERS_ENDPOINTS = {
	CUSTOMERS,
};

export const SETTLEMENT_ENDPOINTS = {
	SETTLEMENT,
};

export const COLLECTIONS_ENDPOINTS = {
	COLLECTIONS,
	FIND_COLLECTION,
};

export const BUSINESS_ENDPOINTS = {
	BUSINESS,
	SWITCH_BUSINESS,
};

export const WALLET_ENDPOINTS = {
	WALLET,
};

export const SETTINGS_ENDPOINTS = {
	API_KEY,
	WEBHOOK,
	ROLE,
	PERMISSION,
};

export const BUSINESS_USER_ENDPOINTS = {
	BUSINESS_USER,
};

export const WITHDRAWAL_ENDPOINTS = {
	COIN_ADDRESS,
	BANK_ACCOUNT,
};

export const APP_ENDPOINTS = {
	NETWORKS,
	BANKS,
};

export const DASHBOARD_ENDPOINTS = {
	DASHBOARD,
};

export const INVOICES_ENDPOINTS = {
	INVOICES,
};
