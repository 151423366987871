import { usecoinsApi } from '../../services/http';
import { BUSINESS_USER_ENDPOINTS } from '../../constants/endpoints';
import { CreateTeam, IAcceptInvite } from '../../types/_model';

export const inviteUser = async (payload: CreateTeam) => {
	try {
		const { data } = await usecoinsApi.post(BUSINESS_USER_ENDPOINTS.BUSINESS_USER, payload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const verifyInvite = async (inviteId: string) => {
	try {
		const url = process.env.REACT_APP_VERIFY_INVITE_API_URL;
		const { data } = await usecoinsApi.get(`${url}/${inviteId}`);
		return data;
	} catch (error) {
		throw error;
	}
};

export const acceptInviteGuest = async (payload: IAcceptInvite) => {
	try {
		const { data } = await usecoinsApi.post(
			`${BUSINESS_USER_ENDPOINTS.BUSINESS_USER}/guest`,
			payload
		);
		return data;
	} catch (error) {
		throw error;
	}
};
