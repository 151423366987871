import { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import FormInput from '../form-input/FormInput';
import FormSelect from '../form-select/FormSelect';
import RenderSvg from '../renderSvg/RenderSvg';
import Button from '../button/Button';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { LayoutContext } from '../../layouts';
import { titleCase } from '../../helpers/utils';
import { createInvoiceAction } from '../../store/Invoice/invoice.actions';
import { InvoiceDispatchContext, InvoiceStateContext } from '../../store/Invoice/invoice.provider';
import { listInvoicesAction, invoiceLoadingAction } from '../../store/Invoice/invoice.actions';
import { ActionType } from '../../types/IAuth';
import { IPageQuery } from '../../types/_model';
import { uuid4 } from '@sentry/utils';

type InvoiceModalProps = {
	show?: boolean;
	triggerModel?: any;
};

const initialValues = {
	reference: uuid4(),
	currency_id: 1,
	amount: '',
	discount: '',
	total: '',
	name: '',
	email: '',
	items: [
		{
			name: '',
			amount: '',
		},
	],
	tax: '',
	selectOption: '',
	description: '',
};

const customerSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 characters')
		.max(50, 'Maximum 50 characters')
		.required('Email is required'),
	name: Yup.string()
		.min(3, 'Minimum 3 characters')
		.max(50, 'Maximum 50 characters')
		.required('Customer name is required'),
	selectOption: Yup.string().required('Select an option'),
	amount: Yup.string().when('selectOption', {
		is: 'simple',
		then: Yup.string().required('Amount is required'),
	}),
	description: Yup.string().when('selectOption', {
		is: 'simple',
		then: Yup.string()
			.min(3, 'Minimum 3 characters')
			.max(100, 'Maximum 100 characters')
			.required('Description is required'),
	}),
});

const InvoiceModal = ({ show, triggerModel }: InvoiceModalProps) => {
	const [isFiat, setIsFiat] = useState<boolean>(true);
	const [customerEmail, setCustomerEmail] = useState<string>('');
	const dispatch = useContext(InvoiceDispatchContext) as React.Dispatch<ActionType>;

	const navigate = useNavigate();
	const { setError, setSuccess } = useContext(LayoutContext);

	const formik = useFormik({
		initialValues,
		validationSchema: customerSchema,
		onSubmit: async (values, { resetForm, setSubmitting }, pageQuery?: IPageQuery) => {
			console.log(values);
			setCustomerEmail(values?.email);
			if (values.selectOption.includes('simple')) {
				try {
					dispatch(
						await createInvoiceAction({
							reference: values?.reference,
							currency_id: values?.currency_id,
							amount: values?.amount,
							discount: values?.discount,
							total: values?.total || values?.amount,
							customer: {
								name: values?.name,
								email: values?.email,
							},
							items: [
								{
									name: '',
									amount: '',
								},
							],
							tax: '',
							description: values?.description,
						})
					);
					dispatch(invoiceLoadingAction(true));
					dispatch(await listInvoicesAction(pageQuery));
					setSuccess('Invoice created successfully');
					resetForm();
					setSubmitting(false);
					triggerModel(false);
				} catch (error: any) {
					dispatch(invoiceLoadingAction(false));
					setError(
						error.response.data.errors
							? `${error.response.data.errors[0].message} for ${titleCase(
									error.response.data.errors[0].field
							  )}`
							: 'Something went wrong'
					);
					setSubmitting(false);
					triggerModel(false);
				}
			} else if (values.selectOption.includes('pdf')) {
				resetForm();
				setSubmitting(false);
				triggerModel(false);
				setError('This Feature Is In Progress, Create Simple Invoice Instead');
				// try {
				// 	navigate(`/invoices/${values.reference}`, { state: values });
				// 	resetForm();
				// 	setSubmitting(false);
				// } catch (error: any) {
				// 	setError(
				// 		error.response.data.errors
				// 			? `${error.response.data.errors[0].message} for ${titleCase(
				// 					error.response.data.errors[0].field
				// 			  )}`
				// 			: 'Something went wrong'
				// 	);
				// 	setSubmitting(false);
				// }
			}
		},
	});

	return (
		<>
			{/* New transfer modal */}
			<Modal show={show} size="lg">
				<Modal.Header>
					<Modal.Title>Request Payment</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={() => {
							formik.resetForm();
							triggerModel(false);
						}}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							{/*Fiat Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework ${
									!isFiat && 'd-none'
								} `}
								noValidate
								onSubmit={formik.handleSubmit}
							>
								{/* <FormObserver /> */}
								{formik.status && (
									<div className="mb-lg-15 alert alert-danger">
										<div className="alert-text font-weight-bold">
											{formik.status}
										</div>
									</div>
								)}

								<div className="fv-row mb-7">
									<FormInput
										labelName="Customer"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="Customer@gmail.com"
										type="text"
										autoComplete="off"
										{...formik.getFieldProps('email')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched?.email && formik.errors?.email,
											},
											{
												'is-valid':
													formik.touched?.email && !formik.errors?.email,
											}
										)}
										// className="form-control form-control-lg form-control-solid"
									/>
									{formik.touched.email && formik.errors.email && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert" className="text-danger">
													{formik.errors?.email}
												</span>
											</div>
										</div>
									)}
								</div>

								<div>
									<div className="fv-row mb-7">
										<FormInput
											labelName="Name"
											labelClass="form-label fw-bolder text-dark fs-6"
											placeholder="Customer's Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('name')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid':
														formik.touched.name && formik.errors.name,
												},
												{
													'is-valid':
														formik.touched.name && !formik.errors.name,
												}
											)}
										/>
										{formik.touched.name && formik.errors.name && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert" className="text-danger">
														{formik.errors?.name}
													</span>
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="fv-row mb-7">
									<FormSelect
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.selectOption &&
													formik.errors.selectOption,
											},
											{
												'is-valid':
													formik.touched.selectOption &&
													!formik.errors.selectOption,
											}
										)}
										labelName="Attach PDF?"
										labelClass="form-label fw-bolder text-dark fs-6"
										{...formik.getFieldProps('selectOption')}
									>
										<option value="" disabled>
											Attach PDF?{' '}
										</option>
										<option value="simple">No, keep it simple</option>
										<option value="pdf">Yes, Create PDF</option>
									</FormSelect>
									{formik.touched.selectOption && formik.errors.selectOption && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert" className="text-danger">
													{formik.errors.selectOption}
												</span>
											</div>
										</div>
									)}
								</div>

								{formik.values.selectOption.includes('simple') && (
									<div>
										<div className="fv-row mb-7">
											<FormInput
												labelName="Amount"
												labelClass="form-label fw-bolder text-dark fs-6"
												placeholder="How much?"
												type="number"
												autoComplete="off"
												{...formik.getFieldProps('amount')}
												className={clsx(
													'form-control form-control-lg form-control-solid',
													{
														'is-invalid':
															formik.touched.amount &&
															formik.errors.amount,
													},
													{
														'is-valid':
															formik.touched.amount &&
															!formik.errors.amount,
													}
												)}
											/>
											{formik.touched.amount && formik.errors.amount && (
												<div className="fv-plugins-message-container">
													<div className="fv-help-block">
														<span role="alert" className="text-danger">
															{formik.errors.amount}
														</span>
													</div>
												</div>
											)}
										</div>
										<div className="fv-row mb-7">
											<FormInput
												labelName="Note"
												labelClass="form-label fw-bolder text-dark fs-6"
												placeholder="Tell your customer why you are requesting this payment"
												type="text"
												autoComplete="off"
												{...formik.getFieldProps('description')}
												className={clsx(
													'form-control form-control-lg form-control-solid',
													{
														'is-invalid':
															formik.touched.description &&
															formik.errors.description,
													},
													{
														'is-valid':
															formik.touched.description &&
															!formik.errors.description,
													}
												)}
											/>
											{formik.touched.description &&
												formik.errors.description && (
													<div className="fv-plugins-message-container">
														<div className="fv-help-block">
															<span
																role="alert"
																className="text-danger"
															>
																{formik.errors.description}
															</span>
														</div>
													</div>
												)}
										</div>
									</div>
								)}

								<Modal.Footer>
									{formik.values.selectOption.includes('simple') ? (
										<Button
											type="submit"
											className="btn btn-light-success me-3"
										>
											Send Now
										</Button>
									) : (
										<Button
											type="submit"
											className="btn btn-light-success me-3"
										>
											Add Item
										</Button>
									)}
								</Modal.Footer>
							</form>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default InvoiceModal;
