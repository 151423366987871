import React from 'react';
import ReactDOM from 'react-dom/client';
// import { LayoutProvider,  LayoutSplashScreen} from './layouts';

import './assets/sass/style.scss';
import './assets/sass/style.react.scss';

import reportWebVitals from './reportWebVitals';
import { PublicRoutes, PrivateRoutes } from './routes';
import { LayoutProvider } from './layouts';

import './assets/sass/style.scss';
import './assets/sass/style.react.scss';
import { AuthProvider } from './store/Auth/auth.provider';
import { AppRoutes } from './routes/AppRoutes';
import { AuthInit } from './layouts/AuthInit';
import { MasterInit } from './layouts/MasterInit';

const isAuth = false; // TODO: pending auth implementation
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<AuthProvider>
			<LayoutProvider>
				<AuthInit>
					<AppRoutes />
					<MasterInit />
				</AuthInit>
			</LayoutProvider>
		</AuthProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
