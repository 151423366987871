import axios from 'axios';
import { getAuth } from '../helpers/utils';
const auth = getAuth();

export const usecoinsApi = axios.create({
	baseURL: process.env.REACT_APP_USECOINS_API_URL,
	timeout: (process.env.REACT_APP_API_TIMEOUT as number | undefined) || 100000,
});

const authInterceptor = (config: any) => {
	const auth = getAuth();
	if (auth) config.headers['Authorization'] = `Bearer ${auth.token}`;
	config.headers['jwt-auth'] = true;
	config.headers['Access-Control-Allow-Origin'] = '*';
	config.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
	return config;
};

usecoinsApi.interceptors.request.use(authInterceptor);
