import { SimpleTable2 as SimpleTable, TableProvider } from '../../components';
import { PageLink, PageTitle } from '../../layouts';
import { AuditData } from '../../store/TableData';
import ViewAudit from './ViewAudit';

const auditBreadCrumbs: Array<PageLink> = [
	{
		title: 'Audit Management',
		path: '/audit-trails',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const Columns = ['Id', 'Time', 'Activity', 'Description'];

const Audit = () => {
	return (
		<TableProvider>
			<PageTitle breadcrumbs={auditBreadCrumbs}>Audit List</PageTitle>

			<SimpleTable
				tableData={AuditData}
				columns={Columns}
				enableButton={true}
				options={{
					enableSearch: true,
					enableExport: true,
					searchPlaceholder: 'Search for audit',
				}}
			/>

			<ViewAudit />
		</TableProvider>
	);
};

export default Audit;
