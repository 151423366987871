import React, { useContext } from 'react';
import { FormSelect } from '../../components';
import { hasOwnProperty, titleCase } from '../../helpers/utils';
import { initialValues, modifiedValues } from './Business';
import { updateBusinessAction } from '../../store/Business/business.actions';
import { useFormik } from 'formik';
import {
	BusinessDispatchContext,
	BusinessStateContext,
} from '../../store/Business/business.provider';
import { ActionType } from '../../types/IAuth';
import { UpdateBusinessType } from '../../types/IBusiness';
import { LayoutContext } from '../../layouts';
import { IWallet } from '../../types/_model';

type Props = {
	accepted_coins: IWallet[];
};
const Preferences: React.FC<Props> = ({ accepted_coins }: Props) => {
	const { activeBusiness } = useContext(BusinessStateContext);
	const { setError, setSuccess } = useContext(LayoutContext);

	if (activeBusiness) {
		Object.keys(activeBusiness).forEach((string) => {
			if (
				hasOwnProperty(initialValues, string) &&
				hasOwnProperty(activeBusiness, string) &&
				activeBusiness[string] !== null
			) {
				initialValues[string] = activeBusiness[string];
			}
		});
	}

	const dispatch = useContext(BusinessDispatchContext) as React.Dispatch<ActionType>;

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const hasChanged = modifiedValues(initialValues, values);
				if (Object.keys(hasChanged).length > 0) {
					// TODO: send_receipt_to_recipient as boolean
					dispatch(await updateBusinessAction(hasChanged as UpdateBusinessType));
					setSuccess('Business Preference updated successfully');
					setSubmitting(false);
					return;
				}
				setError('No changes were made');
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	return (
		<div className="card mb-5 mb-xl-10">
			<div className="card-header border-0 cursor-pointer" role="button">
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0"> Preferences</h3>
				</div>
			</div>

			<div>
				<form className="form" onSubmit={formik.handleSubmit}>
					<div className="card-body border-top px-9 py-9">
						<div className="row">
							<div className="col-lg-6 mb-3">
								<h3 className="fw-bold p-3">Accepted Coins:</h3>
								<div className="card-body hover-scroll h-200px border-top p-9">
									{accepted_coins.map((item, index) => {
										return (
											<div key={index}>
												<div className="d-flex flex-stack">
													<div className="d-flex">
														<img
															src={item.logo_url}
															className="w-30px me-6"
															alt=""
														/>

														<div className="d-flex flex-column justify-content-center">
															<span className="fs-5 text-dark  fw-bolder">
																{item.name}
															</span>
														</div>
													</div>
													<div className="d-flex justify-content-end">
														<div className="form-check form-check-solid form-switch">
															<input
																className="form-check-input w-45px h-30px bg-gray-400"
																type="checkbox"
																{...formik.getFieldProps(
																	'accepted_tokens'
																)}
																value={item.code}
																defaultChecked={activeBusiness?.accepted_tokens?.includes(
																	item.code
																)}
															/>
														</div>
													</div>
												</div>
												<div className="separator separator-dashed my-3"></div>
											</div>
										);
									})}
								</div>
							</div>

							<div className="col-lg-6 mb-3">
								<h3 className="fw-bold p-3">Settlement Type</h3>
								<div className="row mb-10">
									<div className="">
										<FormSelect
											className={
												'form-control form-control-lg form-control-solid'
											}
											{...formik.getFieldProps('settlement_method')}
										>
											<option
												defaultValue={activeBusiness?.settlement_method}
												// value={
												// 	formik.values.settlement_method
												// 		? formik.values.settlement_method
												// 		: ''
												// }
											>
												{activeBusiness?.settlement_method}
												{/* {formik.values.settlement_method
													? formik.values.settlement_method
													: 'Select Settlement Method'} */}
											</option>
											<option value="fiat">Fiat </option>
											<option value="coin">Coin</option>
										</FormSelect>
									</div>
								</div>

								<div className="row mb-10">
									<label className="col-form-label fs-6 fw-bolder">
										Settlement Currency:
									</label>
									<div className="">
										<FormSelect
											className={
												'form-control form-control-lg form-control-solid'
											}
											{...formik.getFieldProps('settlement_currency')}
										>
											<option value="usd">USD </option>
											<option value="ngn">NGN</option>
											<option value="btc">BTC</option>
											<option value="ltc">LTC</option>
										</FormSelect>
									</div>
								</div>
							</div>
						</div>

						<div className="separator separator-dashed my-6"></div>

						<div className="table-responsive">
							<table className="table table-row-dashed border-gray-300 align-middle gy-6">
								<tbody className="fs-6 fw-bold">
									<tr>
										<td className="min-w-250px fs-6 fw-bolder">
											Fee Collection to be paid by
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													id="merchant"
													{...formik.getFieldProps('customer_bears_fee')}
													defaultChecked={
														formik.values.customer_bears_fee === 0
													}
													// checked={formik.values.customer_bears_fee === 0}
													value={0}
												/>
												<label className="form-check-label ps-2">
													Merchant
												</label>
											</div>
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													id="client"
													{...formik.getFieldProps('customer_bears_fee')}
													value={1}
													// checked={formik.values.customer_bears_fee === 1}
													defaultChecked={
														formik.values.customer_bears_fee === 1
													}
												/>
												<label className="form-check-label ps-2">
													Client
												</label>
											</div>
										</td>
									</tr>

									<tr>
										<td className="min-w-250px fs-6 fw-bolder">
											Transaction Email
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="checkbox"
													{...formik.getFieldProps(
														'send_receipt_to_recipient'
													)}
													value={0}
													// defaultChecked={
													// formik.values.send_receipt_to_recipient?.includes('user')
													// ? true
													// : false
													// }
												/>
												<label className="form-check-label ps-2">
													Sent To User
												</label>
											</div>
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="checkbox"
													{...formik.getFieldProps(
														'send_receipt_to_recipient'
													)}
													value={'business'}
													// defaultChecked={
													// 	formik.values.send_receipt_to_recipient?.includes('business')
													// 		? true
													// 		: false
													// }
												/>
												<label className="form-check-label ps-2">
													Sent to Business
												</label>
											</div>
										</td>
									</tr>

									<tr>
										<td className="min-w-250px fs-6 fw-bolder">
											Settlement Mode
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													{...formik.getFieldProps('settlement_mode')}
													defaultChecked={
														formik.values.settlement_mode === 'manual'
													}
													value="manual"
												/>
												<label className="form-check-label ps-2">
													Manual
												</label>
											</div>
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													{...formik.getFieldProps('settlement_mode')}
													defaultChecked={
														formik.values.settlement_mode === 'auto'
													}
													value="auto"
												/>
												<label className="form-check-label ps-2">
													Automatic
												</label>
											</div>
										</td>
									</tr>

									<tr>
										<td className="min-w-250px fs-6 fw-bolder">
											Pagination Type
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													value=""
													name="pagination"
													defaultChecked={true}
												/>
												<label className="form-check-label ps-2">
													Cursor
												</label>
											</div>
										</td>
										<td className="w-175px">
											<div className="form-check form-check-solid">
												<input
													className="form-check-input"
													type="radio"
													name="pagination"
												/>
												<label className="form-check-label ps-2">
													Offset
												</label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!formik.isSubmitting && (
								<span className="indicator-label">Submit</span>
							)}
							{formik.isSubmitting && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Preferences;
