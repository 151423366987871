/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Alert from '../../components/Alert';
import { toAbsoluteUrl } from '../../helpers/utils';
import { AuthStateContext } from '../../store/Auth/auth.provider';

const AuthLayout: React.FC = () => {
	useEffect(() => {
		document.body.classList.add('bg-white');
		return () => {
			document.body.classList.remove('bg-white');
		};
	}, []);

	const { global_error, global_success } = useContext(AuthStateContext);

	return (
		<div className="d-flex flex-column flex-lg-row flex-column-fluid">
			{/* begin::Aside */}
			<div
				className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative"
				style={{ backgroundColor: '#F2C98A' }}
			>
				{/* begin::Wrapper-*/}
				<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
					{/* begin::Content-*/}
					<div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20">
						{/* begin::Logo-*/}
						<Link to="#" className="py-9 mb-5">
							<img
								alt="Logo"
								src={toAbsoluteUrl('/media/logos/usecoins.png')}
								className="h-80px"
							/>
						</Link>
						{/* end::Logo-*/}
						{/* begin::Title-*/}
						<h1 className="fw-bolder fs-2qx pb-5 pb-md-10" style={{ color: '#986923' }}>
							Welcome to UseCoins
						</h1>
						{/* end::Title-*/}
						{/* begin::Description-*/}
						<p className="fw-bold fs-2" style={{ color: '#986923' }}>
							Global payment rail for the future economy.
							<br />
							Signup to start accepting payments.
							<br />
							<span className="fw-bolder">OR</span>
							<br />
							Login to your dashboard to manage collections
						</p>
						{/* end::Description-*/}
					</div>
					{/* end::Content-*/}
					{/* begin::Illustration-*/}
					<div
						className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
						style={{
							backgroundImage: `url(${toAbsoluteUrl(
								'/media/illustrations/sketchy-1/13.png'
							)})`,
						}}
					></div>
					{/* end::Illustration-*/}
				</div>
				{/* end::Wrapper-*/}
			</div>
			{/* end::Aside-*/}
			{/* begin::Body-*/}
			<div className="d-flex flex-column flex-lg-row-fluid py-10">
				{/* begin::Content-*/}
				{global_error && <Alert title={global_error} type="danger" />}
				{global_success && <Alert title={global_success} type="success" />}
				<div className="d-flex flex-center flex-column flex-column-fluid">
					{/* begin::Wrapper-*/}

					<Outlet />

					{/* end::Wrapper-*/}
				</div>
				{/* end::Content-*/}
				{/* begin::Footer-*/}
				<div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
					{/* begin::Links-*/}
					<div className="d-flex flex-center fw-bold fs-6"></div>
					{/* end::Links-*/}
				</div>
				{/* end::Footer-*/}
			</div>
			{/* end::Body-*/}
		</div>
	);
};

export default AuthLayout;
