import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { fetchAllBusinessAction, fetchBusinessAction } from '../store/Business/business.actions';
import { ActionType } from '../types/IAuth';
import { BusinessDispatchContext } from '../store/Business/business.provider';

export interface PageLink {
	title: string;
	path: string;
	isActive: boolean;
	isSeparator?: boolean;
}

export interface PageDataContextModel {
	pageTitle?: string;
	setPageTitle: (_title: string) => void;
	pageDescription?: string;
	setPageDescription: (_description: string) => void;
	pageBreadcrumbs?: Array<PageLink>;
	setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
	setPageTitle: (_title: string) => {},
	setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
	setPageDescription: (_description: string) => {},
});

const PageDataProvider: React.FC<Props> = ({ children }) => {
	const [pageTitle, setPageTitle] = useState<string>('');
	const [pageDescription, setPageDescription] = useState<string>('');
	const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
	const value: PageDataContextModel = {
		pageTitle,
		setPageTitle,
		pageDescription,
		setPageDescription,
		pageBreadcrumbs,
		setPageBreadcrumbs,
	};

	const dispatch = useContext(BusinessDispatchContext) as React.Dispatch<ActionType>;

	const dispachBusinesss = async () => {
		try {
			dispatch(await fetchAllBusinessAction());
			dispatch(await fetchBusinessAction());
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Business Fatal error');
		}
	};

	useEffect(() => {
		dispachBusinesss();
	}, []);

	return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
	return useContext(PageDataContext);
}

type Props = {
	children: React.ReactNode;
	description?: string;
	breadcrumbs?: Array<PageLink>;
};

const PageTitle: FC<Props> = ({ children, description, breadcrumbs }) => {
	const { setPageTitle, setPageDescription, setPageBreadcrumbs } = usePageData();
	useEffect(() => {
		if (children) {
			setPageTitle(children.toString());
		}
		return () => {
			setPageTitle('');
		};
	}, [children]);

	useEffect(() => {
		if (description) {
			setPageDescription(description);
		}
		return () => {
			setPageDescription('');
		};
	}, [description]);

	useEffect(() => {
		if (breadcrumbs) {
			setPageBreadcrumbs(breadcrumbs);
		}
		return () => {
			setPageBreadcrumbs([]);
		};
	}, [breadcrumbs]);

	return <></>;
};

const PageDescription: React.FC<Props> = ({ children }) => {
	const { setPageDescription } = usePageData();
	useEffect(() => {
		if (children) {
			setPageDescription(children.toString());
		}
		return () => {
			setPageDescription('');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [children]);
	return <></>;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
