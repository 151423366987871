/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import {useIntl} from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import * as ROUTES from '../../constants/routes';

export function AsideMenuMain() {
	// const intl = useIntl()

	return (
		<>
			<AsideMenuItem
				to="/dashboard"
				icon="/media/icons/art/art002.svg"
				title={'Dashboard'}
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to={ROUTES.COLLECTIONS}
				icon="/media/icons/art/art002.svg"
				title={'Collections'}
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to={ROUTES.CUSTOMERS}
				icon="/media/icons/account.svg"
				title={'Customers'}
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to={ROUTES.SETTLEMENT}
				icon="/media/icons/art/art002.svg"
				title={'Payouts'}
				fontIcon="bi-app-indicator"
			/>

			{/* <AsideMenuItem
				to="/transfers"
				icon="/media/icons/art/art006.svg"
				title={'Transfers'}
				fontIcon="bi-app-indicator"
			/> */}

			<AsideMenuItem
				to={ROUTES.BALANCE}
				icon="/media/icons/abstract/abs015.svg"
				title={'Wallets'}
				fontIcon="bi-app-indicator"
			/>

			{/* <AsideMenuItem
				to={ROUTES.AUDIT}
				icon="/media/icons/art/art008.svg"
				title={'Audit Trail'}
				fontIcon="bi-app-indicator"
			/> */}

			<AsideMenuItem
				to={ROUTES.INVOICES}
				icon="/media/icons/art/art008.svg"
				title={'Invoices'}
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/settings"
				icon="/media/icons/art/art008.svg"
				title={'Settings'}
				fontIcon="bi-app-indicator"
			/>
			<div className="menu-item">
				<div className="menu-content">
					<div className="separator mx-1 my-4"></div>
				</div>
			</div>
		</>
	);
}
