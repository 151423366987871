export type CreateBusinessType = {
	business_name: string;
	business_size?: string;
	business_category?: string;
	contact_phone: string;
	contact_email: string;
	website_url?: string;
	default_currency: string;
	accepted_tokens?: AcceptedCurrencyEnum | string[];
};

export enum AcceptedCurrencyEnum {
	USDT = 'usdt',
	USDC = 'usdc',
}

export enum BusinessDomainEnum {
	TEST = 'test',
	LIVE = 'live',
}

export type UpdateBusinessType = {
	domain: BusinessDomainEnum;
	support_code: string | null;
	business_name: string;
	business_category: string;
	business_type: string | null;
	business_size: string;
	business_description: string | null;
	logo_url: string | null;
	bvn: string | null;
	rc: string | null;
	contact_phone: string;
	contact_email: string;
	support_email: string | null;
	chargeback_email: string | null;
	country: string;
	state: string | null;
	address: string | null;
	timezone: string | null;
	website_url: string | null;
	social_url: string | null;
	callback_url: string | null;
	test_webhook_url: string | null;
	live_webhook_url: string | null;
	cancel_url: string | null;
	default_currency: string;
	accepted_tokens: string[] | [];
	customer_bears_fee: number;
	supported_currencies: string | null;
	settlement_method: string;
	settlement_currency: string | null;
	settlement_address: string | null;
	settlement_account_no: string | null;
	settlement_swift_code: string | null;
	send_receipt_to_recipient: number;
	send_receipt_to_sender: number;
	onboarding_status: string;
	risk_level: string;
	rating: null;
};

export type IBusiness = {
	id: number;
	uuid: string;
	created_by: number;
	referred_by: number;
	primary_user: number;
	support_code: string;
	business_name: string;
	business_category: string;
	business_type?: string;
	business_size: string;
	business_description?: string;
	logo_url?: string;
	bvn?: string;
	rc?: string;
	contact_phone: string;
	contact_email: string;
	support_email?: string;
	chargeback_email?: string;
	country?: string;
	state?: string;
	address?: string;
	timezone?: string;
	website_url: string;
	social_url?: string;
	callback_url?: string;
	cancel_url?: string;
	default_currency: string;
	accepted_tokens: AcceptedCurrencyEnum;
	customer_bears_fee: number;
	supported_coins?: string;
	settlement_method: string;
	settlement_currency?: string;
	settlement_address?: string;
	settlement_mode?: string;
	settlement_account_no?: string;
	settlement_swift_code?: string;
	send_receipt_to_recipient: number;
	send_receipt_to_sender: number;
	onboarding_status: string;
	risk_level: string;
	rating?: string;
	setup_complete: number;
	reviewed_at?: string;
	is_live: number;
	go_live_requested_at?: string;
	live_keys_requested_at?: string;
	is_under_investigation: number;
	suspended_at?: string;
	suspension_reason?: string;
	disabled_at?: string;
	disabled_reason?: string;
	createdAt: string;
	updatedAt: string;
	is_active_business?: boolean;
	domain: 'live' | 'test';
};
