import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { Modal, Button as BtsButton } from 'react-bootstrap';
import { useFormik } from 'formik';
import {
	Button,
	Card,
	CardBody,
	FormInput,
	FormSelect,
	RenderSvg,
	Search,
	SimpleTable,
} from '../../components';
import { titleCase } from '../../helpers/utils';
import { BusinessStateContext } from '../../store/Business/business.provider';
import { LayoutContext } from '../../layouts';
import { IBank, IBankAccount, ICoinAddress, INetwork, IWallet } from '../../types/_model';
import * as Yup from 'yup';
import {
	addBankAccount,
	addCoinAddress,
	deleteBankAccount,
	deleteCoinAddress,
	listCoinAddress,
} from '../../services/internal/withdrawal.service';
import { listNetworks } from '../../services/internal/app.service';

type Props = {
	accepted_coins: IWallet[];
	coinAddressData: ICoinAddress[];
	accepted_fiats: IWallet[];
	bankAccountData: IBankAccount[];
	banks: IBank[];
	setCoinAddressData: React.Dispatch<React.SetStateAction<ICoinAddress[]>>;
	getBankAccount: () => Promise<void>;
};

const BankSchema = Yup.object().shape({
	bank: Yup.string().required('Bank is required'),
	currency: Yup.string().required('Currency is required'),
	account_name: Yup.string().required('Account Name is required'),
	account_no: Yup.string().required('Account Number is required'),
});

let bankInitialValues = {
	currency: 0,
	bank: 0,
	account_name: '',
	account_no: '',
};

const CoinSchema = Yup.object().shape({
	title: Yup.string().required('Coin Title is required'),
	currency: Yup.string().required('Currency is required'),
	description: Yup.string().required('Description is required'),
	network: Yup.string().required('Network is required'),
	address: Yup.string().required('Address is required'),
});

let coinInitialValues = {
	currency: '',
	title: '',
	description: '',
	network: '',
	address: '',
};

const Payment: React.FC<Props> = ({
	accepted_coins,
	coinAddressData,
	accepted_fiats,
	bankAccountData,
	banks,
	setCoinAddressData,
	getBankAccount,
}: Props) => {
	const [tab, setTab] = useState('Fiat');
	const [showModal, setShowModal] = useState(false);
	const [networks, setNetworks] = useState<INetwork[]>([]);
	const handleSetModal = () => setShowModal(!showModal);

	const [showBankForm, setShowBankForm] = useState(false);
	const handleSetShowBankForm = () => setShowBankForm(!showBankForm);

	const { activeBusiness } = useContext(BusinessStateContext);
	const { setError, setSuccess } = useContext(LayoutContext);

	const handleCoinChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
		coinformik.setFieldValue('currency', event.target.value);
		const getNetworks = await listNetworks(event.target.value, activeBusiness?.domain);
		setNetworks(getNetworks.data);
	};

	const handleDeleteCoinAddress = async (address: string) => {
		try {
			await deleteCoinAddress(address);
			setSuccess('Coin deleted successfully');
			const addresses = await listCoinAddress();
			setCoinAddressData(addresses.data);
			return;
		} catch (error: any) {
			console.error(error);
			setError(
				error.response.data.errors
					? `${error.response.data.errors[0].message} for ${titleCase(
							error.response.data.errors[0].field
					  )}`
					: 'Something went wrong'
			);
		}
	};

	const handleDeleteBankAccount = async (account: string) => {
		try {
			await deleteBankAccount(account);
			setSuccess('Bank Account deleted successfully');
			await getBankAccount();
			return;
		} catch (error: any) {
			console.error(error);
			setError(
				error.response.data.errors
					? `${error.response.data.errors[0].message} for ${titleCase(
							error.response.data.errors[0].field
					  )}`
					: 'Something went wrong'
			);
		}
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: bankInitialValues,
		validationSchema: BankSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				await addBankAccount(values);
				setSuccess('Bank Account added successfully');
				getBankAccount();
				resetForm();
				handleSetShowBankForm();
				return;
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	const coinformik = useFormik({
		enableReinitialize: true,
		initialValues: coinInitialValues,
		validationSchema: CoinSchema,
		onSubmit: async (values, { setSubmitting, resetForm }) => {
			try {
				await addCoinAddress(values);
				const addresses = await listCoinAddress();
				setCoinAddressData(addresses.data);
				setSuccess('Coin created successfully');
				handleSetModal();
				resetForm();
				return;
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
				handleSetModal();
			}
		},
	});

	return (
		<div className="card card-custom">
			<div className="card-header card-header-stretch overflow-auto">
				<ul
					className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap"
					role="tablist"
				>
					<li className="nav-item">
						<a
							className={clsx(`nav-link cursor-pointer`, { active: tab === 'Fiat' })}
							onClick={() => setTab('Fiat')}
							role="tab"
						>
							Fiat
						</a>
					</li>
					<li className="nav-item">
						<a
							className={clsx(`nav-link cursor-pointer`, { active: tab === 'Coin' })}
							onClick={() => setTab('Coin')}
							role="tab"
						>
							Coin
						</a>
					</li>
				</ul>
			</div>

			{/* begin::Body */}
			<div className="card-body">
				<div className="tab-content pt-3">
					<div className={clsx('tab-pane', { active: tab === 'Fiat' })}>
						{!showBankForm && (
							<Card>
								{bankAccountData.length === 0 && (
									<div className="d-flex justify-content-end">
										<Button
											type="button"
											className="btn btn-sm btn-primary"
											onClick={handleSetShowBankForm}
										>
											Add Bank
										</Button>
									</div>
								)}

								<CardBody>
									<SimpleTable
										tableData={bankAccountData}
										totalColumns={3}
										tdClass="text-muted"
										enableButton={true}
										columns={['Bank', 'Account No']}
									>
										{Object.values(bankAccountData).length > 0 ? (
											bankAccountData.map((item, index) => {
												if (index > 0) return;
												return (
													<tr key={index} className="odd">
														<td className="text-dark fs-6">
															{item.bank_id}
														</td>
														<td className="text-dark fs-6">
															{item.account_no}
														</td>

														<td className="text-end">
															<div className="">
																<button
																	onClick={() => {
																		handleDeleteBankAccount(
																			item.account_no
																		);
																	}}
																	className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  mt-2"
																>
																	Remove Account
																</button>
															</div>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td colSpan={7}>
													<div className="d-flex text-center w-100 align-content-center justify-content-center">
														You don't have any bank account, please add
														one.
													</div>
												</td>
											</tr>
										)}
									</SimpleTable>
								</CardBody>
							</Card>
						)}

						{showBankForm && (
							<form className="form" onSubmit={formik.handleSubmit}>
								<div className="row mb-10">
									<div className="col-lg-6">
										<div className="">
											<FormSelect
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('currency')}
												labelName="Currency"
												labelClass="col-form-label"
											>
												<option> Select Currency </option>
												{accepted_fiats.map((fiat) => {
													return (
														<option key={fiat.id} value={fiat.id}>
															{fiat.code}
														</option>
													);
												})}
											</FormSelect>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="">
											<FormSelect
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('bank')}
												labelName="Bank"
												labelClass="col-form-label"
											>
												<option> Select Bank </option>
												{banks.map((bank) => (
													<option key={bank.id} value={bank.id}>
														{bank.name}
													</option>
												))}
											</FormSelect>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="">
											<FormInput
												type="text"
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('account_no')}
												labelName="Account Number"
												labelClass="col-form-label"
											/>
										</div>
									</div>

									<div className="col-lg-6">
										<div className="">
											<FormInput
												type="text"
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('account_name')}
												labelName="Account Name"
												labelClass="col-form-label"
											/>
										</div>
									</div>

									{/* <div className="col-lg-6">
										<div className="">
											<FormInput
												type="text"
												className="form-control form-control-lg form-control-solid"
												// {...formik.getFieldProps('business_name')}
												labelName="Sort Code"
												labelClass="col-form-label"
											/>
										</div>
									</div> */}

									{/* <div className="col-lg-6">
										<div className="">
											<FormInput
												type="text"
												className="form-control form-control-lg form-control-solid"
												{...formik.getFieldProps('settlement_swift_code')}
												labelName="Swift Code"
												labelClass="col-form-label"
											/>
										</div>
									</div> */}
								</div>

								<div className="card-footer d-flex justify-content-end py-6 px-9">
									<button
										type="button"
										className="btn btn-light"
										onClick={handleSetShowBankForm}
									>
										Close
									</button>

									<button
										type="submit"
										className="btn btn-primary"
										disabled={formik.isSubmitting || !formik.isValid}
									>
										{!formik.isSubmitting && (
											<span className="indicator-label">Submit</span>
										)}
										{formik.isSubmitting && (
											<span
												className="indicator-progress"
												style={{ display: 'block' }}
											>
												Please wait...{' '}
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										)}
									</button>
								</div>
							</form>
						)}
					</div>

					<div className={clsx('tab-pane', { active: tab === 'Coin' })}>
						<Card>
							<div className="card-header border-0 pt-6">
								<Search placeholder="Search Members" />
								<div className="card-toolbar">
									<div
										className="d-flex justify-content-end"
										data-kt-user-table-toolbar="base"
									>
										<Button
											type="button"
											className="btn btn-sm btn-primary"
											onClick={handleSetModal}
										>
											New Address
										</Button>
									</div>
								</div>
							</div>

							<CardBody>
								<SimpleTable
									tableData={coinAddressData}
									totalColumns={5}
									tdClass="text-muted"
									enableButton={true}
									columns={['title', 'address', 'currency', 'network']}
								>
									{Object.values(coinAddressData).length > 0 ? (
										coinAddressData.map((item, index) => {
											return (
												<tr key={index} className="odd">
													<td className="text-dark fs-6">{item.title}</td>
													<td className="text-dark fs-6">
														{item.address}
													</td>
													<td className="text-dark fs-6">
														{item.currency}
													</td>
													<td className="text-dark fs-6">
														{item.network}
													</td>

													<td className="text-end">
														<div className="">
															<button
																onClick={() => {
																	handleDeleteCoinAddress(
																		item.address
																	);
																}}
																className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm  mt-2"
															>
																Remove Address
															</button>
														</div>
													</td>
												</tr>
											);
										})
									) : (
										<tr>
											<td colSpan={7}>
												<div className="d-flex text-center w-100 align-content-center justify-content-center">
													No matching records found
												</div>
											</td>
										</tr>
									)}
								</SimpleTable>
							</CardBody>
						</Card>
					</div>
					{/* </form> */}
				</div>
			</div>
			{/* end::Body */}

			{/* New Address modal */}
			<Modal show={showModal}>
				<Modal.Header>
					<Modal.Title>Create New Address</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetModal}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							{/*Role Form */}
							<form
								onSubmit={coinformik.handleSubmit}
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework `}
								noValidate
								id="coin"
							>
								<div className="fv-row mb-4">
									<FormInput
										labelName="Title"
										labelClass="form-label fs-6 fw-bolder mb-3"
										{...coinformik.getFieldProps('title')}
										className="form-control form-control-lg form-control-solid "
										id="title"
									/>
								</div>

								<div className="fv-row mb-4">
									<FormSelect
										labelName="Coin"
										labelClass="form-label fs-6 fw-bolder mb-3"
										{...coinformik.getFieldProps('currency')}
										className={
											'form-control form-control-lg form-control-solid'
										}
										onChange={handleCoinChange}
									>
										<option defaultValue="">Select Coin </option>
										{accepted_coins.map((coin) => {
											return (
												<option key={coin.id} defaultValue={coin.code}>
													{coin.code}
												</option>
											);
										})}
									</FormSelect>
								</div>

								{/* Get Newtork according coin selected */}
								<div className="fv-row mb-4">
									<FormSelect
										labelName="Network"
										labelClass="form-label fs-6 fw-bolder mb-3"
										{...coinformik.getFieldProps('network')}
										className={
											'form-control form-control-lg form-control-solid'
										}
									>
										<option defaultValue="">Select Network </option>
										{networks.map((network) => {
											return (
												<option key={network.key} value={network.key}>
													{network.name}
												</option>
											);
										})}
									</FormSelect>
								</div>

								<div className="fv-row mb-0">
									<FormInput
										{...coinformik.getFieldProps('description')}
										className="form-control form-control-lg form-control-solid "
										id="description"
										labelName="Description"
										labelClass="form-label fs-6 fw-bolder mb-3"
									/>
								</div>
								<div className="fv-row mb-0">
									<FormInput
										{...coinformik.getFieldProps('address')}
										className="form-control form-control-lg form-control-solid "
										id="address"
										labelName="Address"
										labelClass="form-label fs-6 fw-bolder mb-3"
									/>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<BtsButton variant="secondary" onClick={handleSetModal}>
						Close
					</BtsButton>

					<Button
						disabled={coinformik.isSubmitting || !coinformik.isValid}
						className={'btn btn-light'}
						type="submit"
						form="coin"
					>
						{!coinformik.isSubmitting && (
							<span className="indicator-label">Create</span>
						)}

						{coinformik.isSubmitting && (
							<span className="indicator-progress" style={{ display: 'block' }}>
								Please wait...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Payment;
