import React, { useContext, useState } from 'react';
import { FormInput } from '../../components';
import { phoneRegExp } from '../auth/register/Register';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { hasOwnProperty, titleCase } from '../../helpers/utils';
import { AuthDispatchContext, AuthStateContext } from '../../store/Auth/auth.provider';
import { UpdateUserAction } from '../../store/Auth/auth.actions';
import { modifiedValues } from './Business';
import clsx from 'clsx';
import { LayoutContext } from '../../layouts';
import { updatePassword } from '../../services/internal/auth.service';
import { UserModel } from '../../types/_model';

const userSchema = Yup.object().shape({
	firstname: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('First name is required'),
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	lastname: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Last name is required'),
	phone: Yup.string()
		.matches(phoneRegExp, 'Phone number is not valid')
		.required('Phone number is required'),
});

const passwordSchema = Yup.object().shape({
	current_password: Yup.string()
		.min(6, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),

	new_password: Yup.string()
		.min(6, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),

	new_password_confirmation: Yup.string()
		.required('Password confirmation is required')
		.when('new_password', {
			is: (val: string) => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref('new_password')],
				"Password and Confirm Password didn't match"
			),
		}),
});

const initialUserValues = {
	firstname: '',
	lastname: '',
	email: '',
	phone: '',
};

const initialPasswordValues = {
	current_password: '',
	new_password: '',
	new_password_confirmation: '',
};

const Personal: React.FC = () => {
	const [showPasswordForm, setPasswordForm] = useState<boolean>(false);

	const { user } = useContext(AuthStateContext);
	const dispatch = useContext(AuthDispatchContext);

	const { setError, setSuccess } = useContext(LayoutContext);

	if (user) {
		Object.keys(user).forEach((string) => {
			if (
				hasOwnProperty(initialUserValues, string) &&
				hasOwnProperty(user, string) &&
				user[string] !== null
			) {
				initialUserValues[string] = user[string];
			}
		});
	}

	const formik = useFormik({
		initialValues: initialUserValues,
		enableReinitialize: true,
		validationSchema: userSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			try {
				const hasChangedUser = modifiedValues(initialUserValues, values);
				if (Object.keys(hasChangedUser).length > 0) {
					dispatch(await UpdateUserAction(hasChangedUser as UserModel));
					setSubmitting(false);
					setSuccess('User updated successfully');
					return;
				}
				setError('No changes were made');
			} catch (error: any) {
				setStatus('The registration details is incorrect');
				setSubmitting(false);
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
			}
		},
	});

	const formikPassword = useFormik({
		initialValues: initialPasswordValues,
		enableReinitialize: true,
		validationSchema: passwordSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				await updatePassword(values);
				setSubmitting(false);
				setSuccess('Password updated successfully');
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	return (
		<div className="card mb-5 mb-xl-10">
			<div className="card-header border-0 cursor-pointer">
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">Profile Details</h3>
				</div>
			</div>

			<div className="collapse show">
				<div className="card-body border-top p-9">
					<form className="form" onSubmit={formik.handleSubmit}>
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								First Name
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
											{...formik.getFieldProps('firstname')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Last Name
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('lastname')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Email
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('email')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Phone Number
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid"
											{...formik.getFieldProps('phone')}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="card-footer d-flex justify-content-end py-6 px-9">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={formik.isSubmitting || !formik.isValid}
							>
								{!formik.isSubmitting && (
									<span className="indicator-label">Submit</span>
								)}
								{formik.isSubmitting && (
									<span
										className="indicator-progress"
										style={{ display: 'block' }}
									>
										Please wait...{' '}
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
									</span>
								)}
							</button>
						</div>
					</form>

					<div className="separator separator-dashed my-6"></div>

					<div className="d-flex flex-wrap align-items-center mb-5">
						<div
							id="kt_signin_password"
							className={' ' + (showPasswordForm && 'd-none')}
						>
							<div className="fs-6 fw-bolder mb-1">Password</div>
							<div className="fw-bold text-gray-600">************</div>
						</div>

						<div
							id="kt_signin_password_edit"
							className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}
						>
							<form
								id="kt_signin_change_password"
								className="form"
								onSubmit={formikPassword.handleSubmit}
							>
								<div className="row mb-1">
									<div className="col-lg-4">
										<div className="fv-row mb-0">
											<label
												htmlFor="currentpassword"
												className="form-label fs-6 fw-bolder mb-3"
											>
												Current Password
											</label>
											<FormInput
												type="password"
												className={clsx(
													'form-control form-control-lg form-control-solid',
													{
														'is-invalid':
															formikPassword.touched
																.current_password &&
															formikPassword.errors.current_password,
													},
													{
														'is-valid':
															formikPassword.touched
																.current_password &&
															!formikPassword.errors.current_password,
													}
												)}
												{...formikPassword.getFieldProps(
													'current_password'
												)}
											/>
										</div>
									</div>

									<div className="col-lg-4">
										<div className="fv-row mb-0">
											<label
												htmlFor="newpassword"
												className="form-label fs-6 fw-bolder mb-3"
											>
												New Password
											</label>
											<FormInput
												type="password"
												className={clsx(
													'form-control form-control-lg form-control-solid',
													{
														'is-invalid':
															formikPassword.touched.new_password &&
															formikPassword.errors.new_password,
													},
													{
														'is-valid':
															formikPassword.touched.new_password &&
															!formikPassword.errors.new_password,
													}
												)}
												{...formikPassword.getFieldProps('new_password')}
											/>
										</div>
									</div>

									<div className="col-lg-4">
										<div className="fv-row mb-0">
											<label
												htmlFor="confirmpassword"
												className="form-label fs-6 fw-bolder mb-3"
											>
												Confirm New Password
											</label>
											<FormInput
												type="password"
												className={clsx(
													'form-control form-control-lg form-control-solid',
													{
														'is-invalid':
															formikPassword.touched
																.new_password_confirmation &&
															formikPassword.errors
																.new_password_confirmation,
													},
													{
														'is-valid':
															formikPassword.touched
																.new_password_confirmation &&
															!formikPassword.errors
																.new_password_confirmation,
													}
												)}
												{...formikPassword.getFieldProps(
													'new_password_confirmation'
												)}
											/>
										</div>
									</div>
								</div>

								<div className="form-text mb-5">
									Password must be at least 8 character and contain symbols
								</div>

								<div className="d-flex">
									<button
										type="submit"
										className="btn btn-primary"
										disabled={
											formikPassword.isSubmitting || !formikPassword.isValid
										}
									>
										{!formikPassword.isSubmitting && (
											<span className="indicator-label">Update Password</span>
										)}
										{formikPassword.isSubmitting && (
											<span
												className="indicator-progress"
												style={{ display: 'block' }}
											>
												Please wait...{' '}
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
										)}
									</button>

									<button
										onClick={() => {
											setPasswordForm(false);
										}}
										type="button"
										className="btn btn-color-gray-400 btn-active-light-primary px-6"
									>
										Cancel
									</button>
								</div>
							</form>
						</div>

						<div className={'ms-auto ' + (showPasswordForm && 'd-none')}>
							<button
								onClick={() => {
									setPasswordForm(true);
								}}
								className="btn btn-light btn-active-light-primary"
							>
								Reset Password
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Personal;
