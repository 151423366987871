import { usecoinsApi } from '../../services/http';
import { DASHBOARD_ENDPOINTS } from '../../constants/endpoints';
import { buildPageQuery } from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';

export const getDashboard = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${DASHBOARD_ENDPOINTS.DASHBOARD}${buildPageQuery(pageQuery)}`;

		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error) {
		throw error;
	}
};
