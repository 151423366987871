import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { register } from '../../../services/internal/auth';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import { FormInput, FormSelect, Button } from '../../../components';
import { PasswordMeterComponent } from '../../../components/ts';
import { AuthDispatchContext, AuthStateContext } from '../../../store/Auth/auth.provider';
import { authActions } from '../../../store/Auth/auth.actions';
import { LayoutContext } from '../../../layouts';
import { titleCase } from '../../../helpers/utils';

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	business_name: '',
	password: '',
	changePassword: '',
	acceptTerms: false,
	phoneNumber: '',
	country: '',
	accountType: '',
};

export const phoneRegExp =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const registrationSchema = Yup.object().shape({
	firstName: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('First name is required'),
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	lastName: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Last name is required'),
	business_name: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Business name is required'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),

	acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
	phoneNumber: Yup.string()
		.matches(phoneRegExp, 'Phone number is not valid')
		.required('Phone number is required'),
	country: Yup.string().required('Country is required'),
	accountType: Yup.string().required('Account type is required'),
});

const Registeration: React.FC = () => {
	const { loading } = useContext(AuthStateContext);
	let navigate = useNavigate();

	const dispatch = useContext(AuthDispatchContext);

	const { setError } = useContext(LayoutContext);

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			dispatch({ type: authActions.AUTH_LOADING, payload: true });
			try {
				const { data: auth } = await register(
					values.email,
					values.firstName,
					values.lastName,
					values.business_name,
					values.password,
					values.phoneNumber,
					values.country,
					values.accountType
				);

				dispatch({
					type: authActions.REGISTER,
					payload: auth.data,
				});
				setSubmitting(false);
				navigate(`/${ROUTES.LOGIN}`);
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
				dispatch({ type: authActions.AUTH_LOADING, payload: false });
			}
		},
	});

	const [showPassword, setShowPassword] = useState(false);
	const handleSetShowPassword = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		PasswordMeterComponent.bootstrap();
	}, []);

	return (
		<div className="w-lg-600px p-10 p-lg-15 mx-auto">
			<form
				className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
				noValidate
				id="kt_sign_up_form"
				onSubmit={formik.handleSubmit}
			>
				{/* begin::Heading */}
				<div className="mb-10 text-center">
					{/* begin::Title */}
					<h1 className="text-dark mb-3">Create an Account</h1>
					{/* end::Title */}
				</div>
				{/* end::Heading */}

				{formik.status && (
					<div className="mb-lg-15 alert alert-danger">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				)}

				{/* begin::Form group firstName */}
				<div className="row fv-row mb-2">
					<div className="col-xl-6 mb-5">
						<FormInput
							labelName="First Name"
							labelClass="form-label fw-bolder text-dark fs-6"
							placeholder="First Name"
							type="text"
							autoComplete="off"
							{...formik.getFieldProps('firstName')}
							className={clsx(
								'form-control form-control-lg form-control-solid',
								{
									'is-invalid':
										formik.touched.firstName && formik.errors.firstName,
								},
								{
									'is-valid':
										formik.touched.firstName && !formik.errors.firstName,
								}
							)}
						/>
						{formik.touched.firstName && formik.errors.firstName && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.firstName}</span>
								</div>
							</div>
						)}
					</div>
					<div className="col-xl-6">
						{/* begin::Form group lastName */}
						<div className="fv-row mb-5">
							<FormInput
								labelName="Last Name"
								labelClass="form-label fw-bolder text-dark fs-6"
								placeholder="Last Name"
								type="text"
								autoComplete="off"
								{...formik.getFieldProps('lastName')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{
										'is-invalid':
											formik.touched.lastName && formik.errors.lastName,
									},
									{
										'is-valid':
											formik.touched.lastName && !formik.errors.lastName,
									}
								)}
							/>
							{formik.touched.lastName && formik.errors.lastName && (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block">
										<span role="alert">{formik.errors.lastName}</span>
									</div>
								</div>
							)}
						</div>
						{/* end::Form group */}
					</div>
				</div>
				{/* end::Form group */}

				{/* begin::Form group Email */}
				<div className="fv-row mb-7">
					<FormInput
						labelName="Email"
						labelClass="form-label fw-bolder text-dark fs-6"
						placeholder="Email"
						type="email"
						autoComplete="off"
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.email && formik.errors.email },
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
					/>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group business name */}
				<div className="row fv-row mb-7">
					<FormInput
						labelName="Business Name"
						labelClass="form-label fw-bolder text-dark fs-6"
						placeholder="Business Name"
						type="text"
						autoComplete="off"
						{...formik.getFieldProps('business_name')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid':
									formik.touched.business_name && formik.errors.business_name,
							},
							{
								'is-valid':
									formik.touched.business_name && !formik.errors.business_name,
							}
						)}
					/>
					{formik.touched.business_name && formik.errors.business_name && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.business_name}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group Phone */}
				<div className="fv-row mb-7">
					<FormInput
						labelName="Phone Number"
						labelClass="form-label fw-bolder text-dark fs-6"
						placeholder="Phone Number"
						type="tel"
						autoComplete="off"
						{...formik.getFieldProps('phoneNumber')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid':
									formik.touched.phoneNumber && formik.errors.phoneNumber,
							},
							{
								'is-valid':
									formik.touched.phoneNumber && !formik.errors.phoneNumber,
							}
						)}
					/>
					{formik.touched.phoneNumber && formik.errors.phoneNumber && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.phoneNumber}</span>
							</div>
						</div>
					)}
				</div>

				{/* end::Form group */}

				{/* begin::Form group Country */}

				<div className="fv-row mb-7">
					<FormSelect
						className="form-control form-control-lg form-control-solid"
						labelName="Country"
						labelClass="form-label fw-bolder text-dark fs-6"
						{...formik.getFieldProps('country')}
					>
						<option value=""> Select a Country...</option>
						<option value="AF">Afghanistan</option>
						<option value="AX">Aland Islands</option>
						<option value="AL">Albania</option>
						<option value="DZ">Algeria</option>
						<option value="AS">American Samoa</option>
						<option value="AD">Andorra</option>
						<option value="AO">Angola</option>
						<option value="AI">Anguilla</option>
						<option value="AQ">Antarctica</option>
						<option value="AG">Antigua and Barbuda</option>
						<option value="AR">Argentina</option>
						<option value="AM">Armenia</option>
						<option value="AW">Aruba</option>
						<option value="AU">Australia</option>
						<option value="AT">Austria</option>
						<option value="AZ">Azerbaijan</option>
						<option value="BS">Bahamas</option>
						<option value="BH">Bahrain</option>
						<option value="BD">Bangladesh</option>
						<option value="BB">Barbados</option>
						<option value="BY">Belarus</option>
						<option value="BE">Belgium</option>
						<option value="BZ">Belize</option>
						<option value="BJ">Benin</option>
						<option value="BM">Bermuda</option>
						<option value="BT">Bhutan</option>
						<option value="BO">Bolivia, Plurinational State of</option>
						<option value="BQ">Bonaire, Sint Eustatius and Saba</option>
						<option value="BA">Bosnia and Herzegovina</option>
						<option value="BW">Botswana</option>
						<option value="BV">Bouvet Island</option>
						<option value="BR">Brazil</option>
						<option value="IO">British Indian Ocean Territory</option>
						<option value="BN">Brunei Darussalam</option>
						<option value="BG">Bulgaria</option>
						<option value="BF">Burkina Faso</option>
						<option value="BI">Burundi</option>
						<option value="KH">Cambodia</option>
						<option value="CM">Cameroon</option>
						<option value="CA">Canada</option>
						<option value="CV">Cape Verde</option>
						<option value="KY">Cayman Islands</option>
						<option value="CF">Central African Republic</option>
						<option value="TD">Chad</option>
						<option value="CL">Chile</option>
						<option value="CN">China</option>
						<option value="CX">Christmas Island</option>
						<option value="CC">Cocos (Keeling) Islands</option>
						<option value="CO">Colombia</option>
						<option value="KM">Comoros</option>
						<option value="CG">Congo</option>
						<option value="CD">Congo, the Democratic Republic of the</option>
						<option value="CK">Cook Islands</option>
						<option value="CR">Costa Rica</option>
						<option value="CI">Côte d'Ivoire</option>
						<option value="HR">Croatia</option>
						<option value="CU">Cuba</option>
						<option value="CW">Curaçao</option>
						<option value="CY">Cyprus</option>
						<option value="CZ">Czech Republic</option>
						<option value="DK">Denmark</option>
						<option value="DJ">Djibouti</option>
						<option value="DM">Dominica</option>
						<option value="DO">Dominican Republic</option>
						<option value="EC">Ecuador</option>
						<option value="EG">Egypt</option>
						<option value="SV">El Salvador</option>
						<option value="GQ">Equatorial Guinea</option>
						<option value="ER">Eritrea</option>
						<option value="EE">Estonia</option>
						<option value="ET">Ethiopia</option>
						<option value="FK">Falkland Islands (Malvinas)</option>
						<option value="FO">Faroe Islands</option>
						<option value="FJ">Fiji</option>
						<option value="FI">Finland</option>
						<option value="FR">France</option>
						<option value="GF">French Guiana</option>
						<option value="PF">French Polynesia</option>
						<option value="TF">French Southern Territories</option>
						<option value="GA">Gabon</option>
						<option value="GM">Gambia</option>
						<option value="GE">Georgia</option>
						<option value="DE">Germany</option>
						<option value="GH">Ghana</option>
						<option value="GI">Gibraltar</option>
						<option value="GR">Greece</option>
						<option value="GL">Greenland</option>
						<option value="GD">Grenada</option>
						<option value="GP">Guadeloupe</option>
						<option value="GU">Guam</option>
						<option value="GT">Guatemala</option>
						<option value="GG">Guernsey</option>
						<option value="GN">Guinea</option>
						<option value="GW">Guinea-Bissau</option>
						<option value="GY">Guyana</option>
						<option value="HT">Haiti</option>
						<option value="HM">Heard Island and McDonald Islands</option>
						<option value="VA">Holy See (Vatican City State)</option>
						<option value="HN">Honduras</option>
						<option value="HK">Hong Kong</option>
						<option value="HU">Hungary</option>
						<option value="IS">Iceland</option>
						<option value="IN">India</option>
						<option value="ID">Indonesia</option>
						<option value="IR">Iran, Islamic Republic of</option>
						<option value="IQ">Iraq</option>
						<option value="IE">Ireland</option>
						<option value="IM">Isle of Man</option>
						<option value="IL">Israel</option>
						<option value="IT">Italy</option>
						<option value="JM">Jamaica</option>
						<option value="JP">Japan</option>
						<option value="JE">Jersey</option>
						<option value="JO">Jordan</option>
						<option value="KZ">Kazakhstan</option>
						<option value="KE">Kenya</option>
						<option value="KI">Kiribati</option>
						<option value="KP">Korea, Democratic People's Republic of</option>
						<option value="KW">Kuwait</option>
						<option value="KG">Kyrgyzstan</option>
						<option value="LA">Lao People's Democratic Republic</option>
						<option value="LV">Latvia</option>
						<option value="LB">Lebanon</option>
						<option value="LS">Lesotho</option>
						<option value="LR">Liberia</option>
						<option value="LY">Libya</option>
						<option value="LI">Liechtenstein</option>
						<option value="LT">Lithuania</option>
						<option value="LU">Luxembourg</option>
						<option value="MO">Macao</option>
						<option value="MK">Macedonia, the former Yugoslav Republic of</option>
						<option value="MG">Madagascar</option>
						<option value="MW">Malawi</option>
						<option value="MY">Malaysia</option>
						<option value="MV">Maldives</option>
						<option value="ML">Mali</option>
						<option value="MT">Malta</option>
						<option value="MH">Marshall Islands</option>
						<option value="MQ">Martinique</option>
						<option value="MR">Mauritania</option>
						<option value="MU">Mauritius</option>
						<option value="YT">Mayotte</option>
						<option value="MX">Mexico</option>
						<option value="FM">Micronesia, Federated States of</option>
						<option value="MD">Moldova, Republic of</option>
						<option value="MC">Monaco</option>
						<option value="MN">Mongolia</option>
						<option value="ME">Montenegro</option>
						<option value="MS">Montserrat</option>
						<option value="MA">Morocco</option>
						<option value="MZ">Mozambique</option>
						<option value="MM">Myanmar</option>
						<option value="NA">Namibia</option>
						<option value="NR">Nauru</option>
						<option value="NP">Nepal</option>
						<option value="NL">Netherlands</option>
						<option value="NC">New Caledonia</option>
						<option value="NZ">New Zealand</option>
						<option value="NI">Nicaragua</option>
						<option value="NE">Niger</option>
						<option value="NG">Nigeria</option>
						<option value="NU">Niue</option>
						<option value="NF">Norfolk Island</option>
						<option value="MP">Northern Mariana Islands</option>
						<option value="NO">Norway</option>
						<option value="OM">Oman</option>
						<option value="PK">Pakistan</option>
						<option value="PW">Palau</option>
						<option value="PS">Palestinian Territory, Occupied</option>
						<option value="PA">Panama</option>
						<option value="PG">Papua New Guinea</option>
						<option value="PY">Paraguay</option>
						<option value="PE">Peru</option>
						<option value="PH">Philippines</option>
						<option value="PN">Pitcairn</option>
						<option value="PL">Poland</option>
						<option value="PT">Portugal</option>
						<option value="PR">Puerto Rico</option>
						<option value="QA">Qatar</option>
						<option value="RE">Réunion</option>
						<option value="RO">Romania</option>
						<option value="RU">Russian Federation</option>
						<option value="RW">Rwanda</option>
						<option value="BL">Saint Barthélemy</option>
						<option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
						<option value="KN">Saint Kitts and Nevis</option>
						<option value="LC">Saint Lucia</option>
						<option value="MF">Saint Martin (French part)</option>
						<option value="PM">Saint Pierre and Miquelon</option>
						<option value="VC">Saint Vincent and the Grenadines</option>
						<option value="WS">Samoa</option>
						<option value="SM">San Marino</option>
						<option value="ST">Sao Tome and Principe</option>
						<option value="SA">Saudi Arabia</option>
						<option value="SN">Senegal</option>
						<option value="RS">Serbia</option>
						<option value="SC">Seychelles</option>
						<option value="SL">Sierra Leone</option>
						<option value="SG">Singapore</option>
						<option value="SX">Sint Maarten (Dutch part)</option>
						<option value="SK">Slovakia</option>
						<option value="SI">Slovenia</option>
						<option value="SB">Solomon Islands</option>
						<option value="SO">Somalia</option>
						<option value="ZA">South Africa</option>
						<option value="GS">South Georgia and the South Sandwich Islands</option>
						<option value="KR">South Korea</option>
						<option value="SS">South Sudan</option>
						<option value="ES">Spain</option>
						<option value="LK">Sri Lanka</option>
						<option value="SD">Sudan</option>
						<option value="SR">Suriname</option>
						<option value="SJ">Svalbard and Jan Mayen</option>
						<option value="SZ">Swaziland</option>
						<option value="SE">Sweden</option>
						<option value="CH">Switzerland</option>
						<option value="SY">Syrian Arab Republic</option>
						<option value="TW">Taiwan, Province of China</option>
						<option value="TJ">Tajikistan</option>
						<option value="TZ">Tanzania, United Republic of</option>
						<option value="TH">Thailand</option>
						<option value="TL">Timor-Leste</option>
						<option value="TG">Togo</option>
						<option value="TK">Tokelau</option>
						<option value="TO">Tonga</option>
						<option value="TT">Trinidad and Tobago</option>
						<option value="TN">Tunisia</option>
						<option value="TR">Turkey</option>
						<option value="TM">Turkmenistan</option>
						<option value="TC">Turks and Caicos Islands</option>
						<option value="TV">Tuvalu</option>
						<option value="UG">Uganda</option>
						<option value="UA">Ukraine</option>
						<option value="AE">United Arab Emirates</option>
						<option value="GB">United Kingdom</option>
						<option value="US">United States</option>
						<option value="UY">Uruguay</option>
						<option value="UZ">Uzbekistan</option>
						<option value="VU">Vanuatu</option>
						<option value="VE">Venezuela, Bolivarian Republic of</option>
						<option value="VN">Vietnam</option>
						<option value="VI">Virgin Islands</option>
						<option value="WF">Wallis and Futuna</option>
						<option value="EH">Western Sahara</option>
						<option value="YE">Yemen</option>
						<option value="ZM">Zambia</option>
						<option value="ZW">Zimbabwe</option>
					</FormSelect>
				</div>

				{/* end::Form group */}

				{/* begin::Form group Account Type  */}

				<div className="fv-row mb-7">
					<label className="form-label fw-bolder text-dark fs-6">Account Type</label>
					<FormSelect
						{...formik.getFieldProps('accountType')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid':
									formik.touched.accountType && formik.errors.accountType,
							},
							{
								'is-valid':
									formik.touched.accountType && !formik.errors.accountType,
							}
						)}
					>
						<option value="">Select Account Type</option>
						<option value="personal">Personal</option>
						<option value="business">Business</option>
					</FormSelect>
					{formik.touched.accountType && formik.errors.accountType && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.accountType}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group Password */}
				<div className="mb-10 fv-row" data-kt-password-meter="true">
					<div className="mb-1">
						<div className="position-relative mb-3">
							<FormInput
								labelName="Password"
								labelClass="form-label fw-bolder text-dark fs-6"
								type={showPassword ? 'text' : 'password'}
								placeholder="Password"
								autoComplete="off"
								{...formik.getFieldProps('password')}
								className={clsx(
									'form-control form-control-lg form-control-solid',
									{
										'is-invalid':
											formik.touched.password && formik.errors.password,
									},
									{
										'is-valid':
											formik.touched.password && !formik.errors.password,
									}
								)}
							/>
							<div
								style={{ transform: 'translateY(-100%)' }}
								className="position-absolute  end-0 me-9"
							>
								<button
									className="btn btn-icon "
									type="button"
									onClick={handleSetShowPassword}
								>
									{showPassword ? (
										<i className="bi bi-eye-slash fs-1"></i>
									) : (
										<i className="bi bi-eye fs-1"></i>
									)}
								</button>
							</div>
							{formik.touched.password && formik.errors.password && (
								<div className="fv-plugins-message-container ">
									<div className="fv-help-block">
										<span role="alert">{formik.errors.password}</span>
									</div>
								</div>
							)}
						</div>

						{/* begin::Meter */}
						<div
							className="d-flex align-items-center mb-3"
							data-kt-password-meter-control="highlight"
						>
							<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
							<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
							<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
							<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
						</div>
						{/* end::Meter */}
					</div>
					<div className="text-muted">
						Use 8 or more characters with a mix of letters, numbers & symbols.
					</div>
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="fv-row mb-10">
					<div className="form-check form-check-custom form-check-solid">
						<FormInput
							className="form-check-input"
							type="checkbox"
							id="kt_login_toc_agree"
							{...formik.getFieldProps('acceptTerms')}
						/>
						<label
							className="form-check-label fw-bold text-gray-700 fs-6"
							htmlFor="kt_login_toc_agree"
						>
							I Agree the{' '}
							<Link to="/auth/terms" className="ms-1 link-primary">
								terms and conditions
							</Link>
							.
						</label>
						{formik.touched.acceptTerms && formik.errors.acceptTerms && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">
									<span role="alert">{formik.errors.acceptTerms}</span>
								</div>
							</div>
						)}
					</div>
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="text-center">
					<Button
						type="submit"
						id="kt_sign_up_submit"
						className="btn btn-lg btn-primary w-100 mb-5"
						disabled={
							formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms
						}
					>
						{!loading && <span className="indicator-label">Submit</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: 'block' }}>
								Please wait...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</Button>
					<Link to={`/auth/${ROUTES.LOGIN}`}>
						<Button
							type="button"
							id="kt_login_signup_form_cancel_button"
							className="btn btn-lg btn-light-primary w-100 mb-5"
						>
							Cancel
						</Button>
					</Link>
				</div>
				{/* end::Form group */}

				<div className="d-flex align-items-center mb-10">
					<div className="border-bottom border-gray-300 mw-50 w-100"></div>
					<span className="fw-bold text-gray-400 fs-7 mx-2">OR</span>
					<div className="border-bottom border-gray-300 mw-50 w-100"></div>
				</div>

				{/* begin::Link */}
				<div className="text-gray-400 fw-bold fs-4">
					Already have an account?
					<Link
						to={`/auth/${ROUTES.LOGIN}`}
						className="link-primary fw-bolder"
						style={{ marginLeft: '5px' }}
					>
						Login
					</Link>
				</div>
				{/* end::Link */}
			</form>
		</div>
	);
};

export default Registeration;
