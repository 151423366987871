import { usecoinsApi } from '../../services/http';
import { COLLECTIONS_ENDPOINTS } from '../../constants/endpoints';
import { buildPageQuery } from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';

export const listCollections = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${COLLECTIONS_ENDPOINTS.COLLECTIONS}${buildPageQuery(pageQuery)}`;
		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error) {
		throw error;
	}
};

export const findCollection = async (orderUuid: string) => {
	try {
		const { data } = await usecoinsApi.get(
			`${COLLECTIONS_ENDPOINTS.FIND_COLLECTION}/${orderUuid}`
		);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};
