import { ActionType } from '../../types/IAuth';
import * as BusinessService from '../../services/internal/business.service';
import { CreateBusinessType, IBusiness, UpdateBusinessType } from '../../types/IBusiness';

export const businessActions = {
	BUSINESS_ACTION: 'BUSINESS_ACTION',
	BUSINESS_FOUND: 'BUSINESS_FOUND',
	ALL_BUSINESS: 'ALL_BUSINESS',
	BUSINESS_LOADING: 'BUSINESS_LOADING',
	BUSINESS_ERROR: 'BUSINESS_ERROR',
};

export const createBusinessAction = async (
	businessPayload: CreateBusinessType
): Promise<ActionType> => {
	try {
		const createdBusiness = await BusinessService.createBusiness(businessPayload);
		await BusinessService.switchBusiness(createdBusiness?.data.uuid);
		return {
			type: businessActions.BUSINESS_ACTION,
			payload: { ...createdBusiness?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const updateBusinessAction = async (
	businessPayload: UpdateBusinessType
): Promise<ActionType> => {
	try {
		const updatedBusiness = await BusinessService.updateBusiness(businessPayload);
		return {
			type: businessActions.BUSINESS_FOUND,
			payload: { ...updatedBusiness?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const fetchBusinessAction = async (): Promise<ActionType> => {
	try {
		const foundBusiness = await BusinessService.fetchActiveBusiness();
		return {
			type: businessActions.BUSINESS_FOUND,
			payload: foundBusiness.data,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const fetchAllBusinessAction = async (): Promise<ActionType> => {
	try {
		const allBusiness = await BusinessService.fetchBusiness();
		return {
			type: businessActions.ALL_BUSINESS,
			payload: allBusiness.data,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const switchBusinessAction = async (business: IBusiness): Promise<ActionType> => {
	try {
		await BusinessService.switchBusiness(business.uuid);
		return {
			type: businessActions.BUSINESS_ACTION,
			payload: business,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const businessLoadingAction = (payload: boolean) => {
	return {
		type: businessActions.BUSINESS_LOADING,
		payload,
	};
};
