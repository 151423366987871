import clsx from 'clsx';
import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toAbsoluteUrl } from '../../helpers/utils';
import { AuthDispatchContext, AuthStateContext } from '../../store/Auth/auth.provider';
import { ActionType } from '../../types/IAuth';
import { authActions } from '../../store/Auth/auth.actions';
import { switchBusinessDomain } from '../../services/internal/business.service';
import { LayoutContext, Search } from '../index';
import {
	BusinessDispatchContext,
	BusinessStateContext,
} from '../../store/Business/business.provider';
import { fetchBusinessAction } from '../../store/Business/business.actions';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
	toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px';

const Topbar: FC = () => {
	const dispatch = useContext(AuthDispatchContext) as React.Dispatch<ActionType>;

	const { user } = useContext(AuthStateContext);
	const currentUser = user;

	const { setSuccess } = useContext(LayoutContext);

	const { activeBusiness } = useContext(BusinessStateContext);
	const dispatchBusiness = useContext(BusinessDispatchContext) as React.Dispatch<ActionType>;

	const [isLive, setIsLive] = useState(false);

	const handleSwitchDomain = async (event: React.ChangeEvent<HTMLInputElement>) => {
		try {
			setIsLive(!isLive);
			const res = await switchBusinessDomain(event.target.checked ? 'live' : 'test');
			dispatchBusiness(await fetchBusinessAction());
			setSuccess(res.message);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setIsLive(activeBusiness?.domain === 'live' ? true : false);
	}, [activeBusiness]);

	const handleLogout = () => {
		dispatch({ type: authActions.LOGOUT });
	};

	return (
		<div className="d-flex align-items-stretch flex-shrink-0">
			{/* Search */}
			<div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
				{/* <Search /> */}
			</div>

			{/* Test/Live  */}
			<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
				{/* begin::Menu wrapper */}

				<label className="form-check form-check-custom form-check-solid form-switch">
					<span className="form-check-label text-muted mx-2">Test</span>
					<input
						className="form-check-input px-2"
						type="checkbox"
						name="test-live"
						checked={isLive}
						onChange={handleSwitchDomain}
					/>
					<span className="form-check-label text-muted">Live</span>
				</label>
				{/* end::Menu wrapper */}
			</div>

			{/* begin::User */}
			<div
				className={clsx(`d-flex align-items-center `, toolbarButtonMarginClass)}
				id="kt_header_user_menu_toggle"
			>
				{/* begin::Toggle */}
				<div
					// className={clsx(`cursor-pointer symbol-con `, toolbarUserAvatarHeightClass)}
					className="d-flex align-items-center flex-grow-1 flex-lg-grow-1"
					data-kt-menu-trigger="click"
					data-kt-menu-attach="parent"
					data-kt-menu-placement="bottom-end"
					data-kt-menu-flip="bottom"
				>
					<img
						className="h-40px"
						src={toAbsoluteUrl('/media/logos/usecoins.png')}
						alt="usecoins"
					/>
				</div>
				{/* Begin HeaderUserMenu */}
				<div
					className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px`}
					data-kt-menu="true"
				>
					<div className="menu-item px-3">
						<div className="menu-content d-flex align-items-center px-3">
							<div className="d-flex align-items-center mx-3">
								<img
									className="h-40px"
									src={toAbsoluteUrl('/media/logos/usecoins.png')}
									alt="usecoins"
								/>
							</div>

							<div className="d-flex flex-column">
								<div className="fw-bolder d-flex align-items-center fs-5">
									{currentUser?.firstname} {currentUser?.lastname}
								</div>
								<span className="fw-bold text-muted text-hover-primary fs-7">
									{currentUser?.email}
								</span>
							</div>
						</div>
					</div>

					<div className="separator my-2"></div>

					<div className="menu-item px-5">
						<Link to={'/settings/personal'} className="menu-link px-5">
							My Profile
						</Link>
					</div>

					<div className="menu-item px-5">
						<a href="#" className="menu-link px-5">
							Support
						</a>
					</div>

					<div className="separator my-2"></div>

					<div className="menu-item px-5 my-1">
						<Link to={'/settings/business'} className="menu-link px-5">
							Business Settings
						</Link>
					</div>

					<div className="menu-item px-5">
						<a onClick={handleLogout} className="menu-link px-5">
							Sign Out
						</a>
					</div>
				</div>
				{/* end::Toggle */}
			</div>
			{/* end::User */}
		</div>
	);
};

export { Topbar };
