import React, { useContext, useEffect, useState } from 'react';
import { FormInput, RenderSvg } from '../../components';
import { LayoutContext } from '../../layouts';
import { createApiKey, getApiKey, updateWebhook } from '../../services/internal/business.service';
import { BusinessStateContext } from '../../store/Business/business.provider';
import { useFormik } from 'formik';
import { initialValues, modifiedValues } from './Business';
import { hasOwnProperty, titleCase } from '../../helpers/utils';
import { UpdateBusinessType } from '../../types/IBusiness';

const ApiWebhook: React.FC = () => {
	const copyToClipboard = (e: React.MouseEvent) => {
		const text = e.currentTarget.getAttribute('data-clipboard-text');
		if (text) {
			navigator.clipboard.writeText(text);
			setSuccess('Copied to clipboard');
		}
	};

	const [privateK, showPrivateKey] = useState(false);
	const [publicK, showPublicKey] = useState(false);

	const handleShowSecretKey = () => {
		showPrivateKey(!privateK);
	};
	const handleShowPublicKey = () => {
		showPublicKey(!publicK);
	};

	const { setError, setSuccess } = useContext(LayoutContext);
	const { activeBusiness } = useContext(BusinessStateContext);

	const [publicKey, setpublicKey] = React.useState<string>('');
	const [privateKey, setPrivateKey] = React.useState<string>('');

	const handleCreateApiKey = async () => {
		try {
			const createdKeyResponse = await createApiKey();
			setSuccess(createdKeyResponse.message);
			handleGetApiKey();
		} catch (error: any) {
			setError(error?.response?.message || error?.message || 'Something went wrong');
		}
	};

	if (activeBusiness) {
		Object.keys(activeBusiness).forEach((string) => {
			if (
				hasOwnProperty(initialValues, string) &&
				hasOwnProperty(activeBusiness, string) &&
				activeBusiness[string] !== null
			) {
				initialValues[string] = activeBusiness[string];
			}
		});
	}

	const handleGetApiKey = async () => {
		try {
			const apiKey = await getApiKey();
			apiKey.data.filter((key: any) => {
				if (key.domain === 'live' && activeBusiness?.domain === 'live') {
					setpublicKey(key.public_key);
					setPrivateKey(key.private_key);
					return;
				}
				if (key.domain === 'test' && activeBusiness?.domain === 'test') {
					setpublicKey(key.public_key);
					setPrivateKey(key.private_key);
					return;
				}
			});
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Something went wrong');
		}
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				const hasChanged = modifiedValues(initialValues, values);
				if (Object.keys(hasChanged).length > 0) {
					await updateWebhook(hasChanged as UpdateBusinessType);
					setSuccess('Webhook url updated successfully');
					setSubmitting(false);
					return;
				}
				setError('No changes were made');
			} catch (error: any) {
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	useEffect(() => {
		handleGetApiKey();
	}, [activeBusiness]);

	return (
		<div className="card mb-5 mb-xl-10">
			<div className="card-header border-0 cursor-pointer">
				<div className="card-title m-0">
					<h3 className="fw-bolder m-0">API & Webhook</h3>
				</div>
			</div>

			<div id="kt_account_profile_details" className="collapse show">
				<form className="form" onSubmit={formik.handleSubmit}>
					<div className="card-body border-top p-9">
						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								Public Key
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-11 col-lg-11 fv-row position-relative">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
											readOnly={true}
											value={
												publicK
													? publicKey
													: '**************************************************'
											}
										/>

										<div className="position-absolute translate-middle-y top-50 end-0 me-3">
											<button
												type="button"
												className="btn btn-icon  bg-gray-300"
												onClick={handleShowPublicKey}
											>
												<i className="bi bi-eye fs-1"></i>
											</button>
										</div>
									</div>
									<div className="col-1 col-lg-1 d-flex align-items-center">
										<button
											type="button"
											data-clipboard-text={publicKey}
											className="btn btn-icon"
											onClick={copyToClipboard}
										>
											<RenderSvg
												path="/media/icons/copy.svg"
												className="svg-icon-hx"
												svgClassName="h-25px w-25px"
											/>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label fw-bold fs-6">
								Secret Key
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-11 col-lg-11 fv-row position-relative">
										<FormInput
											type="text"
											className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
											readOnly={true}
											value={
												privateK
													? privateKey
													: '**************************************************'
											}
										/>

										<div className="position-absolute translate-middle-y top-50 end-0 me-3">
											<button
												type="button"
												className="btn btn-icon bg-gray-300"
												onClick={handleShowSecretKey}
											>
												<i className="bi bi-eye fs-1"></i>
											</button>
										</div>
									</div>
									<div className="col-1 col-lg-1 d-flex align-items-center">
										<button
											type="button"
											data-clipboard-text={privateKey}
											className="btn btn-icon"
											onClick={copyToClipboard}
										>
											<RenderSvg
												path="/media/icons/copy.svg"
												className="svg-icon-hx"
												svgClassName="h-25px w-25px"
											/>
										</button>
									</div>

									<div className="d-flex align-items-center my-3">
										<a
											role="button"
											className="link-primary fw-bolder"
											onClick={handleCreateApiKey}
										>
											Generate New Secret Key
											<i
												className="fas fa-exclamation-circle ms-2 fs-7"
												data-bs-toggle="tooltip"
												title="Provide your team size to help us setup your billing"
											></i>
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="row mb-6">
							<label className="col-lg-4 col-form-label required fw-bold fs-6">
								Webhook URL
							</label>

							<div className="col-lg-8">
								<div className="row">
									<div className="col-lg-12 fv-row">
										<FormInput
											type="url"
											className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
											{...formik.getFieldProps('test_webhook_url')}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="card-footer d-flex justify-content-end py-6 px-9">
						<button
							type="submit"
							className="btn btn-primary"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							{!formik.isSubmitting && (
								<span className="indicator-label">Submit</span>
							)}
							{formik.isSubmitting && (
								<span className="indicator-progress" style={{ display: 'block' }}>
									Please wait...{' '}
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ApiWebhook;
