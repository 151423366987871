import { usecoinsApi } from '../../services/http';
import { APP_ENDPOINTS } from '../../constants/endpoints';

export const listNetworks = async (coin?: string, domain?: string) => {
	try {
		const url = `${APP_ENDPOINTS.NETWORKS}?${coin ? 'coin=' + coin : ''}${
			domain ? '&domain=' + domain : ''
		}`;
		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error) {
		throw error;
	}
};

export const listBanks = async () => {
	try {
		const { data } = await usecoinsApi.get(APP_ENDPOINTS.BANKS);
		return data;
	} catch (error) {
		throw error;
	}
};
