import { usecoinsApi } from '../../services/http';
import { WALLET_ENDPOINTS } from '../../constants/endpoints';

export const listWallets = async () => {
	try {
		const { data } = await usecoinsApi.get(WALLET_ENDPOINTS.WALLET);
		return data;
	} catch (error) {
		throw error;
	}
};

export const findWallets = async (coin: string) => {
	try {
		const { data } = await usecoinsApi.get(`${WALLET_ENDPOINTS.WALLET}/${coin}`);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};
