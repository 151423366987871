import { usecoinsApi } from '../../services/http';
import { INVOICES_ENDPOINTS } from '../../constants/endpoints';
import { UpdateInvoiceType, ICreateInvoice } from '../../types/IInvoice';
import { IPageQuery } from '../../types/_model';
import { buildPageQuery } from '../../helpers/utils';

export const findInvoice = async (invoiceUuid: string) => {
	try {
		const { data } = await usecoinsApi.get(`${INVOICES_ENDPOINTS.INVOICES}/${invoiceUuid}`);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const createInvoice = async (payload?: ICreateInvoice) => {
	try {
		const { data } = await usecoinsApi.post(INVOICES_ENDPOINTS.INVOICES, payload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const updateInvoice = async (invoicePayload: UpdateInvoiceType) => {
	try {
		const { data } = await usecoinsApi.put(INVOICES_ENDPOINTS.INVOICES, invoicePayload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const deleteInvoice = async (id: number | string) => {
	try {
		const { data } = await usecoinsApi.delete(`${INVOICES_ENDPOINTS.INVOICES}/${id}`);
		return data;
	} catch (error) {
		throw error;
	}
};

export const listInvoices = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${INVOICES_ENDPOINTS.INVOICES}${buildPageQuery(pageQuery)}`;
		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};
