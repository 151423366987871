/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from './ts';

type Props = {
	className: string;
	chartColor: string;
	chartHeight: string;
	chartData: Array<{ x: string; y: number }>;
	handleChangePeriod: (query?: Record<string, any>) => void;
};

const MixedWidget11: React.FC<Props> = ({
	className,
	chartColor,
	chartHeight,
	chartData,
	handleChangePeriod,
}) => {
	const chartRef = useRef<HTMLDivElement | null>(null);
	const [period, setPeriod] = useState<string>('year');
	const handleChangeChartPeriod = (e: any, query?: Record<string, any>) => {
		handleChangePeriod(query);
		setPeriod(e.target.dataset.period);
	};

	useEffect(() => {
		if (!chartRef.current) {
			return;
		}

		const chart = new ApexCharts(
			chartRef.current,
			chartOptions(chartColor, chartHeight, chartData)
		);
		if (chart) {
			chart.render();
		}

		return () => {
			if (chart) {
				chart.destroy();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartRef, chartData]);

	return (
		<div className={`card ${className}`}>
			{/* begin::Body */}
			<div className="card-body p-0 d-flex justify-content-between flex-column overflow-hidden">
				{/* begin::Hidden */}
				{/* <div className="d-flex flex-wrap flex-grow-1 px-9 pt-7 pb-3">card-header border-0 pt-5 */}
				<div className="card-header border-0">
					<div className="me-2">
						<span className="fw-bolder text-gray-800 d-block fs-3">
							Transaction Overview
						</span>
					</div>

					<div className="card-toolbar" data-kt-buttons="true">
						<button
							className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
								period === 'year' && 'active'
							} px-4 me-1`}
							id="kt_charts_widget_2_year_btn"
							data-period="year"
							onClick={(e) => handleChangeChartPeriod(e, { months: 11 })}
						>
							Year
						</button>
						<button
							className={`btn btn-sm btn-color-muted btn-active btn-active-primary ${
								period === 'month' && 'active'
							} px-4 me-1`}
							data-period="month"
							id="kt_charts_widget_2_month_btn"
							onClick={(e) => handleChangeChartPeriod(e, { months: 6 })}
						>
							Month
						</button>
						<button
							className={`btn btn-sm btn-color-muted btn-active ${
								period === 'week' && 'active'
							} btn-active-primary px-4`}
							data-period="week"
							id="kt_charts_widget_2_week_btn"
							onClick={(e) => handleChangeChartPeriod(e, { days: 7 })}
						>
							Week
						</button>
					</div>
				</div>
				{/* end::Hidden */}

				{/* begin::Chart */}
				<div ref={chartRef} className="mixed-widget-10-chart overflow-scroll"></div>
				{/* end::Chart */}
			</div>
		</div>
	);
};

const chartOptions = (
	chartColor: string,
	chartHeight: string,
	chartData: Array<{ x: string; y: number }>
): ApexOptions => {
	const labelColor = getCSSVariableValue('--bs-gray-500');
	const borderColor = getCSSVariableValue('--bs-gray-200');
	const secondaryColor = getCSSVariableValue('--bs-gray-300');
	const baseColor = getCSSVariableValue('--bs-' + chartColor);

	return {
		series: [
			{
				data: chartData,
			},
		],

		chart: {
			fontFamily: 'inherit',
			type: 'bar',
			height: chartHeight,
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '50%',
				borderRadius: 5,
			},
		},
		legend: {
			show: false,
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent'],
		},
		xaxis: {
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
			},
			labels: {
				style: {
					colors: labelColor,
					fontSize: '12px',
				},
			},
		},
		yaxis: {
			labels: {
				style: {
					colors: labelColor,
					fontSize: '12px',
				},
			},
		},
		fill: {
			type: 'solid',
		},
		states: {
			normal: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			hover: {
				filter: {
					type: 'none',
					value: 0,
				},
			},
			active: {
				allowMultipleDataPointsSelection: false,
				filter: {
					type: 'none',
					value: 0,
				},
			},
		},
		tooltip: {
			style: {
				fontSize: '12px',
			},
			y: {
				formatter: function (val) {
					return '$' + val + ' collection';
				},
			},
		},
		colors: [baseColor, secondaryColor],
		grid: {
			padding: {
				top: 10,
			},
			borderColor: borderColor,
			strokeDashArray: 4,
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
	};
};

export { MixedWidget11 };
