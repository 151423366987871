import React, { useContext, useEffect } from 'react';
import { PageLink, PageTitle } from '../../layouts';
import { TableProvider } from '../../components';
import SimpleTable from '../../components/table/SimpleTable2';
import { ViewCollectionModal } from './ViewCollectionModal';
import { ActionType } from '../../types/IAuth';
import {
	CollectionDispatchContext,
	CollectionStateContext,
} from '../../store/Collection/collection.provider';
import {
	collectionLoadingAction,
	listCollectionsAction,
} from '../../store/Collection/collection.actions';
import { ICollection, IPageQuery } from '../../types/_model';
import Pagination from '../../components/Pagination';

const collectionBreadCrumbs: Array<PageLink> = [
	{
		title: 'Collections Management',
		path: '/collections',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

export const formatCollections = (collections: ICollection[]) => {
	if (collections.length <= 0) return [];
	return collections.map((collection: ICollection) => {
		return {
			reference: collection.reference,
			email: collection.customer.email,
			amount: collection.amount_payable_fiat,
			status: collection.status,
			created_at: collection.created_at,
			amount_payable_coin: `${
				collection.amount_payable_coin ? collection.amount_payable_coin : '..'
			} ${collection.payment_currency ? collection.payment_currency : '..'}`,
			uuid: collection.uuid,
		};
	});
};

const Collections: React.FC = () => {
	const { loading, collections, meta } = useContext(CollectionStateContext);

	const dispatch = useContext(CollectionDispatchContext) as React.Dispatch<ActionType>;

	const dispatchCollections = async (pageQuery?: IPageQuery) => {
		try {
			dispatch(collectionLoadingAction(true));
			dispatch(await listCollectionsAction(pageQuery));
			dispatch(collectionLoadingAction(false));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Collection Fatal error');
		}
	};

	useEffect(() => {
		dispatchCollections();
	}, []);

	return (
		<TableProvider>
			<PageTitle breadcrumbs={collectionBreadCrumbs}>Collections</PageTitle>
			<SimpleTable
				tableData={formatCollections(collections)}
				enableButton={true}
				columns={['reference', 'customer', 'amount', 'status', 'created_at']}
				loading={loading}
				dispatchAction={dispatchCollections}
			></SimpleTable>
			{meta && <Pagination {...meta} dispatchAction={dispatchCollections} />}
			<ViewCollectionModal />
		</TableProvider>
	);
};

export default Collections;
