import { ActionType } from '../../types/IAuth';
import * as InvoiceService from '../../services/internal/invoice.service';
import { ICreateInvoice, UpdateInvoiceType } from '../../types/IInvoice';
import { IPageQuery } from '../../types/_model';

export const invoiceActions = {
	FIND_INVOICE: 'FIND_INVOICE',
	CREATE_INVOICE: 'CREATE_INVOICE',
	UPDATE_INVOICE: 'UPDATE_INVOICE',
	DELETE_INVOICE: 'DELETE_INVOICE',
	LIST_INVOICES: 'LIST_INVOICES',
	INVOICE_LOADING: 'INVOICE_LOADING',
	INVOICE_ERROR: 'INVOICE_ERROR',
};

export const findInvoiceAction = async (invoiceUuid: string): Promise<ActionType> => {
	try {
		const invoices = await InvoiceService.findInvoice(invoiceUuid);
		return {
			type: invoiceActions.FIND_INVOICE,
			payload: { ...invoices.data },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const createInvoiceAction = async (invoicePayload?: ICreateInvoice): Promise<ActionType> => {
	try {
		const createInvoice = await InvoiceService.createInvoice(invoicePayload);
		return {
			type: invoiceActions.CREATE_INVOICE,
			payload: { ...createInvoice?.data },
		};
	} catch (error: any) {
		throw error;
	}
};

export const updateInvoiceAction = async (
	invoicePayload: UpdateInvoiceType
): Promise<ActionType> => {
	try {
		const updatedInvoice = await InvoiceService.updateInvoice(invoicePayload);
		return {
			type: invoiceActions.UPDATE_INVOICE,
			payload: { ...updatedInvoice?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const deleteInvoiceAction = async (invoicePayload: number | string): Promise<ActionType> => {
	try {
		const deleteInvoice = await InvoiceService.deleteInvoice(invoicePayload);
		return {
			type: invoiceActions.DELETE_INVOICE,
			payload: { ...deleteInvoice?.data },
		};
	} catch (error: any) {
		console.error(error);
		throw error;
	}
};

export const listInvoicesAction = async (pageQuery?: IPageQuery): Promise<ActionType> => {
	try {
		const invoices = await InvoiceService.listInvoices(pageQuery);
		return {
			type: invoiceActions.LIST_INVOICES,
			payload: invoices?.data,
		};
	} catch (error: any) {
		throw error;
	}
};

export const invoiceLoadingAction = (payload: boolean) => {
	return {
		type: invoiceActions.INVOICE_LOADING,
		payload,
	};
};
