import { useState } from 'react';
import Button from '../../../components/button/Button';
import InvoiceModal from '../../../components/InvoiceModal/InvoiceModal';
import RenderSvg from '../../../components/renderSvg/RenderSvg';

type Props = {};

const RequestButtonComp = (props: Props) => {
	const [showModal, setShowModel] = useState(false);

	const handleRequestInvoiceModal = (show: boolean) => {
		setShowModel(show);
		console.log('modal open');
	};
	return (
		<div>
			<Button
				onClick={handleRequestInvoiceModal}
				type="button"
				className="btn btn-light-primary me-3"
			>
				<RenderSvg path="/media/icons/export.svg" className="svg-icon-2" />
				Request a Payment
			</Button>
			<InvoiceModal show={showModal} triggerModel={handleRequestInvoiceModal} />
		</div>
	);
};

export default RequestButtonComp;
