import { PageLink, PageTitle } from '../../layouts';
import SimpleTable from '../../components/table/SimpleTable2';
import { TableProvider } from '../../components/table/TableProvider';
import { ViewCustomerModal } from './ViewCustomer';
import { useContext, useEffect } from 'react';
import {
	CustomerDispatchContext,
	CustomerStateContext,
} from '../../store/Customer/customer.provider';
import { ActionType } from '../../types/IAuth';
import { customerLoadingAction, listCustomersAction } from '../../store/Customer/customer.actions';
import { ICustomer, IPageQuery } from '../../types/_model';
import Pagination from '../../components/Pagination';

const accountBreadCrumbs: Array<PageLink> = [
	{
		title: 'Customers Management',
		path: '/customers',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const formatCustomers = (customers: ICustomer[]) => {
	if (customers.length <= 0) return [];
	return customers.map((customer: ICustomer) => {
		return {
			reference: customer.uuid,
			email: customer.email,
			firstname: customer.firstname,
			lastname: customer.lastname,
			status: customer.status,
			created_at: customer.created_at,
			id: customer.id,
		};
	});
};

const Customers = () => {
	const { loading, customers, meta } = useContext(CustomerStateContext);

	const dispatch = useContext(CustomerDispatchContext) as React.Dispatch<ActionType>;

	const dispatchCustomers = async (pageQuery?: IPageQuery) => {
		try {
			dispatch(customerLoadingAction(true));
			dispatch(await listCustomersAction(pageQuery));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Customer Fatal error');
			dispatch(customerLoadingAction(false));
		}
	};

	useEffect(() => {
		dispatchCustomers();
	}, []);

	return (
		<TableProvider>
			<PageTitle breadcrumbs={accountBreadCrumbs}>Customers List</PageTitle>
			<SimpleTable
				dispatchAction={dispatchCustomers}
				tableData={formatCustomers(customers)}
				enableButton={true}
				columns={['reference', 'email', 'firstName', 'lastName', 'status']}
				loading={loading}
			></SimpleTable>
			{meta && <Pagination {...meta} dispatchAction={dispatchCustomers} />}
			<ViewCustomerModal />
		</TableProvider>
	);
};

export default Customers;
