import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { SETTLEMENT_INITIAL_STATE, settlementReducer } from './settlement.reducer';

export const SettlementStateContext = createContext({ ...SETTLEMENT_INITIAL_STATE });
export const SettlementDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const SettlementProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(settlementReducer, SETTLEMENT_INITIAL_STATE);

	return (
		<SettlementDispatchContext.Provider value={dispatch}>
			<SettlementStateContext.Provider value={state}>
				{children}
			</SettlementStateContext.Provider>
		</SettlementDispatchContext.Provider>
	);
};
