/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { toAbsoluteUrl } from '../../helpers/utils';
import * as ROUTES from '../../constants/routes';
import { useContext } from 'react';
import { AuthStateContext } from '../../store/Auth/auth.provider';

const SettingsHeader = () => {
	const location = useLocation();
	const { user } = useContext(AuthStateContext);

	return (
		<div className="card mb-5 mb-xl-10">
			<div className="card-body pt-9 pb-0">
				<div className="d-flex flex-wrap flex-sm-nowrap mb-3">
					<div className="me-7 mb-4">
						<div className="d-flex align-items-center flex-grow-1 flex-lg-grow-1">
							<img
								src={toAbsoluteUrl('/media/logos/usecoins.png')}
								alt="business_logo"
								className='w-100px'
							/>
							<div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
						</div>
					</div>

					<div className="flex-grow-1">
						<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
							<div className="d-flex flex-column">
								<div className="d-flex align-items-center mb-2">
									<a
										href="#"
										className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
									>
										{user?.firstname} {user?.lastname}
									</a>
								</div>
							</div>
						</div>

						<div className="d-flex flex-wrap flex-stack">
							<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
								<div className="d-flex justify-content-between w-100 mt-auto mb-2">
									<span className="fw-bold fs-6 text-gray-400">
										Profile Compleation
									</span>
									<span className="fw-bolder fs-6">60%</span>
								</div>
								<div className="h-5px mx-3 w-100 bg-light mb-3">
									<div
										className="bg-success rounded h-5px"
										role="progressbar"
										style={{ width: '60%' }}
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="d-flex overflow-auto h-55px">
					<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.PERSONAL &&
										'active')
								}
								to={ROUTES.PERSONAL}
							>
								Personal
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.BUSINESS &&
										'active')
								}
								to={ROUTES.BUSINESS}
							>
								Business
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.PAYMENT &&
										'active')
								}
								to={ROUTES.PAYMENT}
							>
								Payment
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.PREFERENCES &&
										'active')
								}
								to={ROUTES.PREFERENCES}
							>
								Preferences
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.TEAM && 'active')
								}
								to={ROUTES.TEAM}
							>
								Team
							</Link>
						</li>

						<li className="nav-item">
							<Link
								className={
									`nav-link text-active-primary me-6 ` +
									(location.pathname === `/settings/` + ROUTES.APIWEBHOOK &&
										'active')
								}
								to={ROUTES.APIWEBHOOK}
							>
								API & Webhook
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export { SettingsHeader };
