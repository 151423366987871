import React from 'react';
import { capitalizeFirstLetter, getStatusColor, moneyFormat } from '../helpers/utils';

export const formatTableValue = (key: string, value: string | number) => {
	switch (key) {
		case 'status':
			return (
				<span className={`badge badge-${getStatusColor(value)}`}>
					{capitalizeFirstLetter(value + '')}
				</span>
			);
		case 'amount':
			return <span>{moneyFormat(value)}</span>;
		default:
			return <span>{value}</span>;
	}
};
