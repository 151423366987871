import { ActionType, LoginType } from '../../types/IAuth';
import * as CollectionService from '../../services/internal/collection.service';
import { IPageQuery } from '../../types/_model';

export const collectionActions = {
	LIST_COLLECTIONS: 'LIST_COLLECTIONS',
	FIND_COLLECTION: 'FIND_COLLECTION',
	COLLECTION_LOADING: 'COLLECTION_LOADING',
	COLLECTION_ERROR: 'COLLECTION_ERROR',
};

export const listCollectionsAction = async (pageQuery?: IPageQuery): Promise<ActionType> => {
	try {
		const collections = await CollectionService.listCollections(pageQuery);
		return {
			type: collectionActions.LIST_COLLECTIONS,
			payload: collections?.data,
		};
	} catch (error: any) {
		throw error;
	}
};

export const findCollectionAction = async (collectionId: string): Promise<ActionType> => {
	try {
		const collections = await CollectionService.findCollection(collectionId);
		return {
			type: collectionActions.FIND_COLLECTION,
			payload: { ...collections.data },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};
export const collectionLoadingAction = (payload: boolean) => {
	return {
		type: collectionActions.COLLECTION_LOADING,
		payload,
	};
};
