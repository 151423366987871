import React, { useContext, useEffect, useState } from 'react';
import { Alert as BsALert } from 'react-bootstrap';
import { authActions } from '../store/Auth/auth.actions';
import { AuthDispatchContext } from '../store/Auth/auth.provider';
import RenderSvg from './renderSvg/RenderSvg';

export interface AlertModel {
	title: string;
	type: 'danger' | 'warning' | 'success' | 'info';
	description?: string;
	seconds?: number;
}

const Alert: React.FC<AlertModel> = ({ title, type, description, seconds }) => {
	const [show, setShow] = useState(true);

	const dispatch = useContext(AuthDispatchContext);

	useEffect(() => {
		setTimeout(
			() => {
				setShow(false);
				dispatch({ type: authActions.GLOBAL_ERROR, payload: undefined });
				dispatch({ type: authActions.GLOBAL_SUCCESS, payload: undefined });
			},
			seconds ? seconds : 3000
		);
	}, [show]);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
		setShow(true);
	}, [title]);

	return (
		<BsALert
			variant={type}
			className="d-flex align-items-center p-5 my-5"
			onClose={() => setShow(false)}
			show={show}
			dismissible
		>
			<RenderSvg
				path={`/media/icons/alert-${type !== 'success' ? 'warning' : 'success'}.svg`}
				className={`svg-icon svg-icon-2hx svg-icon-${type} me-3`}
			/>
			<div className="d-flex flex-column">
				<h4 className="mb-1 text-dark">{title}</h4>
				{description && <span className="mb-1">{description}</span>}
			</div>
		</BsALert>
	);
};

export default Alert;
