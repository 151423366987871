import { ActionType } from '../../types/IAuth';
import { ICustomer, IPageMeta } from '../../types/_model';
import { customerActions } from './customer.actions';

export type CustomerType = {
	customers: ICustomer[];
	customer?: ICustomer | undefined | null;
	meta?: IPageMeta;
	loading: boolean;
	error: boolean;
};

export const CUSTOMER_INITIAL_STATE: CustomerType = {
	customers: [],
	customer: undefined,
	meta: undefined,
	loading: false,
	error: false,
};

export const customerReducer = (state = CUSTOMER_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case customerActions.LISTCUSOMERS:
			return {
				...state,
				customers: action.payload.data,
				meta: action.payload.meta,
				loading: false,
			};
		case customerActions.FINDCUSTOMER:
			return {
				...state,
				customer: { ...state.customer, ...action.payload },
				loading: false,
			};
		case customerActions.CUSTOMER_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case customerActions.CUSTOMER_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
