import React, { useState } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import * as AuthService from '../../../services/internal/auth.service';

const initialValues = {
	email: '',
};

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
});

const ForgotPassword: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			setHasErrors(undefined);

			try {
				const { message } = await AuthService.requestReset({
					email: values.email,
				});
				setHasErrors(false);
				setStatus(message);
				setLoading(false);
			} catch (error: any) {
				setHasErrors(true);
				setLoading(false);
				setSubmitting(false);
				setStatus(error?.message || 'error occurred');
			}
			// setTimeout(() => {
			//   requestPassword(values.email)
			//     .then(({ data: { result } }) => {
			//       setHasErrors(false);
			//       setLoading(false);
			//     })
			//     .catch(() => {
			//       setHasErrors(true);
			//       setLoading(false);
			//       setSubmitting(false);
			//       setStatus('The login detail is incorrect');
			//     });
			// }, 1000);
		},
	});

	return (
		<div className="w-lg-500px p-10 p-lg-15 mx-auto">
			<form
				className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
				noValidate
				id="kt_login_password_reset_form"
				onSubmit={formik.handleSubmit}
			>
				<div className="text-center mb-10">
					{/* begin::Title */}
					<h1 className="text-dark mb-3">Forgot Password ?</h1>
					{/* end::Title */}
				</div>

				{/* begin::Title */}
				{hasErrors === true && (
					<div className="mb-lg-15 alert alert-danger">
						<div className="alert-text font-weight-bold">
							{formik.status ||
								'Sorry, looks like there are some errors detected, please try again.'}
						</div>
					</div>
				)}

				{hasErrors === false && (
					<div className="mb-10 bg-light-info p-8 rounded">
						<div className="text-info">
							{formik.status || 'Sent password reset. Please check your email'}
						</div>
					</div>
				)}
				{/* end::Title */}

				{/* begin::Form group */}
				<div className="fv-row mb-10">
					<label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
					<input
						type="email"
						placeholder=""
						autoComplete="off"
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.email && formik.errors.email },
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
					/>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="d-flex flex-wrap justify-content-center pb-lg-0">
					<button
						type="submit"
						id="kt_password_reset_submit"
						className="btn btn-lg btn-primary fw-bolder me-4"
					>
						<span className="indicator-label">Submit</span>
						{loading && (
							<span className="indicator-progress">
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>
				</div>
				{/* end::Form group */}
			</form>
		</div>
	);
};

export default ForgotPassword;
