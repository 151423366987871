/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useLayout } from '../index';
import { FormInput, FormSelect, RenderSvg } from '../../components';
import { AsideMenu } from './AsideMenu';
import { AsideBusinessDropdown } from './business-header/AsideBusinessDropdown';
import {
	BusinessDispatchContext,
	BusinessStateContext,
} from '../../store/Business/business.provider';
import { Modal, Button as BtsButton } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionType } from '../../types/IAuth';
import {
	createBusinessAction,
	fetchAllBusinessAction,
	switchBusinessAction,
} from '../../store/Business/business.actions';
import { phoneRegExp } from '../../views/auth/register/Register';
import { IBusiness } from '../../types/IBusiness';
import * as ROUTES from '../../constants/routes';
import {
	disableBusinessSwitchSplashScreen,
	enableBusinessSwitchSplashScreen,
	LayoutContext,
} from '../LayoutProvider';
import { IWallet } from '../../types/_model';
import { listWallets } from '../../services/internal/wallet.service';
import { authActions } from '../../store/Auth/auth.actions';
import { AuthDispatchContext } from '../../store/Auth/auth.provider';

const initialValues = {
	business_name: '',
	// business_category: '',
	business_size: '',
	contact_phone: '',
	contact_email: '',
	website_url: '<yourwebsite.com>',
	default_currency: 'NGN',
	accepted_tokens: [],
};

const businessSchema = Yup.object().shape({
	business_name: Yup.string().required('Business name is required'),
	// business_category: Yup.string().required('Business category is required'),
	contact_phone: Yup.string()
		.matches(phoneRegExp, 'Business Phone number is not valid')
		.required('Phone number is required'),
	business_size: Yup.string().required(),
	contact_email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),

	default_currency: Yup.string().required(),
});

const AsideDefault: FC = () => {
	const { classes } = useLayout();
	const { activeBusiness, allBusiness } = useContext(BusinessStateContext);

	const navigate = useNavigate();
	const { setError, setSuccess } = useContext(LayoutContext);

	const dispatch = useContext(BusinessDispatchContext) as React.Dispatch<ActionType>;

	const authDispatch = useContext(AuthDispatchContext) as React.Dispatch<ActionType>;

	const [showModal, setShowModal] = useState(false);
	const [walletFiatBalance, setWalletFiatBalance] = React.useState<IWallet[]>([]);

	const wallet_coins = async () => {
		try {
			const wallets = await listWallets();
			setWalletFiatBalance(wallets.data.fiat);
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Wallet Fatal error');
		}
	};
	const handleSetModal = () => {
		setShowModal(!showModal);
		wallet_coins();
	};

	const handleLogout = () => {
		authDispatch({ type: authActions.LOGOUT });
	};

	const handleSwitchBusiness = async (business: IBusiness) => {
		try {
			enableBusinessSwitchSplashScreen(business.business_name);
			dispatch(await switchBusinessAction(business));
			navigate('/');
			disableBusinessSwitchSplashScreen();
		} catch (error) {
			console.error(error);
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: businessSchema,
		onSubmit: async (values, { resetForm, setSubmitting }) => {
			try {
				dispatch(await createBusinessAction(values));
				dispatch(await fetchAllBusinessAction());
				handleSetModal();
				setSuccess('Business created successfully');
				resetForm();
				navigate(`settings/${ROUTES.BUSINESS}`);
			} catch (error) {
				setError('Business creation failed');
				setSubmitting(false);
			}
		},
	});

	const [filteredBusinessName, setFilteredBuisnessName] = React.useState<IBusiness[]>([]);

	useEffect(() => {
		setFilteredBuisnessName(allBusiness);
	}, [allBusiness]);

	//handle search function
	const handleBusinessFilter = (e: any) => {
		const search = e.target.value.toLowerCase();
		const filterBusiness = allBusiness.filter((item) =>
			item.business_name.toLowerCase().includes(search)
		);
		setFilteredBuisnessName(filterBusiness);
	};

	return (
		<div
			id="kt_aside"
			className={clsx('aside aside-dark', classes.aside.join(' '))}
			data-kt-drawer="true"
			data-kt-drawer-name="aside"
			data-kt-drawer-activate="{default: true, lg: false}"
			data-kt-drawer-overlay="true"
			data-kt-drawer-width="{default:'200px', '300px': '250px'}"
			data-kt-drawer-direction="start"
			data-kt-drawer-toggle="#kt_aside_mobile_toggle"
		>
			<AsideBusinessDropdown to={''} business={activeBusiness}>
				<span className={clsx('text-white p-8 fs-6 fw-900')}>
					OTHER BUSINESSES - {allBusiness?.length - 1}
				</span>
				<div className="px-4 align-items-center position-relative">
					<RenderSvg
						path="/media/icons/search.svg"
						className="svg-icon-1 position-absolute ms-2 pt-2"
					/>
					<FormInput
						className="menu-item  border-none outline-none form-control py-2 mb-3 ps-9"
						placeholder="Search"
						type="text"
						onChange={(e: any) => handleBusinessFilter(e)}
					/>
				</div>
				{
					// allBusiness
					filteredBusinessName.length ? (
						<>
							{filteredBusinessName
								.filter(
									(data) => data.business_name !== activeBusiness?.business_name
								)
								.map((business) => {
									return (
										<div className="menu-item" key={business.uuid}>
											<Link
												className={clsx('menu-link text-white fs-6')}
												to={'#'}
												onClick={() => handleSwitchBusiness(business)}
											>
												<span className="menu-title">
													{business.business_name}
												</span>
											</Link>
										</div>
									);
								})}
						</>
					) : (
						<div className="menu-item">
							<span className="menu-title text-red menu-link">No match found!</span>
						</div>
					)
				}
				<div className="separator px-0 py-0"></div>

				<div className="menu-item">
					<div className={clsx('menu-link text-white fs-6')}>
						{/* <span className="menu-icon">
							<RenderSvg path="/media/icons/plus.svg" className="svg-icon-2" />
						</span> */}
						<span onClick={handleSetModal} className="menu-title">
							{'Add A Business'}
						</span>
					</div>
					<div className="separator mx-1 my-1"></div>
				</div>
				<div className="menu-item">
					<div className={clsx('menu-link text-white fs-6')}>
						<span onClick={handleLogout} className="menu-title">
							{'Sign Out'}
						</span>
					</div>
				</div>
			</AsideBusinessDropdown>

			{/* end::Brand */}

			{/* begin::Aside menu */}
			<div className="aside-menu flex-column-fluid">
				<AsideMenu asideMenuCSSClasses={classes.asideMenu} />
			</div>
			{/* end::Aside menu */}

			{/* New Business modal */}
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Create New Business</Modal.Title>
					<button
						className="btn btn-icon btn-sm btn-active-icon-primary"
						onClick={handleSetModal}
					>
						<RenderSvg path="/media/icons/arrows/arr061.svg" className="svg-icon-1" />
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="card mb-xl-10">
						<div className="card-body pt-0 pb-0">
							{/*Role Form */}
							<form
								className={`form w-100 fv-plugins-bootstrap5 fv-plugins-framework `}
								noValidate
								onSubmit={formik.handleSubmit}
								id="business_form"
							>
								<div className="fv-row mb-4">
									<FormInput
										labelName="Business Name"
										labelClass="form-label fs-6 fw-bolder mb-3"
										type="text"
										{...formik.getFieldProps('business_name')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.business_name &&
													formik.errors.business_name,
											},
											{
												'is-valid':
													formik.touched.business_name &&
													!formik.errors.business_name,
											}
										)}
									/>
								</div>

								<div className="fv-row mb-4">
									<FormInput
										labelName="Business Email"
										labelClass="form-label fs-6 fw-bolder mb-3"
										type="text"
										{...formik.getFieldProps('contact_email')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.contact_email &&
													formik.errors.contact_email,
											},
											{
												'is-valid':
													formik.touched.contact_email &&
													!formik.errors.contact_email,
											}
										)}
									/>
								</div>

								<div className="fv-row mb-4">
									<FormInput
										labelName="Contact Phone"
										labelClass="form-label fs-6 fw-bolder mb-3"
										type="text"
										{...formik.getFieldProps('contact_phone')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.contact_phone &&
													formik.errors.contact_phone,
											},
											{
												'is-valid':
													formik.touched.contact_email &&
													!formik.errors.contact_email,
											}
										)}
									/>
								</div>

								{/* <div className="fv-row mb-4">
									<FormInput
										labelName="Business Category"
										labelClass="form-label fs-6 fw-bolder mb-3"
										type="text"
										{...formik.getFieldProps('business_category')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.business_category &&
													formik.errors.business_category,
											},
											{
												'is-valid':
													formik.touched.business_category &&
													!formik.errors.business_category,
											}
										)}
									/>
								</div> */}

								<div className="fv-row mb-4">
									<FormSelect
										labelName="Business Size"
										labelClass="form-label fs-6 fw-bolder mb-3"
										{...formik.getFieldProps('business_size')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.business_size &&
													formik.errors.business_size,
											},
											{
												'is-valid':
													formik.touched.business_size &&
													!formik.errors.business_size,
											}
										)}
									>
										<option value="small">Small </option>
										<option value="medium">Medium</option>
										<option value="large">Large</option>
									</FormSelect>
								</div>

								<div className="fv-row mb-4">
									<FormSelect
										labelName="Default Currency"
										labelClass="form-label fs-6 fw-bolder mb-3"
										{...formik.getFieldProps('default_currency')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.default_currency &&
													formik.errors.default_currency,
											},
											{
												'is-valid':
													formik.touched.default_currency &&
													!formik.errors.default_currency,
											}
										)}
									>
										<option value="">Default Currency </option>
										{walletFiatBalance.map((currency) => (
											<option key={currency.id} value={currency.code}>
												{currency.code}
											</option>
										))}
									</FormSelect>
								</div>
							</form>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<BtsButton variant="secondary" onClick={handleSetModal}>
						Close
					</BtsButton>

					<BtsButton
						form="business_form"
						type="submit"
						variant="primary"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!formik.isSubmitting && <span className="indicator-label">Submit</span>}
						{formik.isSubmitting && (
							<span className="indicator-progress" style={{ display: 'block' }}>
								Please wait...{' '}
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</BtsButton>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export { AsideDefault };
