import { useContext, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AsideDefault } from './aside/AsideDefault';
import { Footer } from './Footer';
import { HeaderWrapper } from './header/HeaderWrapper';
import { Toolbar } from './Toolbar';
import { Content } from './Content';
import { PageDataProvider } from './index';
import { MenuComponent } from '../components/ts';
import { BusinessProvider } from '../store/Business/business.provider';
import Alert from '../components/Alert';
import { AuthStateContext } from '../store/Auth/auth.provider';

const MasterLayout = () => {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization();
		}, 500);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization();
		}, 500);
	}, [location.key]);

	const { global_error, global_success } = useContext(AuthStateContext);

	return (
		<BusinessProvider>
			<PageDataProvider>
				<div className="page d-flex flex-row flex-column-fluid">
					<AsideDefault />
					<div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
						<HeaderWrapper />

						<div
							id="kt_content"
							className="content d-flex flex-column flex-column-fluid"
						>
							<Toolbar />
							<div className="post d-flex flex-column-fluid" id="kt_post">
								<Content>
									{global_error && <Alert title={global_error} type="danger" />}
									{global_success && (
										<Alert title={global_success} type="success" />
									)}
									<Outlet />
								</Content>
							</div>
						</div>
						<Footer />
					</div>
				</div>
			</PageDataProvider>
		</BusinessProvider>
	);
};

export { MasterLayout };
