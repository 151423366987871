import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { acceptInviteGuest, verifyInvite } from '../../../services/internal/businessUser.service';
import { useLocation, useNavigate } from 'react-router';
import clsx from 'clsx';
import { LayoutContext } from '../../../layouts';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PasswordMeterComponent } from '../../../components/ts';
import { phoneRegExp } from '../register/Register';
import * as ROUTES from '../../../constants/routes';
import { titleCase } from '../../../helpers/utils';
import { Button, FormInput, FormSelect } from '../../../components';
import { IVerfiyInvite } from '../../../types/_model';

const initialValues = {
	firstname: '',
	lastname: '',
	password: '',
	phone: '',
	token: '',
};

const registrationSchema = Yup.object().shape({
	firstname: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('First name is required'),

	lastname: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Last name is required'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),

	phone: Yup.string()
		.matches(phoneRegExp, 'Phone number is not valid')
		.required('Phone number is required'),
});

const VerifyInvite: React.FC = () => {
	const location = useLocation();

	const [data, setData] = useState<IVerfiyInvite>();
	const { token } = useParams();
	const verifyId = token + location.search;

	const { setError, setSuccess } = useContext(LayoutContext);

	const handleVerifyInvite = async () => {
		try {
			const verify: any = await verifyInvite(verifyId);
			setData(verify.data);
			formik.setFieldValue('token', token);
		} catch (error: any) {
			setError(error.response.data.message);
			console.error(error.response.data.message);
		}
	};

	let navigate = useNavigate();

	const formik = useFormik({
		initialValues,
		validationSchema: registrationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				await acceptInviteGuest(values);

				setSubmitting(false);
				setSuccess('Invitation accepted successfully please login');
				navigate(`/${ROUTES.LOGIN}`);
			} catch (error: any) {
				console.error(error);
				setError(
					error.response.data.errors
						? `${error.response.data.errors[0].message} for ${titleCase(
								error.response.data.errors[0].field
						  )}`
						: 'Something went wrong'
				);
				setSubmitting(false);
			}
		},
	});

	const [showPassword, setShowPassword] = useState(false);
	const handleSetShowPassword = () => {
		setShowPassword(!showPassword);
	};

	useEffect(() => {
		handleVerifyInvite();
	}, []);

	useEffect(() => {
		PasswordMeterComponent.bootstrap();
	}, [data]);

	return (
		<React.Fragment>
			{data && Object.keys(data).length !== 0 ? (
				<React.Fragment>
					<div className="w-lg-600px p-10 p-lg-15 mx-auto">
						<form
							className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
							noValidate
							id="kt_sign_up_form"
							onSubmit={formik.handleSubmit}
						>
							{/* begin::Heading */}
							<div className="mb-10 text-center">
								{/* begin::Title */}
								<h1 className="text-dark mb-3">
									Create an Account to Accept Invite
								</h1>
								{/* end::Title */}
							</div>
							{/* end::Heading */}

							{/* begin::Form group firstname */}
							<div className="row fv-row mb-7">
								<div className="col-xl-6">
									<FormInput
										labelName="First Name"
										labelClass="form-label fw-bolder text-dark fs-6"
										placeholder="First Name"
										type="text"
										autoComplete="off"
										{...formik.getFieldProps('firstname')}
										className={clsx(
											'form-control form-control-lg form-control-solid',
											{
												'is-invalid':
													formik.touched.firstname &&
													formik.errors.firstname,
											},
											{
												'is-valid':
													formik.touched.firstname &&
													!formik.errors.firstname,
											}
										)}
									/>
									{formik.touched.firstname && formik.errors.firstname && (
										<div className="fv-plugins-message-container">
											<div className="fv-help-block">
												<span role="alert">{formik.errors.firstname}</span>
											</div>
										</div>
									)}
								</div>
								<div className="col-xl-6">
									{/* begin::Form group lastname */}
									<div className="fv-row mb-5">
										<FormInput
											labelName="Last Name"
											labelClass="form-label fw-bolder text-dark fs-6"
											placeholder="Last Name"
											type="text"
											autoComplete="off"
											{...formik.getFieldProps('lastname')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid':
														formik.touched.lastname &&
														formik.errors.lastname,
												},
												{
													'is-valid':
														formik.touched.lastname &&
														!formik.errors.lastname,
												}
											)}
										/>
										{formik.touched.lastname && formik.errors.lastname && (
											<div className="fv-plugins-message-container">
												<div className="fv-help-block">
													<span role="alert">
														{formik.errors.lastname}
													</span>
												</div>
											</div>
										)}
									</div>
									{/* end::Form group */}
								</div>
							</div>
							{/* end::Form group */}

							{/* begin::Form group Email */}
							<div className="fv-row mb-7">
								<FormInput
									labelName="Email"
									labelClass="form-label fw-bolder text-dark fs-6"
									readOnly
									value={data.email}
									className={clsx(
										'form-control form-control-lg form-control-solid'
									)}
								/>
							</div>
							{/* end::Form group */}

							{/* begin::Form group Phone */}
							<div className="fv-row mb-7">
								<FormInput
									labelName="Phone Number"
									labelClass="form-label fw-bolder text-dark fs-6"
									placeholder="Phone Number"
									type="tel"
									autoComplete="off"
									{...formik.getFieldProps('phone')}
									className={clsx(
										'form-control form-control-lg form-control-solid',
										{
											'is-invalid':
												formik.touched.phone && formik.errors.phone,
										},
										{
											'is-valid':
												formik.touched.phone && !formik.errors.phone,
										}
									)}
								/>
								{formik.touched.phone && formik.errors.phone && (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block">
											<span role="alert">{formik.errors.phone}</span>
										</div>
									</div>
								)}
							</div>

							{/* begin::Form group Password */}
							<div className="mb-10 fv-row" data-kt-password-meter="true">
								<div className="mb-1">
									<div className="position-relative mb-3">
										<FormInput
											labelName="Password"
											labelClass="form-label fw-bolder text-dark fs-6"
											type={showPassword ? 'text' : 'password'}
											placeholder="Password"
											autoComplete="off"
											{...formik.getFieldProps('password')}
											className={clsx(
												'form-control form-control-lg form-control-solid',
												{
													'is-invalid':
														formik.touched.password &&
														formik.errors.password,
												},
												{
													'is-valid':
														formik.touched.password &&
														!formik.errors.password,
												}
											)}
										/>
										<div
											style={{ transform: 'translateY(-100%)' }}
											className="position-absolute  end-0 me-9"
										>
											<button
												className="btn btn-icon "
												onClick={handleSetShowPassword}
												type="button"
											>
												{showPassword ? (
													<i className="bi bi-eye-slash fs-1"></i>
												) : (
													<i className="bi bi-eye fs-1"></i>
												)}
											</button>
										</div>
										{formik.touched.password && formik.errors.password && (
											<div className="fv-plugins-message-container ">
												<div className="fv-help-block">
													<span role="alert">
														{formik.errors.password}
													</span>
												</div>
											</div>
										)}
									</div>

									{/* begin::Meter */}
									<div
										className="d-flex align-items-center mb-3"
										data-kt-password-meter-control="highlight"
									>
										<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
										<div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
									</div>
									{/* end::Meter */}
								</div>
								<div className="text-muted">
									Use 8 or more characters with a mix of letters, numbers &
									symbols.
								</div>
							</div>

							<div className="text-center">
								<Button
									type="submit"
									id="kt_sign_up_submit"
									className="btn btn-lg btn-primary w-100 mb-5"
									disabled={formik.isSubmitting || !formik.isValid}
								>
									{!formik.isSubmitting && (
										<span className="indicator-label">Submit</span>
									)}
									{formik.isSubmitting && (
										<span
											className="indicator-progress"
											style={{ display: 'block' }}
										>
											Please wait...{' '}
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
										</span>
									)}
								</Button>
								<Link to={`/auth/${ROUTES.LOGIN}`}>
									<Button
										type="button"
										id="kt_login_signup_form_cancel_button"
										className="btn btn-lg btn-light-primary w-100 mb-5"
									>
										Cancel
									</Button>
								</Link>
							</div>
						</form>
					</div>
				</React.Fragment>
			) : (
				// TODO: redirect to home page/ button to home page
				<></>
			)}
		</React.Fragment>

		// Accept Invite
	);
};

export default VerifyInvite;

//     <div className="w-lg-500px p-10 p-lg-15 mx-auto">n
//     <div className="pt-lg-10 mb-10 text-center">
//         <h2 className="fw-bolder fs-2qx text-gray-800 mb-7 text-center">
//             Accept Invite
//         </h2>
//         <div className="fs-3 fw-bold text-muted mb-10">
//             We have sent an email to &nbsp;
//             <Link to="#" className="link-primary fw-bolder">
//                 {user.email}
//             </Link>
//             <br />
//             pelase click to accept invite
//         </div>

//         <div className="fs-5">
//             <span className="fw-bold text-gray-700">Did’t receive an email?</span> &nbsp;
//             <Link to="#" className="link-primary fw-bolder">
//                 Resend
//             </Link>
//         </div>
//     </div>
// </div>
//   )
// }
