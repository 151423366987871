import { ActionType, LoginType } from '../../types/IAuth';
import * as AuthService from '../../services/internal/auth.service';
import { getAuthUser } from '../../services/internal/auth';
import { UserModel } from '../../types/_model';

export const authActions = {
	LOGIN: 'LOGIN',
	REGISTER: 'REGISTER',
	AUTH_LOADING: 'AUTH_LOADING',
	AUTH_ERROR: 'AUTH_ERROR',
	AUTH_USER: 'AUTH_USER',
	GLOBAL_ERROR: 'GLOBAL_ERROR',
	GLOBAL_SUCCESS: 'GLOBAL_SUCCESS',
	LOGOUT: 'LOGOUT',
};

export const loginAction = async (payload: LoginType): Promise<ActionType> => {
	try {
		const { email, password } = payload;
		const newLogin = await AuthService.login({ email, password });
		return {
			type: authActions.LOGIN,
			payload: { ...newLogin.data, email },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const authLoadingAction = (payload: boolean) => {
	return {
		type: authActions.AUTH_LOADING,
		payload,
	};
};

export const getAuthUserAction = async (): Promise<ActionType> => {
	try {
		const userResponse = await getAuthUser();
		return {
			type: authActions.AUTH_USER,
			payload: userResponse.data.data,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const UpdateUserAction = async (userPayload: UserModel): Promise<ActionType> => {
	try {
		const userResponse = await AuthService.updateUser(userPayload);
		return {
			type: authActions.AUTH_USER,
			payload: userResponse.data,
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};
