import React from 'react';
import { PageLink, PageTitle } from '../../layouts';
import SimpleTable from '../../components/table/SimpleTable';
import { TableProvider } from '../../components/table/TableProvider';
import { Card, Search, CardBody } from '../../components';
import { ViewCoinTransferModal } from './ViewCoinTransferModal';
import { CoinTransferData } from '../../store/TableData';

const accountBreadCrumbs: Array<PageLink> = [
	{
		title: 'Transfers',
		path: '/transfers/coin',
		isSeparator: false,
		isActive: true,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const TransferCoin: React.FC = () => {
	return (
		<TableProvider>
			<PageTitle breadcrumbs={accountBreadCrumbs}>Coin Transfers</PageTitle>
			<Card>
				<div className="card-header border-0 justify-content-end">
					<Search placeholder="Search Coin Transfers" />
				</div>

				<CardBody>
					<SimpleTable
						tableData={CoinTransferData}
						totalColumns={7}
						enableButton={true}
					/>
				</CardBody>
			</Card>
			<ViewCoinTransferModal />
		</TableProvider>
	);
};

export default TransferCoin;
