import { usecoinsApi } from '../../services/http';
import { SETTLEMENT_ENDPOINTS } from '../../constants/endpoints';
import { buildPageQuery } from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';

export const listSettlements = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${SETTLEMENT_ENDPOINTS.SETTLEMENT}${buildPageQuery(pageQuery)}`;
		const { data } = await usecoinsApi.get(url);
		return data.data;
	} catch (error) {
		throw error;
	}
};

export const findSettlement = async (settlementId: string) => {
	try {
		const { data } = await usecoinsApi.get(
			`${SETTLEMENT_ENDPOINTS.SETTLEMENT}/${settlementId}`
		);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};
