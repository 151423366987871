import React, { useCallback, useContext, useEffect } from 'react';
import { PageLink, PageTitle } from '../../layouts';
import * as ROUTES from '../../constants/routes';
import { InvoiceDispatchContext, InvoiceStateContext } from '../../store/Invoice/invoice.provider';
import { ActionType } from '../../types/IAuth';
import { listInvoicesAction, invoiceLoadingAction } from '../../store/Invoice/invoice.actions';
import { TableProvider } from '../../components';
import InvoiceTable from '../../components/table/InvoiceTable';
import { IPageQuery } from '../../types/_model';
import { IInvoice } from '../../types/IInvoice';
import Pagination from '../../components/Pagination';
import { ViewInvoiceModal } from './ViewInvoiceModal';
import {
	CustomerDispatchContext,
	CustomerStateContext,
} from '../../store/Customer/customer.provider';
import { customerLoadingAction, listCustomersAction } from '../../store/Customer/customer.actions';
import { ICustomer } from '../../store/TableData';

const invoiceBreadCrumbs: Array<PageLink> = [
	{
		title: 'Invoice Management',
		path: ROUTES.INVOICES,
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const formatInvoices = (invoiceData: IInvoice[], customers: ICustomer[]) => {
	const InvoiceList = Array.isArray(invoiceData)
		? invoiceData?.map((invoice: IInvoice) => {
				const customerEmail = Array.isArray(customers)
					? customers.map((customer: ICustomer) => {
							if (customer?.id === invoice?.customer_id) {
								return customer?.email;
							}
					  })
					: [];
				return {
					reference: invoice?.reference,
					amount: invoice?.amount,
					customer: customerEmail,
					status: invoice?.status,
					invoice_no: invoice?.invoice_no,
					created_at: invoice?.created_at,
					currency: invoice?.currency_id,
					id: invoice?.id,
					due_date: invoice?.due_date,
				};
		  })
		: [];
	return InvoiceList;
};

const Invoice: React.FC = () => {
	// const [customerId, setCustomerId] = useState(0);
	const { loading, invoices, meta } = useContext(InvoiceStateContext);

	const dispatch = useContext(InvoiceDispatchContext) as React.Dispatch<ActionType>;

	const dispatchInvoices = React.useCallback(
		async (pageQuery?: IPageQuery) => {
			try {
				dispatch(invoiceLoadingAction(true));
				dispatch(await listInvoicesAction(pageQuery));
				dispatch(invoiceLoadingAction(false));
			} catch (error: any) {
				console.log(error?.response?.message || error?.message || 'Invoice Fatal error');
				dispatch(invoiceLoadingAction(false));
			}
		},
		[dispatch]
	);

	useEffect(() => {
		dispatchInvoices();
	}, [dispatchInvoices]);

	const Dispatch = useContext(CustomerDispatchContext) as React.Dispatch<ActionType>;
	const { customers } = useContext(CustomerStateContext);
	const dispatchFindCustomer = useCallback(
		async (pageQuery?: IPageQuery) => {
			try {
				Dispatch(customerLoadingAction(true));
				Dispatch(await listCustomersAction(pageQuery));
				Dispatch(customerLoadingAction(false));
			} catch (error: any) {
				console.log(error?.response?.message || error?.message || 'Customer Fatal error');
				Dispatch(customerLoadingAction(false));
			}
		},
		[Dispatch]
	);

	useEffect(() => {
		dispatchFindCustomer();
	}, [dispatchFindCustomer]);

	return (
		<TableProvider>
			<PageTitle breadcrumbs={invoiceBreadCrumbs}>Invoices List</PageTitle>
			<InvoiceTable
				tableData={formatInvoices(invoices, customers)}
				enableButton={true}
				columns={['reference', 'amount', 'customer', 'status', 'Invoice No.', 'created at']}
				loading={loading}
				dispatchAction={dispatchInvoices}
			></InvoiceTable>
			{meta && <Pagination {...meta} dispatchAction={dispatchInvoices} />}
			<ViewInvoiceModal />
		</TableProvider>
	);
};

export default Invoice;
