import { uuid4 } from '@sentry/utils';
export interface CollectionModel {
	id: number;
	reference: string;
	customer_email: string;
	transaction_type: string;
	amount: number;
	address: string;
	channel: string;
	status: string;
	created_at: string;
	from: string;
	to: string;
	tx_hash: string;
}

export interface SettlementModel {
	id: number;
	reference: string;
	amount: number;
	status: string;
	date: string;
	currency: string;
	fee: number;
	settled_by: string;
}

export interface IFiatTransfer {
	id: number;
	reference: string;
	amount: number;
	transaction_type: string;
	from: string;
	to: string;
	status: string;
	date: string;
}

export interface ICoinTransfer {
	id: number;
	reference: string;
	coin: string;
	transaction_type: string;
	from: string;
	to: string;
	status: string;
	date: string;
}

export type ICustomer = {
	id: number;
	name?: string;
	email?: string;
	phone?: string;
	position?: string;
	role?: string;
	last_login?: string;
	two_steps?: boolean;
	joined_day?: string;
	online?: boolean;
};

export type IAudit = {
	id: number;
	time: string;
	activity: string;
	description: string;
	name: string;
	role: string;
	email: string;
	ipAddress: string;
};

export type ITeamMember = {
	name: string;
	email: string;
	role: string;
	last_login: string;
	status: string;
};

export type IBalance = {
	name: string;
	symbol: string;
	icon?: string;
	balance: string;
	currency?: string;
};

export type IbusinessData = {
	id: number;
	business_name: string;
};

export const SettlementData: SettlementModel[] = [
	{
		id: 1,
		reference: uuid4(),
		amount: 10000,
		status: 'pending',
		date: '2020-01-01',
		currency: 'BTC',
		fee: 0,
		settled_by: 'admin@usecoins.io',
	},
	{
		id: 2,
		reference: uuid4(),
		amount: 20000,
		status: 'failed',
		date: '2020-01-01',
		currency: 'BTC',
		fee: 0,
		settled_by: 'admin@usecoins.io',
	},
	{
		id: 3,
		reference: uuid4(),
		amount: 3000,
		status: 'canceled',
		date: '2020-01-01',
		currency: 'BTC',
		fee: 0,
		settled_by: 'UseCoins',
	},
	{
		id: 4,
		reference: uuid4(),
		amount: 40000,
		status: 'success',
		date: '2020-01-01',
		currency: 'BTC',
		fee: 0,
		settled_by: 'Admin',
	},
];

export const CollectionTableData = [
	{
		id: 1,
		reference: uuid4(),
		customer_email: 'Johndoe@usecoins.io',
		transaction_type: 'debit',
		amount: 2000,
		address: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		channel: 'Binance',
		status: 'pending',
		created_at: '2020-01-22',
		from: 'brad@example.com',
		to: 'usecoins@hotmail.com',
		tx_hash: uuid4(),
	},

	{
		id: 2,
		reference: uuid4(),
		customer_email: 'grams@gmail.com',
		transaction_type: 'credit',
		amount: 5600,
		address: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		channel: 'Online',
		status: 'success',
		created_at: '2020-06-01',
		from: 'hela@example.com',
		to: 'doe@hotmail.com',
		tx_hash: uuid4(),
	},

	{
		id: 3,
		reference: uuid4(),
		customer_email: 'heike@hotmail.com',
		transaction_type: 'debit',
		amount: 2000,
		address: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		channel: 'stripe',
		status: 'pending',
		created_at: '2020-01-03',
		from: 'cus@example.com',
		to: 'usecoins@hotmail.com',
		tx_hash: uuid4(),
	},

	{
		id: 4,
		reference: uuid4(),
		customer_email: 'addin@tech.com',
		transaction_type: 'credit',
		amount: 8700,
		address: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		channel: 'Card',
		status: 'success',
		created_at: '2020-01-01',
		from: 'kiny@example.com',
		to: 'test@hotmail.com',
		tx_hash: uuid4(),
	},
];

export const CustomerData: ICustomer[] = [
	{
		id: 1,
		name: 'Emma Smith',
		email: 'smith@kpmg.com',
		phone: '070862222222',
	},
	{
		id: 2,
		name: 'Melody Macy',
		email: 'melody@altbox.com',
		phone: '070862222222',
	},
	{
		id: 3,
		name: 'Max Smith',
		email: 'max@kt.com',
		phone: '070862222222',
	},
	{
		id: 4,
		name: 'Sean Bean',
		email: 'sean@dellito.com',
		phone: '070862222222',
	},
	{
		id: 5,
		name: 'Brian Cox',
		email: 'brian@exchange.com',
		phone: '070862222222',
	},
	{
		id: 6,
		name: 'Mikaela Collins',
		email: 'mik@pex.com',
		phone: '070862222222',
	},
	{
		id: 7,
		name: 'Francis Mitcham',
		email: 'f.mit@kpmg.com',
		phone: '070862222222',
	},
	{
		id: 8,
		name: 'Olivia Wild',
		email: 'olivia@corpmail.com',
		phone: '070862222222',
	},
	{
		id: 9,
		name: 'Neil Owen',
		email: 'owen.neil@gmail.com',
		phone: '070862222222',
	},
	{
		id: 10,
		name: 'Dan Wilson',
		email: 'dam@consilting.com',
		phone: '070862222222',
	},
];

export const FiatTransferData: IFiatTransfer[] = [
	{
		id: 1,
		reference: uuid4(),
		amount: 2000,
		transaction_type: 'transfer',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0xssfs58B726830A2E222f9b139E90483A37142bcBE5',
		status: 'pending',
		date: '2020-01-22',
	},
	{
		id: 2,
		reference: uuid4(),
		amount: 32000,
		transaction_type: 'refund',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x358B726830A2E222f9b139E90483A37142bcB211',
		status: 'success',
		date: '2020-01-22',
	},
	{
		id: 3,
		reference: uuid4(),
		amount: 2000,
		transaction_type: 'settlement',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		status: 'pending',
		date: '2020-01-22',
	},
	{
		id: 4,
		reference: uuid4(),
		amount: 2000,
		transaction_type: 'transfer',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		status: 'success',
		date: '2020-01-22',
	},
];

export const CoinTransferData: ICoinTransfer[] = [
	{
		id: 1,
		reference: uuid4(),
		coin: '0.000432 BTC',
		transaction_type: 'transfer',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0xssfs58B726830A2E222f9b139E90483A37142bcBE5',
		status: 'pending',
		date: '2020-01-22',
	},
	{
		id: 2,
		reference: uuid4(),
		coin: '27.99 LTC',
		transaction_type: 'refund',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x7358B726830A2E222f9b139E90483A37142bcB211',
		status: 'success',
		date: '2020-01-22',
	},
	{
		id: 3,
		reference: uuid4(),
		coin: '0.047 BNB',
		transaction_type: 'settlement',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		status: 'pending',
		date: '2020-01-22',
	},
	{
		id: 4,
		reference: uuid4(),
		coin: '0.65 USDT',
		transaction_type: 'transfer',
		from: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		to: '0x7358B726830A2E222f9b139E90483A37142bcBE5',
		status: 'success',
		date: '2020-01-22',
	},
];

export const AuditData: IAudit[] = [
	{
		id: 1,
		time: '7 Dec, 2021 -  7:12 am',
		activity: 'Christian Invited Team Member',
		description: 'The team member description',
		name: 'Christian Osueke',
		role: 'Admin',
		email: 'christian.osueke@gmail.com',
		ipAddress: '13.45.444.12',
	},
	{
		id: 2,
		time: '7 Dec, 2021 -  7:12 am',
		activity: 'Chisom Invited Team Member',
		description: 'The team member description',
		name: 'Chisom Mkparu',
		role: 'Finance',
		email: 'chisom.mkparu@gmail.com',
		ipAddress: '15.45.444.12',
	},
	{
		id: 3,
		time: '7 Dec, 2021 -  7:12 am',
		activity: 'Donchi Invited Team Member',
		description: 'The team member description',
		name: 'Don Chi',
		role: 'Admin',
		email: 'donchi@gmail.com',
		ipAddress: '12.45.444.12',
	},
];

export const TeamMemberData: ITeamMember[] = [
	{
		name: 'John team',
		role: 'Admin',
		email: 'usecoins@gmail.com',
		last_login: '7 Dec, 202',
		status: 'active',
	},
	{
		name: 'Member two',
		role: 'Finance',
		email: 'member2@gmail.com',
		last_login: '7 Dec, 202',
		status: 'active',
	},
	{
		name: 'Member one',
		role: 'Admin',
		email: 'member3@gmail.com',
		last_login: '7 Dec, 202',
		status: 'active',
	},
];

export const BalanceFiatData: IBalance[] = [
	{
		name: 'Nigeria naira',
		symbol: 'NGN',
		icon: 'svg/coins/binance.svg',
		balance: '100000.00',
	},
	{
		name: 'United State Dollar',
		symbol: 'AED',
		icon: 'svg/coins/bitcoin.svg',
		balance: '10000.00',
	},
];

export const BalanceCurrencyData: IBalance[] = [
	{
		name: 'Alpha Finance',
		symbol: 'ALPHA',
		icon: 'svg/coins/bitcoin.svg',
		balance: '0.100000',
		currency: 'NGN 10,000.00',
	},
	{
		name: 'Alpine F1',
		symbol: 'ALPINE',
		icon: 'svg/coins/ethereum.svg',
		balance: '0.000000',
		currency: 'NGN 0000',
	},
	{
		name: 'FileCoin',
		symbol: 'F Coin',
		icon: 'svg/coins/filecoin.svg',
		balance: '0.000000',
		currency: 'NGN 0000',
	},
	{
		name: 'Binance',
		symbol: 'BNB',
		icon: 'svg/coins/binance.svg',
		balance: '0.000000',
		currency: 'NGN 0000',
	},
	{
		name: 'FileCoin',
		symbol: 'F Coin',
		icon: 'svg/coins/filecoin.svg',
		balance: '0.000000',
		currency: 'NGN 0000',
	},
	{
		name: 'Alpha Finance',
		symbol: 'ALPHA',
		icon: 'svg/coins/bitcoin.svg',
		balance: '0.100000',
		currency: 'NGN 10,000.00',
	},
];

export const Businessdata: IbusinessData[] = [
	{
		id: 0,
		business_name: 'Crest',
	},
	{
		id: 1,
		business_name: 'Foods)',
	},
];
