import { usecoinsApi } from '../../services/http';
import { LoginType, RequestResetType, ResetPasswordType, VerifyTokenType } from '../../types/IAuth';
import { AUTH_ENDPOINTS } from '../../constants/endpoints';
import { IPasswordUpdate, UserModel } from '../../types/_model';

export const login = async (credentials: LoginType) => {
	try {
		const { data } = await usecoinsApi.post(AUTH_ENDPOINTS.LOGIN, credentials);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const requestReset = async (email: RequestResetType) => {
	try {
		const { data } = await usecoinsApi.post(AUTH_ENDPOINTS.REQUEST_RESET, email);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const verifyToken = async (tokenParam: VerifyTokenType) => {
	try {
		const { data } = await usecoinsApi.get(
			`${AUTH_ENDPOINTS.VERIFY_TOKEN}/${tokenParam.token}?signature=${tokenParam.signature}`
		);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const resetReset = async (resetpasswordParam: ResetPasswordType) => {
	try {
		const { data } = await usecoinsApi.put(AUTH_ENDPOINTS.RESET_PASSWORD, resetpasswordParam);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const updateUser = async (userPayload: UserModel) => {
	try {
		const { data } = await usecoinsApi.put(AUTH_ENDPOINTS.UPDATE_USER, userPayload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const updatePassword = async (passwordPayload: IPasswordUpdate) => {
	try {
		const { data } = await usecoinsApi.put(AUTH_ENDPOINTS.UPDATE_PASSWORD, passwordPayload);
		return data;
	} catch (error) {
		throw error;
	}
};
