import React, { useContext } from 'react';
import {
	capitalizeFirstLetter,
	moneyFormat,
	titleCase,
	truncate,
	getStatusColor,
	truncateCurrencyAddress,
} from '../../helpers/utils';
import { TableContext } from './TableProvider';

type PropsType = {
	tableData: any[];
	totalColumns: number; // total columns minus id and button;
	columns?: Array<string>;
	tdClass?: string;
	enableButton?: boolean;
	children?: any;
};

const SimpleTable = ({
	tableData,
	totalColumns,
	columns,
	tdClass,
	enableButton,
	children,
}: PropsType) => {
	const { handleSetModal, handleSetActiveTable } = useContext(TableContext);
	const handleOpenModalSetTable = (tableId: string) => {
		handleSetModal();
		handleSetActiveTable(tableId);
	};

	const showButton = enableButton ? enableButton : false;

	const column =
		columns && columns.length > 0
			? columns
			: tableData.length > 0
			? Object.keys(tableData[0])
			: [];
	const ThData = () => {
		return column.map((data, index) => {
			return index < totalColumns ? (
				<th className="min-w-120px" key={data}>
					{titleCase(data)}
				</th>
			) : null;
		});
	};

	return (
		<div className="table-responsive">
			{/* begin::Table */}
			<table className="table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3">
				{/* begin::Table head */}
				<thead>
					<tr className="fw-bolder text-muted">
						{ThData()}
						{showButton ? <th className="min-w-100px text-end">Actions</th> : <></>}
					</tr>
				</thead>
				{/* end::Table head */}
				{/* begin::Table body */}
				<tbody>
					{children
						? children
						: tableData.map((data, index) => {
								return (
									<tr key={index}>
										{Object.entries(data!).map(
											([key, value]: any, indexData) => {
												if (indexData > totalColumns - 1) {
													return null;
												}
												if (key === 'status') {
													return (
														<td key={key}>
															<span
																className={`badge badge-light-${getStatusColor(
																	value
																)}`}
															>
																{capitalizeFirstLetter(value)}
															</span>
														</td>
													);
												}
												if (key === 'amount') {
													return (
														<td key={key}>
															<span className="text-dark d-block mb-1 fs-6">
																{moneyFormat(value)}
															</span>
														</td>
													);
												}
												if (key === 'permissions') {
													return (
														<td className="text-end" key={index}>
															<button
																onClick={() =>
																	handleOpenModalSetTable(value)
																}
																className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
															>
																View Permissions
															</button>
														</td>
													);
												}
												if (key === 'from' || key === 'to') {
													return (
														<td className="text-dark fs-6" key={key}>
															{truncateCurrencyAddress(value)}
														</td>
													);
												}
												return (
													<td
														className={`text - dark fs-6 ${tdClass}`}
														key={key}
													>
														{key === 'reference'
															? truncate(value, 15)
															: value}
													</td>
												);
											}
										)}
										{showButton ? (
											<td className="text-end" key={index}>
												<button
													onClick={() => handleOpenModalSetTable(data.id)}
													className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
												>
													View
												</button>
											</td>
										) : (
											<></>
										)}
									</tr>
								);
						  })}
				</tbody>
			</table>
		</div>
	);
};

export default SimpleTable;
