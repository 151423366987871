import React, { createContext, useContext, useState, useEffect } from 'react';
import { DefaultLayoutConfig } from './DefaultLayoutConfig';
import {
	getEmptyCssClasses,
	getEmptyCSSVariables,
	getEmptyHTMLAttributes,
	LayoutSetup,
} from './LayoutSetup';
import {
	ILayout,
	ILayoutCSSVariables,
	ILayoutCSSClasses,
	ILayoutHTMLAttributes,
} from './LayoutModels';
import { AuthDispatchContext } from '../store/Auth/auth.provider';
import { authActions } from '../store/Auth/auth.actions';

export interface LayoutContextModel {
	config: ILayout;
	classes: ILayoutCSSClasses;
	attributes: ILayoutHTMLAttributes;
	cssVariables: ILayoutCSSVariables;
	setLayout: (config: LayoutSetup) => void;
	setError: (message: string) => void;
	setSuccess: (message: string) => void;
}

const LayoutContext = createContext<LayoutContextModel>({
	config: DefaultLayoutConfig,
	classes: getEmptyCssClasses(),
	attributes: getEmptyHTMLAttributes(),
	cssVariables: getEmptyCSSVariables(),
	setLayout: (config: LayoutSetup) => {},
	setError: (message: string) => {},
	setSuccess: (message: string) => {},
});

export const enableSplashScreen = () => {
	const splashScreen = document.getElementById('splash-screen');
	if (splashScreen) {
		splashScreen.style.setProperty('display', 'flex');
	}
};

export const disableSplashScreen = () => {
	const splashScreen = document.getElementById('splash-screen');
	if (splashScreen) {
		splashScreen.style.setProperty('display', 'none');
	}
};

export const enableBusinessSwitchSplashScreen = (businessName: string) => {
	const splashBusinessScreen = document.getElementById('business-splash-screen');
	const name = document.getElementById('business_name');

	if (splashBusinessScreen) {
		name!.innerText = `Switching to ${businessName}`;
		splashBusinessScreen.style.setProperty('display', 'flex');
	}
};

export const disableBusinessSwitchSplashScreen = () => {
	const splashBusinessScreen = document.getElementById('business-splash-screen');
	if (splashBusinessScreen) {
		splashBusinessScreen.style.setProperty('display', 'none');
	}
};

type props = {
	children: React.ReactNode;
};

const LayoutProvider: React.FC<props> = ({ children }) => {
	const [config, setConfig] = useState(LayoutSetup.config);
	const [classes, setClasses] = useState(LayoutSetup.classes);
	const [attributes, setAttributes] = useState(LayoutSetup.attributes);
	const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables);

	const dispatch = useContext(AuthDispatchContext);

	const setError = (message: string) => {
		dispatch({ type: authActions.GLOBAL_ERROR, payload: message });
	};

	const setSuccess = (message: string) => {
		dispatch({ type: authActions.GLOBAL_SUCCESS, payload: message });
	};

	const setLayout = (_themeConfig: Partial<ILayout>) => {
		enableSplashScreen();
		const bodyClasses = Array.from(document.body.classList);
		bodyClasses.forEach((cl) => document.body.classList.remove(cl));
		LayoutSetup.updatePartialConfig(_themeConfig);
		setConfig(Object.assign({}, LayoutSetup.config));
		setClasses(LayoutSetup.classes);
		setAttributes(LayoutSetup.attributes);
		setCSSVariables(LayoutSetup.cssVariables);
		setTimeout(() => {
			disableSplashScreen();
		}, 500);
	};
	const value: LayoutContextModel = {
		config,
		classes,
		attributes,
		cssVariables,
		setLayout,
		setSuccess,
		setError,
	};

	useEffect(() => {
		disableSplashScreen();
	}, []);
	return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
	return useContext(LayoutContext);
}
