import { ActionType } from '../../types/IAuth';
import { createContext, Dispatch, useReducer } from 'react';
import { ROLE_INITIAL_STATE, roleReducer } from './role.reducer';

export const RoleStateContext = createContext({ ...ROLE_INITIAL_STATE });
export const RoleDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const RoleProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(roleReducer, ROLE_INITIAL_STATE);

	return (
		<RoleDispatchContext.Provider value={dispatch}>
			<RoleStateContext.Provider value={state}>{children}</RoleStateContext.Provider>
		</RoleDispatchContext.Provider>
	);
};
