import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { RenderSvg, Search } from '..';
import {
	capitalizeFirstLetter,
	getStatusColor,
	moneyFormat,
	titleCase,
	toAbsoluteUrl,
	truncate,
	truncateCurrencyAddress,
	toDateFormat,
} from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';
import Button from '../button/Button';
import Card from '../card/Card';
import Filter from '../filter/Filter';
import { TableContext } from './TableProvider';
import { downloadExcel } from 'react-export-table-to-excel';
import { useLocation } from 'react-router-dom';
import RequestInvoice from '../../views/invoice/RequestInvoice/RequestInvoice';
import RequestButtonComp from '../../views/invoice/RequestInvoice/RequestButtonComp';
import { InvoiceDispatchContext, InvoiceStateContext } from '../../store/Invoice/invoice.provider';
import { ActionType } from '../../types/IAuth';
import {
	deleteInvoiceAction,
	invoiceLoadingAction,
	listInvoicesAction,
} from '../../store/Invoice/invoice.actions';
import { LayoutContext } from '../../layouts';
import {
	CustomerDispatchContext,
	CustomerStateContext,
} from '../../store/Customer/customer.provider';
import { customerLoadingAction, findCustomerAction } from '../../store/Customer/customer.actions';

type PropsType = {
	columns: Array<string>;
	tableData: any[];
	children?: any;
	enableButton: boolean;
	className?: string;
	options?: {
		enableSearch?: boolean;
		enableFilter?: boolean;
		enableExport?: boolean;
		enableCreate?: boolean;
		createButtonText?: string;
		searchPlaceholder?: string;
	};
	loading?: boolean;
	dispatchAction?: (pageQuery?: IPageQuery) => Promise<void>;
};

const InvoiceTable = ({
	columns,
	tableData,
	children,
	enableButton,
	className,
	options = {
		enableSearch: true,
		enableFilter: true,
		enableExport: true,
		enableCreate: true,
		createButtonText: 'Create',
	},
	loading,
	dispatchAction,
}: PropsType) => {
	const { handleSetModal, handleSetActiveTable } = useContext(TableContext);
	const { setError, setSuccess } = useContext(LayoutContext);
	const [customerId, setCustomerId] = useState('');
	const handleOpenModalSetTable = (tableId: string) => {
		handleSetModal();
		handleSetActiveTable(tableId);
	};

	const dispatch = useContext(InvoiceDispatchContext) as React.Dispatch<ActionType>;

	const handleDeleteInvoice = async (tableId: string, pageQuery?: IPageQuery) => {
		try {
			await deleteInvoiceAction(tableId);
			setSuccess('Invoice deleted successfully');
			dispatch(invoiceLoadingAction(true));
			dispatch(await listInvoicesAction(pageQuery));
		} catch (error: any) {
			console.error(error);
			setError(
				error.response.data.errors
					? `${error.response.data.errors[0].message} for ${titleCase(
							error.response.data.errors[0].field
					  )}`
					: 'Something went wrong'
			);
		}
	};

	const tableRef = useRef(null);
	const location = useLocation();

	const showButton = enableButton ? enableButton : false;
	const columnData =
		columns && columns.length > 0
			? columns
			: tableData.length > 0
			? Object.keys(tableData[0])
			: [];
	const ThData = () => {
		return columnData?.map((data) => {
			return (
				<th className="min-w-150px" key={data}>
					{titleCase(data)}
				</th>
			);
		});
	};

	const handleDownloadExcel = () => {
		downloadExcel({
			fileName: location.pathname,
			sheet: `${location.pathname} export`,
			tablePayload: {
				header: columnData,
				body: tableData,
			},
		});
	};
	const {
		enableSearch,
		enableFilter,
		enableExport,
		searchPlaceholder,
		enableCreate,
		createButtonText,
	} = options;

	return (
		<Card>
			<div className="card-header border-0">
				{enableSearch && (
					<Search
						dispatchAction={dispatchAction}
						placeholder={searchPlaceholder ? searchPlaceholder : 'Search ....'}
					/>
				)}
				<div className="card-toolbar">
					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						{enableFilter && (
							<Filter isLoading={false} html={''} dispatchAction={dispatchAction} />
						)}
						{enableExport && (
							<Button
								onClick={handleDownloadExcel}
								type="button"
								className="btn btn-light-primary me-3"
							>
								<RenderSvg path="/media/icons/export.svg" className="svg-icon-2" />
								Export
							</Button>
						)}
						{tableData.length > 0 && <RequestButtonComp />}
					</div>
				</div>
			</div>

			<div>
				<div className={`${className ? className : 'table-responsive'}`}>
					{/*begin::Table*/}
					<table
						className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
						ref={tableRef}
					>
						{/*begin::Table head*/}
						<thead className="border-gray-200 fs-5 fw-bold bg-lighten">
							<tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
								{ThData()}
								{showButton ? (
									<th className="min-w-100px text-end">Actions</th>
								) : (
									<></>
								)}
							</tr>
						</thead>
						{/*end::Table head*/}
						{/*begin::Table body*/}
						<tbody className="fw-6 fw-bold text-gray-600">
							{children && children}
							{Object.values(tableData).length > 0 ? (
								!children &&
								tableData?.map((data, index) => {
									return (
										<tr key={index}>
											{Object.entries(data)?.map(
												([key, value]: any, indexData) => {
													if (indexData > columnData.length - 1) {
														return null;
													}
													// if (key === 'status') {
													// 	return (
													// 		<td key={key}>
													// 			<span
													// 				className={`badge badge-light-${getStatusColor(
													// 					value
													// 				)}`}
													// 			>
													// 				{capitalizeFirstLetter(value)}
													// 			</span>
													// 		</td>
													// 	);
													// }

													if (key === 'amount' || key === 'total') {
														return (
															<td key={key}>
																<div className="d-flex">
																	<span className="text-dark d-block mb-1 fs-6">
																		{moneyFormat(value)}
																	</span>
																</div>
															</td>
														);
													}

													if (
														key === 'date' ||
														key === 'created_at' ||
														key === 'updated_at'
													) {
														return (
															<td key={key}>
																<span className="text-dark d-block mb-1 fs-6">
																	{toDateFormat(value)}
																</span>
															</td>
														);
													}
													if (key === 'customer') {
														return (
															<td
																className="text-dark fs-6"
																key={key}
															>
																{value}
															</td>
														);
													}
													return (
														<td
															className={`text-dark fs-6 `}
															title={value}
															key={key}
														>
															{key === 'reference' || key === 'id'
																? truncate(value, 12)
																: value}
														</td>
													);
												}
											)}
											{showButton ? (
												<td className="text-dark fs-6 text-end">
													<button
														onClick={() =>
															handleOpenModalSetTable(
																data?.uuid || data?.id
															)
														}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
													>
														View
													</button>
													<button
														onClick={() =>
															handleDeleteInvoice(
																data?.id || data?.uuid
															)
														}
														className="btn btn-bg-danger btn-color-muted btn-active-color-white btn-sm px-4 me-2"
													>
														Delete
													</button>
												</td>
											) : (
												<></>
											)}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={7}>
										<div className="d-flex text-center w-100 align-content-center justify-content-center">
											{!loading ? (
												<RequestInvoice />
											) : (
												<span
													className="indicator-progress"
													style={{ display: 'block' }}
												>
													Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
												</span>
											)}
										</div>
									</td>
								</tr>
							)}
						</tbody>
						{/*end::Table body*/}
					</table>
					{/*end::Table*/}
				</div>
			</div>
		</Card>
	);
};

export default InvoiceTable;
