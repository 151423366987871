import { usecoinsApi } from '../../services/http';
import { SETTINGS_ENDPOINTS, BUSINESS_ENDPOINTS } from '../../constants/endpoints';
import { CreateBusinessType, UpdateBusinessType } from '../../types/IBusiness';
import { IPageQuery } from '../../types/_model';
import { buildPageQuery } from '../../helpers/utils';

export const createBusiness = async (businessPayload: CreateBusinessType) => {
	try {
		const { data } = await usecoinsApi.post(BUSINESS_ENDPOINTS.BUSINESS, businessPayload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const updateBusiness = async (businessPayload: UpdateBusinessType) => {
	try {
		const { data } = await usecoinsApi.put(BUSINESS_ENDPOINTS.BUSINESS, businessPayload);
		return data;
	} catch (error) {
		throw error;
	}
};

export const fetchBusiness = async () => {
	try {
		const { data } = await usecoinsApi.get(BUSINESS_ENDPOINTS.BUSINESS);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const fetchActiveBusiness = async () => {
	try {
		const { data } = await usecoinsApi.get(`${BUSINESS_ENDPOINTS.BUSINESS}/active`);
		return data;
	} catch (error: any) {
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const switchBusiness = async (businessUuid: string) => {
	try {
		const { data } = await usecoinsApi.post(BUSINESS_ENDPOINTS.SWITCH_BUSINESS, {
			business: businessUuid,
		});
		return data;
	} catch (error: any) {
		console.error(error);
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const switchBusinessDomain = async (domainType: 'test' | 'live') => {
	try {
		const { data } = await usecoinsApi.post(BUSINESS_ENDPOINTS.SWITCH_BUSINESS, {
			domain: domainType,
		});
		return data;
	} catch (error: any) {
		console.error(error);
		const { data } = error.response;
		throw new Error(data?.message || 'fatal error');
	}
};

export const createApiKey = async () => {
	try {
		const { data } = await usecoinsApi.post(SETTINGS_ENDPOINTS.API_KEY);
		return data;
	} catch (error) {
		throw error;
	}
};

export const getApiKey = async () => {
	try {
		const { data } = await usecoinsApi.get(SETTINGS_ENDPOINTS.API_KEY);
		return data;
	} catch (error) {
		throw error;
	}
};

export const updateWebhook = async (url: { test_webhook_url: string | null }) => {
	try {
		const { data } = await usecoinsApi.put(SETTINGS_ENDPOINTS.WEBHOOK, {
			url: url.test_webhook_url,
		});
		return data;
	} catch (error) {
		console.error(error);
		throw error;
	}
};

export const getTeams = async (pageQuery?: IPageQuery) => {
	try {
		const url = `${BUSINESS_ENDPOINTS.BUSINESS}/team${buildPageQuery(pageQuery)}`;
		const { data } = await usecoinsApi.get(url);
		return data;
	} catch (error) {
		throw error;
	}
};
