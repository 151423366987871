export const ROOT = '/*';

export const AUTH_ROOT = 'auth/*';

export const LOGIN = 'login';
export const REGISTER = 'register';
export const FORGOTPASSWORD = 'forgot-password';
export const RESETPASSWORD = 'reset-password/*';

export const HOME = '/home';
export const DASHBOARD = 'dashboard';
export const VERIFYEMAIL = 'verify-email';
export const COLLECTIONS = 'collections';
export const SETTLEMENT = 'settlement';
export const CUSTOMERS = 'customers';
export const TRANSFERS = 'transfers/*';
export const COIN = 'coin';
export const FIAT = 'fiat';
export const AUDIT = 'audit-trails';
export const BALANCE = 'balance';

export const SETTINGS = 'settings/*';
export const BUSINESS = 'business';
export const PERSONAL = 'personal';
export const PAYMENT = 'payment';
export const PREFERENCES = 'preferences';
export const TEAM = 'team';
export const APIWEBHOOK = 'api-webhook';

export const VERIFY_INVITE = 'verify-invite/invitations/:token';

export const APP = '/app';

export const INVOICES = 'invoices';
// export const CREATEINVOICE = 'invoices/:invoiceId';

export const ERRORS = {
	NOT_FOUND: '/errors/404',
};
