import { ActionType, LoginType } from '../../types/IAuth';
import * as CustomerService from '../../services/internal/customer.service';
import { IPageQuery } from '../../types/_model';

export const customerActions = {
	LISTCUSOMERS: 'LISTCUSOMERS',
	FINDCUSTOMER: 'FINDCUSTOMER',
	CUSTOMER_LOADING: 'CUSTOMER_LOADING',
	CUSTOMER_ERROR: 'CUSTOMER_ERROR',
};

export const listCustomersAction = async (pageQuery?: IPageQuery): Promise<ActionType> => {
	try {
		const customers = await CustomerService.listCustomers(pageQuery);
		return {
			type: customerActions.LISTCUSOMERS,
			payload: customers?.data,
		};
	} catch (error: any) {
		throw error;
	}
};

export const findCustomerAction = async (customerId: string): Promise<ActionType> => {
	try {
		const customers = await CustomerService.findCustomer(customerId);
		return {
			type: customerActions.FINDCUSTOMER,
			payload: { ...customers.data },
		};
	} catch (error) {
		console.error(error);
		throw error;
	}
};
export const customerLoadingAction = (payload: boolean) => {
	return {
		type: customerActions.CUSTOMER_LOADING,
		payload,
	};
};
