import { ActionType } from '../../types/IAuth';
import { ICollection, IPageMeta } from '../../types/_model';
import { collectionActions } from './collection.actions';

export type CollectionType = {
	collections: ICollection[];
	collection?: ICollection;
	meta?: IPageMeta;
	loading: boolean;
	error: boolean;
};

export const COLLECTION_INITIAL_STATE: CollectionType = {
	collections: [],
	collection: undefined,
	meta: undefined,
	loading: false,
	error: false,
};

export const collectionReducer = (state = COLLECTION_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case collectionActions.LIST_COLLECTIONS:
			return {
				...state,
				collections: action.payload.data,
				meta: action.payload.meta,
				loading: false,
			};
		case collectionActions.FIND_COLLECTION:
			return {
				...state,
				collection: { ...state.collection, ...action.payload },
				loading: false,
			};
		case collectionActions.COLLECTION_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case collectionActions.COLLECTION_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
