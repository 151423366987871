/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { toAbsoluteUrl } from '../helpers/utils';
import { IAudit } from '../types/_model';
import SVG from 'react-inlinesvg';

type Props = {
	className: string;
	auditData: IAudit[];
};

const ListsWidget5: React.FC<Props> = ({ className, auditData }) => {
	const buildTime = (datetime: string) => {
		let newTime = new Date(datetime);
		return newTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	};

	const computeAuditTimelineColor = (auditType: string) => {
		const auditTypeColorMap: Record<string, string> = {
			BUSINESS_UPDATED: 'text-primary',
			NEW_INVITE: 'text-warning',
			INVITE_ACCEPTED: 'text-success',
			ORDER_CREATED: 'text-warning',
			PAYMENT_RECEIVED: 'text-success'
		}
		return auditTypeColorMap[auditType] || 'text-primary';
	}

	return (
		<div className={`card ${className}`}>
			<div className="card-header align-items-center border-0 mt-4">
				<h3 className="card-title align-items-start flex-column">
					<span className="fw-bolder mb-2 text-dark">Recent Activities</span>
				</h3>
			</div>
			{auditData.length > 0 ? (
				<div className="card-body pt-5 overflow-scroll">
					<div className="timeline-label">
						{auditData.map((item) => (
							<div className="timeline-item" key={item.id}>
								<div className="timeline-label fw-bolder text-gray-800 fs-6">
									{buildTime(item.created_at)}
								</div>
								<div className="timeline-badge">
									<i className={`fa fa-genderless fs-1 ${computeAuditTimelineColor(item.type)}`}></i>
								</div>
								<div className="fw-mormal timeline-content text-muted ps-3">
									{item.action}
								</div>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="card-body text-center pt-5">
					<SVG
						src={toAbsoluteUrl('/media/svg/illustrations/easy/5.svg')}
						className="w-full"
					/>
					<h1 className="text-muted italic" style={{ letterSpacing: '3px' }}>
						No activity{' '}
					</h1>
				</div>
			)}
		</div>
	);
};

export { ListsWidget5 };
