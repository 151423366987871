import { useContext } from 'react';
import { RenderSvg } from '../../components';

import { Button, Modal } from 'react-bootstrap';
import { TableContext } from '../../components/table/TableProvider';
import FormInput from '../../components/form-input/FormInput';
import { titleCase } from '../../helpers/utils';
import {
	SettlementDispatchContext,
	SettlementStateContext,
} from '../../store/Settlement/settlement.provider';
import { ActionType } from '../../types/IAuth';
import {
	settlementLoadingAction,
	findSettlementAction,
} from '../../store/Settlement/settlement.actions';
import { useEffect } from 'react';

const ViewSettlementModal = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);
	const dispatch = useContext(SettlementDispatchContext) as React.Dispatch<ActionType>;
	const { settlement } = useContext(SettlementStateContext);

	const dispatchFindSettlement = async () => {
		try {
			dispatch(settlementLoadingAction(true));
			dispatch(await findSettlementAction(activeTableId));
		} catch (error: any) {
			console.log(error?.response?.message || error?.message || 'Settlement Fatal error');
			dispatch(settlementLoadingAction(false));
		}
	};

	useEffect(() => {
		if (showModal) {
			dispatchFindSettlement();
		}
	}, [showModal]);

	const lastKeyEntry = settlement && Object.keys(settlement).pop();

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Settlement Details</Modal.Title>
					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						<button
							className="btn btn-icon btn-sm btn-active-icon-primary"
							onClick={handleSetModal}
						>
							<RenderSvg
								path="/media/icons/arrows/arr061.svg"
								className="svg-icon-1"
							/>
						</button>
					</div>
				</Modal.Header>

				<Modal.Body>
					<div className="d-flex flex-column scroll-y me-n7 pe-7">
						<div className={`row gy-1`}>
							{/* begin::Input group */}
							{settlement &&
								Object.entries(settlement).map(([key, value], indexData) => {
									if (
										key === 'id' ||
										key === 'updated_at' ||
										key === 'is_fiat' ||
										key === 'business_id'
									) {
										return null;
									}
									return (
										<div className="col-md-6" key={indexData}>
											<div className="d-flex fv-row">
												<div className="form-check form-check-custom form-check-solid">
													<label
														className="form-check-label"
														htmlFor="kt_modal_update_role_option_0"
													>
														<div className="fw-bolder text-gray-800">
															{titleCase(key)}
														</div>
														<div className="text-gray-600">{value}</div>
													</label>
												</div>
											</div>
											<div className="separator separator-dashed my-5"></div>
										</div>
									);
								})}
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export { ViewSettlementModal };
