import { useContext } from 'react';
import { RenderSvg } from '../../components';

import { Button, Modal } from 'react-bootstrap';
import { AuditData, IAudit } from '../../store/TableData';
import { TableContext } from '../../components/table/TableProvider';
import { titleCase } from '../../helpers/utils';

const ViewAudit = () => {
	const { showModal, handleSetModal, activeTableId } = useContext(TableContext);

	const AuditDetail: IAudit | undefined = AuditData.find(
		(Audit) => Audit.id.toString() === activeTableId
	);
	const lastKeyEntry = AuditDetail && Object.keys(AuditDetail).pop();

	return (
		<>
			<Modal show={showModal} size="lg">
				<Modal.Header>
					<Modal.Title>Audit Details</Modal.Title>

					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						{/* begin::Add user */}
						<button
							className="btn btn-icon btn-sm btn-active-icon-primary"
							onClick={handleSetModal}
						>
							<RenderSvg
								path="/media/icons/arrows/arr061.svg"
								className="svg-icon-1"
							/>
						</button>
					</div>
				</Modal.Header>

				<Modal.Body>
					<>
						<div
							className="d-flex flex-column scroll-y me-n7 pe-7"
							id="kt_modal_add_user_scroll"
							data-kt-scroll="true"
							data-kt-scroll-activate="{default: false, lg: true}"
							data-kt-scroll-max-height="auto"
							data-kt-scroll-dependencies="#kt_modal_add_user_header"
							data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
							data-kt-scroll-offset="300px"
						>
							<div className="row gy-1">
								{/* begin::Input group */}
								{Object.entries(AuditDetail!).map(([key, value], indexData) => {
									if (key === 'id') {
										return null;
									}
									return (
										<div className="col-md-6" key={indexData}>
											<div className="d-flex fv-row">
												<div className="form-check form-check-custom form-check-solid">
													<label
														className="form-check-label"
														htmlFor="kt_modal_update_role_option_0"
													>
														<div className="fw-bolder text-gray-800">
															{titleCase(key)}
														</div>
														<div className="text-gray-600">{value}</div>
													</label>
												</div>
											</div>

											{lastKeyEntry === key ? (
												<></>
											) : (
												<div className="separator separator-dashed my-5"></div>
											)}
										</div>
									);
								})}
							</div>
						</div>
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleSetModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ViewAudit;
