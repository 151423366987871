import moment from 'moment';
import { getItem } from './storage';
export * from './storage';

export function toDateFormat(date: string | number | Date, format: string = 'DD MMMM YYYY') {
	return moment(new Date(date)).format(format);
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const moneyFormat = (x: number | string) => {
	const get_currency = getItem('default_currency');
	const default_currency = get_currency === 'NGN' ? '₦' : '$';
	return default_currency + x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Truncates string to the format descrip…
 * @param string
 * @param length
 * @returns Truncated letters
 */

export const truncate = (str: string | undefined, n: number) => {
	if (typeof str === 'string') return str.length > n ? str.substring(0, n - 1) + '...' : str;
	return str;
};

/**
 * Truncates an crypto address to the format 0x0000…0000
 * @param address Full address to truncate
 * @returns Truncated address
 */
const truncateRegex = /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/;
export const truncateCurrencyAddress = (address: string) => {
	const match = address.match(truncateRegex);
	if (!match) return address;
	return `${match[1]}…${match[2]}`;
};

const ETH_TO_DOLLAR_RATE = 0.00041;
const BTC_TO_DOLLAR_RATE = 0.00003;

export const convertDollarToEth = (dollar: number) => {
	return `ETH ${(dollar * ETH_TO_DOLLAR_RATE).toFixed(4)}`;
};

export const convertDollarToBTC = (dollar: number) => {
	return `BTC ${(dollar * BTC_TO_DOLLAR_RATE).toFixed(4)}`;
};

/**
 * Converts snake case to title case
 * @param string
 * @returns Title case
 */
export const titleCase = (s: string) =>
	s
		.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
		.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

export const lengthOfObject = (obj: {}) => {
	return Object.keys(obj).length;
};

export function getCurrentUrl(pathname: string) {
	return pathname.split(/[?#]/)[0];
}

export function checkIsActive(pathname: string, url: string) {
	const current = getCurrentUrl(pathname);
	if (!current || !url) {
		return false;
	}

	if (current === url) {
		return true;
	}

	if (current.indexOf(url) > -1) {
		return true;
	}

	return false;
}

export function getStatusColor(status: any) {
	const statuses: any = {
		'pending': 'warning',
		'success': 'success',
		'canceled': 'info',
		'failed': 'danger',
		'paid': 'success',
		'awaiting-payment': 'warning',
	};
	return statuses[status] || 'primary';
}

export function hasOwnProperty<O extends object, K extends PropertyKey>(
	obj: O,
	key: K
): obj is O & Record<K, unknown> {
	return Object.prototype.hasOwnProperty.call(obj, key);
}

export function buildPageQuery(pageQuery?: Record<string, any>, appendQuestionMark = true): string {
	if (!pageQuery) return '';

	let query = Object.keys(pageQuery)
		.reduce((query: string[], key) => {
			let value = pageQuery[key];
			if (typeof value === 'string') {
				value = value.trim();
			}

			if ((value || typeof value == 'boolean') && value !== '') {
				query.push(`${key}=${value}`);
			}

			return query;
		}, [])
		.join('&');

	if (appendQuestionMark && query && query !== '') query = '?' + query;

	return query;
}
