import RenderSvg from '../../../components/renderSvg/RenderSvg';
import RequestButtonComp from './RequestButtonComp';

const RequestInvoice = () => {
	return (
		<div className="d-flex flex-column justify-content-center align-items-center p-3">
			<RenderSvg path="/media/icons/export.svg" className="svg-icon-1" />
			<h4 className="fs-2">No invoice here</h4>
			<small className="text-center mw-80 fs-6 mb-6">
				Use this feature to bill your customers easily or send professional invoices
			</small>
			<RequestButtonComp />
		</div>
	);
};

export default RequestInvoice;
