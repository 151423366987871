import { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { FormInput, Button } from '../../../components';
import {
	loginAction,
	authLoadingAction,
	getAuthUserAction,
} from '../../../store/Auth/auth.actions';
import { AuthDispatchContext, AuthStateContext } from '../../../store/Auth/auth.provider';
import * as ROUTES from '../../../constants/routes';
import { ActionType } from '../../../types/IAuth';
import { LayoutContext } from '../../../layouts';
import { titleCase } from '../../../helpers/utils';

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),
});

const Login: React.FC = () => {
	const { loading, newUser, user, auth } = useContext(AuthStateContext);
	const dispatch = useContext(AuthDispatchContext) as React.Dispatch<ActionType>;

	const { setError, setSuccess } = useContext(LayoutContext);

	let navigate = useNavigate();
	const initialValues = {
		email: user?.email || '',
		password: '',
	};

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setStatus, setSubmitting }) => {
			try {
				dispatch(authLoadingAction(true));
				dispatch(await loginAction(values));
				setSuccess('Login successful');
				dispatch(authLoadingAction(false));
				setTimeout(async () => {
					dispatch(await getAuthUserAction());
				}, 1000);
			} catch (error: any) {
				setError(error.message ? `${titleCase(error.message)}` : 'Something went wrong');
				dispatch(authLoadingAction(false));
			}
		},
	});

	return (
		<div className="w-lg-500px p-10 p-lg-15 mx-auto">
			<form
				className="form w-100"
				onSubmit={formik.handleSubmit}
				noValidate
				id="kt_login_signin_form"
			>
				{/* begin::Heading */}
				<div className="text-center mb-10">
					<h1 className="text-dark mb-3">Sign In to UseCoins</h1>
				</div>
				{/* begin::Heading */}

				{newUser && (
					<div className="mb-lg-15 alert alert-success">
						<div className="alert-text font-weight-bold">
							{'Registration Successfull, Please Login'}
						</div>
					</div>
				)}
				{formik.status ? (
					<div className="mb-lg-15 alert alert-danger">
						<div className="alert-text font-weight-bold">{formik.status}</div>
					</div>
				) : (
					<div></div>
				)}
				{/* begin::Form group */}
				<div className="fv-row mb-10">
					<FormInput
						labelName="Email"
						labelClass="form-label fs-6 fw-bolder text-dark"
						placeholder="Email"
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{ 'is-invalid': formik.touched.email && formik.errors.email },
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
						type="email"
						name="email"
					/>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<span role="alert">{formik.errors.email}</span>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Form group */}
				<div className="fv-row mb-10">
					<div className="">
						<div className="d-flex flex-stack mb-2">
							{/* begin::Label */}
							<label className="form-label fw-bolder text-dark fs-6 mb-0">
								Password
							</label>
							{/* end::Label */}
							{/* begin::Link */}
							<Link
								to={ROUTES.FORGOTPASSWORD}
								className="link-primary fs-6 fw-bolder"
							>
								Forgot Password ?
							</Link>
							{/* end::Link */}
						</div>
					</div>
					<FormInput
						type="password"
						autoComplete="off"
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control form-control-lg form-control-solid',
							{
								'is-invalid': formik.touched.password && formik.errors.password,
							},
							{
								'is-valid': formik.touched.password && !formik.errors.password,
							}
						)}
					/>
					{formik.touched.password && formik.errors.password && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.password}</span>
							</div>
						</div>
					)}
				</div>
				{/* end::Form group */}

				{/* begin::Action */}
				<div className="text-center">
					<Button
						type="submit"
						id="kt_sign_in_submit"
						className="btn btn-lg btn-primary w-100 mb-5"
						disabled={formik.isSubmitting || !formik.isValid}
					>
						{!loading && <span className="indicator-label">Continue</span>}
						{loading && (
							<span className="indicator-progress" style={{ display: 'block' }}>
								Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</Button>

					{/* begin::Separator */}
					<div className="text-center text-muted text-uppercase fw-bolder mb-5"></div>
					{/* end::Separator */}
				</div>
				{/* end::Action */}
				<div className="text-center mb-10">
					<div className="text-gray-400 fw-bold fs-4">
						New Here?{' '}
						<Link to={`/auth/${ROUTES.REGISTER}`} className="link-primary fw-bolder">
							Create an Account
						</Link>
					</div>
				</div>
			</form>
		</div>
	);
};

export default Login;
