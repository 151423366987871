import { useContext, useRef, useEffect, useState } from 'react';
import { RenderSvg, Search } from '..';
import {
	capitalizeFirstLetter,
	getStatusColor,
	titleCase,
	toAbsoluteUrl,
	truncate,
	truncateCurrencyAddress,
	toDateFormat,
} from '../../helpers/utils';
import { IPageQuery } from '../../types/_model';
import Button from '../button/Button';
import Card from '../card/Card';
import Filter from '../filter/Filter';
import { TableContext } from './TableProvider';
import { downloadExcel } from 'react-export-table-to-excel';
import { useLocation } from 'react-router-dom';

type PropsType = {
	columns: Array<string>;
	tableData: any[];
	children?: any;
	enableButton: boolean;
	className?: string;
	options?: {
		enableSearch?: boolean;
		enableFilter?: boolean;
		enableExport?: boolean;
		enableCreate?: boolean;
		createButtonText?: string;
		searchPlaceholder?: string;
	};
	isSliced?: boolean;
	showMore?: boolean;
	tableTitle?: string;
	handleShowMore?: any;
	loading?: boolean;
	dispatchAction?: (pageQuery?: IPageQuery) => Promise<void>;
};

const SimpleTable2 = ({
	columns,
	tableData,
	children,
	enableButton,
	className,
	options = {
		enableSearch: true,
		enableFilter: true,
		enableExport: true,
		enableCreate: false,
		createButtonText: 'Create',
	},
	isSliced,
	showMore,
	handleShowMore,
	tableTitle,
	loading,
	dispatchAction,
}: PropsType) => {
	const { handleSetModal, handleSetActiveTable } = useContext(TableContext);
	const handleOpenModalSetTable = (tableId: string) => {
		handleSetModal();
		handleSetActiveTable(tableId);
	};

	const defaultListLength = 10;

	const [slicedTableData, setSlicedTableData] = useState<any>([]);

	const slice = {
		length: 10,
		showMoreRoute: '/collections',
	};

	const tableRef = useRef(null);
	const location = useLocation();
	// const navigate = useNavigate();

	const showButton = enableButton ? enableButton : false;
	const columnData =
		columns && columns.length > 0
			? columns
			: tableData.length > 0
			? Object.keys(tableData[0])
			: [];
	const ThData = () => {
		return columnData.map((data) => {
			return (
				<th className="min-w-150px" key={data}>
					{titleCase(data)}
				</th>
			);
		});
	};

	const handleDownloadExcel = () => {
		downloadExcel({
			fileName: location.pathname,
			sheet: `${location.pathname} export`,
			tablePayload: {
				header: columnData,
				body: tableData,
			},
		});
	};
	const {
		enableSearch,
		enableFilter,
		enableExport,
		searchPlaceholder,
		enableCreate,
		createButtonText,
	} = options;

	// const handleShowMore = () => {
	// 	if (tableData) {
	// 		navigate('/collections');
	// 		return;
	// 	}
	// };

	useEffect(() => {
		if (isSliced) {
			setSlicedTableData(tableData?.slice(0, slice?.length || defaultListLength));
		} else {
			setSlicedTableData(tableData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData, isSliced]);

	return (
		<Card>
			<div className="card-header border-0">
				<div>
					{isSliced && (
						<div className="card-title mt-8">
							<h3>{tableTitle}</h3>
						</div>
					)}
					{enableSearch && (
						<Search
							dispatchAction={dispatchAction}
							placeholder={searchPlaceholder ? searchPlaceholder : 'Search ....'}
						/>
					)}
				</div>
				<div className="card-toolbar">
					<div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
						{enableFilter && (
							<Filter isLoading={false} html={''} dispatchAction={dispatchAction} />
						)}
						{enableExport && (
							<Button
								onClick={handleDownloadExcel}
								type="button"
								className="btn btn-light-primary me-3"
							>
								<RenderSvg path="/media/icons/export.svg" className="svg-icon-2" />
								Export
							</Button>
						)}
						{enableCreate && (
							<Button type="button" className="btn btn-primary me-3">
								{createButtonText}
							</Button>
						)}
					</div>
				</div>
			</div>

			<div>
				<div className={`${className ? className : 'table-responsive'}`}>
					{/*begin::Table*/}
					<table
						className="table table-flush align-middle table-row-bordered table-row-solid gy-4 gs-9"
						ref={tableRef}
					>
						{/*begin::Table head*/}
						<thead className="border-gray-200 fs-5 fw-bold bg-lighten">
							<tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
								{ThData()}
								{showButton ? (
									<th className="min-w-100px text-end">Actions</th>
								) : (
									<></>
								)}
							</tr>
						</thead>
						{/*end::Table head*/}
						{/*begin::Table body*/}
						<tbody className="fw-6 fw-bold text-gray-600">
							{children && children}
							{Object.values(tableData).length > 0 ? (
								!children &&
								slicedTableData.map((data: any, index: number) => {
									return (
										<tr key={index}>
											{Object.entries(data).map(
												([key, value]: any, indexData) => {
													if (indexData > columnData.length - 1) {
														return null;
													}
													if (key === 'status') {
														return (
															<td key={key}>
																<span
																	className={`badge badge-light-${getStatusColor(
																		value
																	)}`}
																>
																	{capitalizeFirstLetter(value)}
																</span>
															</td>
														);
													}

													if (key === 'icon') {
														return (
															<td key={key}>
																<span>
																	<img
																		src={toAbsoluteUrl(
																			`/media/${value}`
																		)}
																		height="50"
																		alt={value}
																	/>
																</span>
															</td>
														);
													}

													if (key === 'amount') {
														return (
															<td key={key}>
																{/* <span className="text-dark d-block mb-1 fs-6">
																	{moneyFormat(value)}
																</span> */}

																<span className="text-muted fw-bold text-muted d-block fs-7">
																	{data.amount_payable_coin
																		? data.amount_payable_coin
																		: '-'}
																</span>
															</td>
														);
													}

													if (
														key === 'date' ||
														key === 'created_at' ||
														key === 'updated_at'
													) {
														return (
															<td key={key}>
																<span className="text-dark d-block mb-1 fs-6">
																	{toDateFormat(value)}
																</span>
															</td>
														);
													}
													if (
														key === 'from' ||
														key === 'to' ||
														key === 'address'
													) {
														return (
															<td
																className="text-dark fs-6"
																key={key}
															>
																{value &&
																	truncateCurrencyAddress(value)}
															</td>
														);
													}
													return (
														<td
															className={`text-dark fs-6 `}
															title={value}
															key={key}
														>
															{key === 'reference' || key === 'id'
																? truncate(value, 12)
																: value}
														</td>
													);
												}
											)}
											{showButton ? (
												<td className="text-dark fs-6 text-end">
													<button
														onClick={() =>
															handleOpenModalSetTable(
																data?.uuid || data?.id
															)
														}
														className="btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2"
													>
														View
													</button>
												</td>
											) : (
												<></>
											)}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={7}>
										<div className="d-flex text-center w-100 align-content-center justify-content-center">
											{!loading ? (
												'No matching records found!'
											) : (
												<span
													className="indicator-progress"
													style={{ display: 'block' }}
												>
													Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
												</span>
											)}
										</div>
									</td>
								</tr>
							)}
							{showMore && tableData.length > defaultListLength && (
								<>
									<div className="show-more-less cursor-pointer">
										<div onClick={handleShowMore} className="show-cover">
											<span className="show">
												Show more {tableData.length}
											</span>
										</div>
									</div>
								</>
							)}
						</tbody>
						{/*end::Table body*/}
					</table>
					{/*end::Table*/}
				</div>
			</div>
		</Card>
	);
};

export default SimpleTable2;
