import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ForgotPassword, Login, Register, VerifyEmail } from '../views';
import * as ROUTES from '../constants/routes';
import { AuthLayout } from '../views';
import SetPassword from '../views/auth/forgot-password/SetPassword';
import VerifyInvite from '../views/auth/verify/VerifyInvite';

const PublicRoutes: React.FC = () => {
	return (
		<Routes>
			<Route element={<AuthLayout />}>
				<Route path={ROUTES.LOGIN} element={<Login />} />
				<Route path={ROUTES.REGISTER} element={<Register />} />
				<Route path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
				<Route path={ROUTES.RESETPASSWORD} element={<SetPassword />} />
				<Route path={ROUTES.VERIFY_INVITE} element={<VerifyInvite />} />
				<Route index element={<Login />} />
			</Route>
		</Routes>
	);
};

export default PublicRoutes;
