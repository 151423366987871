import { setItem } from '../../helpers/storage';
import { ActionType } from '../../types/IAuth';
import { IBusiness } from '../../types/IBusiness';
import { businessActions } from './business.actions';

export type BusinessType = {
	activeBusiness: IBusiness | undefined;
	allBusiness: IBusiness[];
	loading: boolean;
	error: boolean;
};

export const BUSINESS_INITIAL_STATE: BusinessType = {
	activeBusiness: undefined,
	allBusiness: [],
	loading: false,
	error: false,
};

export const businessReducer = (state = BUSINESS_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case businessActions.BUSINESS_ACTION:
			return {
				...state,
				activeBusiness: { ...state.activeBusiness, ...action.payload },
				loading: false,
			};
		case businessActions.BUSINESS_FOUND:
			setItem('default_currency', action.payload.default_currency);
			return {
				...state,
				activeBusiness: action.payload,
				loading: false,
			};
		case businessActions.ALL_BUSINESS:
			return {
				...state,
				allBusiness: action.payload,
				loading: false,
			};
		case businessActions.BUSINESS_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case businessActions.BUSINESS_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
