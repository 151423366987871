import { ActionType } from '../../types/IAuth';
import { IPageMeta } from '../../types/_model';
import { invoiceActions } from './invoice.actions';
import { IInvoice } from '../../types/IInvoice';

export type InvoiceType = {
	invoices: IInvoice[];
	invoice?: IInvoice | undefined | null;
	meta?: IPageMeta;
	loading: boolean;
	error: boolean;
};

export const INVOICE_INITIAL_STATE: InvoiceType = {
	invoices: [],
	invoice: undefined,
	meta: undefined,
	loading: false,
	error: false,
};

export const invoiceReducer = (state = INVOICE_INITIAL_STATE, action: ActionType) => {
	switch (action.type) {
		case invoiceActions.CREATE_INVOICE:
			return {
				...state,
				invoices: action.payload,
				meta: action.payload.meta,
				loading: false,
			};
		case invoiceActions.LIST_INVOICES:
			return {
				...state,
				invoices: action.payload,
				meta: action.payload.meta,
				loading: false,
			};
		case invoiceActions.FIND_INVOICE:
			return {
				...state,
				invoice: { ...state.invoice, ...action.payload },
				loading: false,
			};
		case invoiceActions.DELETE_INVOICE:
			return {
				...state,
				invoice: { ...state.invoice, ...action.payload },
				loading: false,
			};
		case invoiceActions.INVOICE_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		case invoiceActions.INVOICE_ERROR:
			return {
				...state,
				error: action.payload,
				loading: false,
			};

		default:
			throw new Error(`Unknown action type: ${action.type}`);
	}
};
