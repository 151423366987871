import { usecoinsApi } from '../http';
import { AUTH_ENDPOINTS } from '../../constants/endpoints';
import { AxiosRequestHeaders } from 'axios';

const API_URL = process.env.APP_API_URL;

export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

// Server should return AuthModel
export function login(email: string, password: string) {
	return usecoinsApi.post(AUTH_ENDPOINTS.LOGIN, {
		email,
		password,
	});
}

// Server should return AuthModel
export function register(
	email: string,
	firstname: string,
	lastname: string,
	business_name: string,
	password: string,
	phone: string,
	country: string,
	accountType: string
) {
	return usecoinsApi.post(AUTH_ENDPOINTS.REGISTER, {
		email,
		firstname,
		lastname,
		business_name,
		password,
		phone,
		country,
		accountType,
	});
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
	return usecoinsApi.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
		email,
	});
}

export function getAuthUser() {
	return usecoinsApi.get<AxiosRequestHeaders>(AUTH_ENDPOINTS.FETCH_AUTH_USER);
}
